import styled, { css } from 'styled-components';
import { SvgIcon } from '@progress/kendo-react-common';
import { UserBadge } from 'features/Chat/components/ChatBadges';

type ChatWidgetProps = {
  minimised: boolean;
  fullscreen: boolean;
};

type ChatHeaderWidgetProps = {
  minimised: boolean;
};

export const WidgetContainer = styled.div<ChatWidgetProps>`
  position: fixed;
  z-index: 10002;

  ${({ minimised }) =>
    minimised
      ? css`
          max-height: 48px;
          width: 110px;

          * ${ChatHeader} {
            height: 48px;
            box-shadow: 0 1px 100px 2px rgba(0, 0, 0, 0.48);
          }
        `
      : css`
          height: 100%;
          max-height: 85vh;
          width: 790px;
          display: flex;
          flex-direction: column-reverse;

          * ${ChatHeader} {
            height: 56px;
          }
        `};

  ${({ fullscreen, minimised }) =>
    fullscreen && !minimised
      ? css`
          left: 0;
          top: 0;
          position: fixed;
          max-height: 100%;
          width: 100%;

          ${ChatBoxGroup} {
            height: 100%;
          }
        `
      : css`
          right: 0;
          bottom: 0;
          margin-right: 30px;
          max-width: 790px;
        `};
`;

export const ChatHeader = styled.header<ChatHeaderWidgetProps>`
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.link2};
  padding: 0 16px;
  font-size: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  &:hover {
    cursor: ${({ minimised }) => (minimised ? 'pointer' : 'default')};
  }
`;

export const CloseChatIcon = styled.span`
  cursor: pointer;
`;

export const MinimizedChatNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
  font-size: 16px;
`;

export const UnreadChip = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.error2};
  color: white;
  width: 15px;
  height: 15px;
  font-size: 12px;
  right: -2px;
  top: -3px;
  outline: 1.5px solid white;
`;

export const ChatBoxContainer = styled.div`
  padding: 16px 16px 24px;
  background-color: white;
  min-height: 380px;
  height: 100%;
`;

export const ChatBoxScrollSection = styled.div`
  height: calc(100% - 60px);
  overflow: auto;
`;

export const EmptyChatRoomList = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const ChatBoxGroup = styled.div`
  height: 60%;
`;
export const ChatRoomTileSection = styled.div`
  width: 100%;
  flex-grow: 1;
`;

export const ChatRoomTile = styled.div`
  display: grid;
  grid-template-columns: auto 70% auto;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color: #f8f9fa;
  padding: 10px 5px;
  margin: 8px 0;
  cursor: pointer;
  border-radius: 4px;
  max-height: 68px;
  border: solid 1.5px transparent;

  > ${ChatRoomTileSection}:first-child {
    width: auto;
  }

  > ${ChatRoomTileSection}:last-child {
    width: auto;
    white-space: nowrap;
    margin-right: 10px;
  }

  &:hover {
    background-color: rgba(14, 99, 244, 0.24);
    border: solid 1.5px ${({ theme }) => theme.palette.link2};
    //color: white;
  }
`;

export const AvatarWrapper = styled.div`
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-right: 12px;
  width: 56px;

  @media ${({ theme }) => theme.devices.large} {
    width: auto;
    height: 50px;
  }
`;
export const ChatHeaderIcons = styled.div`
  display: flex;
  color: white;
  justify-content: space-between;
  width: 70px;
`;
export const Icon = styled(SvgIcon)`
  color: white;
  cursor: pointer;
`;

export const DetailsSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
`;

export const DetailsSectionTitle = styled.h5`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: left;
  color: #1d2b4f;
`;

export const DetailsSectionText = styled.h5`
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #626c84;
`;

export const DateTimeSection = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #626c84;
`;

export const ChatSummary = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border};
  padding-bottom: 25px;
`;
export const ChatSummarySection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const ChatSummarySectionCopy = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.subtleText};
  margin-bottom: 8px;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.base};

  span {
    white-space: pre;
  }
`;

export const SwitchInner = styled.div`
  margin-right: 12px;
`;

export const ChatMessageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type ChatMessageContentProps = {
  deleted: boolean;
};
export const ChatMessageContent = styled.div<ChatMessageContentProps>`
  border-radius: 4px;
  background-color: ${({ deleted }) => (deleted ? '#e9ecef' : '#f8f9fa')};
  padding: 16px 12px;
  overflow-wrap: break-word;

  span {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #1d2b4f;
  }
`;

export const ChatMessageDeleteContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
`;

export const ChatMessageDeleteButton = styled.div`
  font-size: 14px;
  letter-spacing: normal;
  text-align: left;
  color: #1d2b4f;
  cursor: pointer;
`;

export const ChatMessageDeletedSection = styled.div`
  font-style: italic;
  font-size: 16px;
  color: #626c84;
  padding: 10px;
`;

export const AvatarGroup = styled.div`
  display: inline-flex;
`;

export const AvatarOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: opacity 0.3s ease;

  svg {
    fill: white;
  }
`;

export const CustomAvatar = styled.span`
  position: relative;
  border-radius: 50%;
  border: solid 1.6px transparent;
  overflow: hidden;

  &:not(:first-child) {
    border: solid 1.6px #fff;
    margin-left: -10px;
    background-color: #adb5bd;
  }

  &:hover {
    z-index: 10003;
  }

  &:hover ${AvatarOverlay} {
    cursor: pointer;
    opacity: 1;
    z-index: 10003;
  }

  img,
  svg {
    aspect-ratio: 1/1;
    width: 100%;
    display: block;
  }
`;

export const CustomerAvatarUnreadChip = styled.span`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.error2};
  color: white;
  width: 13px;
  height: 13px;
  font-size: 11px;
  right: -2px;
  bottom: 4px;
  outline: 1.5px solid white;
  z-index: 10;
`;

export const AvatarCap = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: white;
`;

export const AvatarWithText = styled.div`
  display: inline-flex;
  gap: 5px;
  align-items: center;
`;

export const AvatarText = styled.h5`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: left;
  color: #1d2b4f;
`;

export const AvatarActionButton = styled.button`
  border-radius: 160px;
  border: solid 1.6px white;
  background-color: #0e63f4;
  margin-left: -10px;
  height: 32px;
  padding: 0 12px;
  z-index: 10;
  cursor: pointer;

  &:hover {
    font-weight: bold;
  }
`;

export const AvatarActionButtonText = styled.span`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
`;
export const BackToListSection = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  column-gap: 10px;
`;

export const ChatListGroup = styled.ul`
  text-decoration: none;
  list-style: none;
  max-height: calc(100% - 220px);
  height: 100%;
  padding-right: 15px;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
`;
export const ChatListItem = styled.li`
  margin: 20px 0;
`;

export const ChatMessageAboutSection = styled.div`
  width: 100%;
  margin: 10px 0;
  display: flex;
  align-items: center;

  > ${UserBadge} {
    margin-left: 10px;
  }
`;

export const ChatMessageTime = styled.span`
  color: ${({ theme }) => theme.palette.link};
  font-size: 12px;
`;

export const FakeInput = styled.div`
  height: 62px;
  margin: 0.5em 0 0;
  padding: 0 1em;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: solid 1px #ced4da;
`;
export const TextInput = styled.textarea`
  width: 100%;
  height: 100%;
  outline: none;
  position: relative;
  background: transparent;
  border: 0;
  resize: none;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #626c84;
  font-family: 'Arial';
  padding: 15px 0;
`;

export const ChatBoxButtonSection = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

export const ChatMessageText = styled.span`
  white-space: break-spaces;
`;
