import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { AvailableParticipantsQueryParams } from 'features/Chat/api/users.types';
import { getAvailableParticipants } from 'features/Chat/api/users';

export const CHAT_ROOMS_AVAILABLE_PARTICIPANT_QUERY_KEY = 'chat-rooms-participants';

export const useAvailableParticipants = (
  roomId: number,
  queryParams?: AvailableParticipantsQueryParams,
  enabled = true
) => {
  const {
    data,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError,
    hasNextPage,
    error,
    fetchNextPage
  } = useInfiniteQuery(
    [CHAT_ROOMS_AVAILABLE_PARTICIPANT_QUERY_KEY, roomId, queryParams],
    ({ pageParam = 0 }) =>
      getAvailableParticipants(roomId, { ...queryParams, page: pageParam + 1 }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.paginator.last_page > lastPage.paginator.current_page) {
          return lastPage.paginator.current_page;
        }
        return undefined;
      },
      enabled
    }
  );

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, data]);

  return {
    result:
      data && !hasNextPage ? data.pages.reduce((prev, cur) => prev.concat(cur.items), []) : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};
