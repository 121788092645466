import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import FormInput from '../../components/FormInput/FormInput';
import {
  phoneValidatorOptional,
  requiredValidator,
  strongPasswordValidator
} from '../../components/FormInput/validators';
import { Button } from '@progress/kendo-react-buttons';
import React, { useState } from 'react';
import FormMaskedTextBox from '../../components/FormInput/FormMaskedTextBox';
import {
  FormButtonsWrapper,
  FormGridWrapper,
  ResetPasswordButtons,
  ResetPasswordButtonWrapper,
  ResetPasswordInput
} from './styled';
import { UserEntry } from '../../api/users/users.types';
import { RemoveButton } from '../../components/ActionButton/ActionButton';
import {
  useForceChangePasswordForAnotherUser,
  useResetPasswordRequest
} from '../../hooks/api/useUsers';
import { useHistory } from 'react-router-dom';
import { PhoneWrapper } from '../Companies/styled';
import FormCountryCode from '../../components/FormInput/FormCountryCode';
import { errorNotification } from '../../utils/notifications';
import { FormPasswordTextBox } from '../../components/FormInput/FormPasswordTextBox';
import LoaderButton from '../../components/LoaderButton/LoaderButton';
import { parseCountryCodeFromAPI } from 'utils/parsePhone';
import { countryCodeMap } from 'utils/definesLocal';

interface Props {
  onSubmit: (data: any) => void;
  isLoading: boolean;
  user?: UserEntry;
  isUpdate?: boolean;
}

const UserManageForm = ({
  onSubmit,
  user = undefined,
  isLoading = false,
  isUpdate = false
}: Props) => {
  const [changePassword, setChangePassword] = useState<boolean>(false);
  const { mutateAsync: resetPassword } = useResetPasswordRequest();
  const { mutateAsync: changePasswordRequest, isLoading: isLoadingForceChangePassword } =
    useForceChangePasswordForAnotherUser();
  const handleSubmit = (e: any) => {
    onSubmit(e);
  };
  const { goBack } = useHistory();

  const resetPasswordRequest = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!user?.email) {
      return;
    }
    await resetPassword(user?.email);
  };

  const forceChangePassword = async (
    e: React.MouseEvent<HTMLButtonElement>,
    newPassword: string
  ) => {
    e.preventDefault();
    e.stopPropagation();

    if (!changePassword) {
      setChangePassword(true);
      return;
    }

    if (!user) {
      return;
    }
    const validator = strongPasswordValidator(newPassword);

    if (validator) {
      errorNotification(validator);
      return;
    }

    await changePasswordRequest({ userId: user?.id, password: newPassword });
    setChangePassword(false);
  };

  const transformExistingData = (userData: any) => {
    const phoneCode = userData?.phone_code;
    const initialData = {
      ...userData,
      phone_code: phoneCode ? countryCodeMap.get(phoneCode) : ''
    };

    return initialData;
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={transformExistingData(user)}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          <FormGridWrapper>
            <div>
              <Field id={'name'} name={'name'} label={'Name'} component={FormInput} />
              <Field
                id={'email'}
                name={'email'}
                label={'Email'}
                component={FormInput}
                validator={requiredValidator}
              />
              <ResetPasswordButtonWrapper>
                <ResetPasswordInput>
                  <Field
                    id={'password'}
                    name={'password'}
                    label={'Password'}
                    component={FormPasswordTextBox}
                    readOnly={!changePassword}
                    optional={true}
                  />
                </ResetPasswordInput>
                <ResetPasswordButtons>
                  <Button
                    onClick={(e) => forceChangePassword(e, formRenderProps.valueGetter('password'))}
                    themeColor={'primary'}
                    disabled={isLoadingForceChangePassword}>
                    {changePassword ? 'Confirm' : 'Set'}
                    {isLoadingForceChangePassword && <LoaderButton />}
                  </Button>
                  <RemoveButton onClick={resetPasswordRequest}>Reset</RemoveButton>
                </ResetPasswordButtons>
              </ResetPasswordButtonWrapper>
            </div>
            <div>
              <Field
                id={'address1'}
                name={'address1'}
                label={'Address'}
                component={FormInput}
                optional={true}
              />
              <PhoneWrapper>
                {formRenderProps.valueGetter('phone') && (
                  <Field
                    id={'phone_code'}
                    name={'phone_code'}
                    label={'Country code'}
                    component={FormCountryCode}
                    validator={requiredValidator}
                  />
                )}
                <Field
                  id={'phone'}
                  name={'phone'}
                  label={'Phone Number'}
                  component={FormMaskedTextBox}
                  validator={phoneValidatorOptional}
                  optional={true}
                />
              </PhoneWrapper>
            </div>
          </FormGridWrapper>
          <FormButtonsWrapper className='k-form-buttons'>
            <Button onClick={goBack} type='button'>
              Cancel
            </Button>
            <Button
              themeColor={'primary'}
              type='submit'
              disabled={!formRenderProps.allowSubmit || isLoading}>
              {isUpdate ? 'Edit' : 'Create'}
              {isLoading && <LoaderButton />}
            </Button>
          </FormButtonsWrapper>
        </FormElement>
      )}
    />
  );
};

export default UserManageForm;
