import styled from 'styled-components';

export const ForgottenPasswordLink = styled.a`
  color: ${({ theme }) => theme.palette.link};
  font-size: ${({ theme }) => theme.fontSize.labelBig};
  text-decoration: none;
  cursor: pointer;
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

export const Auth2FAWrapper = styled.div`
  width: 630px;
  padding: 30px;
`;

export const Description2FA = styled.p`
  margin-bottom: ${({ theme }) => theme.dimensions.spacing * 2}px;
  color: ${({ theme }) => theme.palette.headerTitle};
  font-size: ${({ theme }) => theme.fontSize.title};
`;

export const Line = styled.span`
  width: 100%;
  background-color: #dee2e6;
  height: 1px;
  margin: 30px 0;
  display: block;
`;

export const SmallParagraph = styled.p`
  font-size: 14px;
  text-align: center;
  color: #626c84;
`;
