/* eslint-disable react/jsx-key */
import * as React from 'react';
import styled from 'styled-components';
import { Label, Hint } from '@progress/kendo-react-labels';
import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form';
import { DropDownList, ListItemProps } from '@progress/kendo-react-dropdowns';
import { ReactComponent as USFlag } from '../../assets/flags/us.svg';
import { ReactComponent as PLFlag } from '../../assets/flags/pl.svg';
import { ReactComponent as DEFlag } from '../../assets/flags/de.svg';
import { ReactComponent as PTFlag } from '../../assets/flags/pt.svg';
import { ReactComponent as FRFlag } from '../../assets/flags/fr.svg';
import { ReactComponent as RUFlag } from '../../assets/flags/ru.svg';
import { ReactComponent as SEFlag } from '../../assets/flags/se.svg';
import { ReactComponent as ESFlag } from '../../assets/flags/es.svg';
import { ReactComponent as ATFlag } from '../../assets/flags/at.svg';
import { ReactComponent as TRFlag } from '../../assets/flags/tr.svg';
import { ReactComponent as ITFlag } from '../../assets/flags/it.svg';
import { ReactComponent as JPFlag } from '../../assets/flags/jp.svg';
import { ReactComponent as GBFlag } from '../../assets/flags/gb.svg';
import { ReactComponent as CNFlag } from '../../assets/flags/cn.svg';
import { ReactComponent as INFlag } from '../../assets/flags/in.svg';
import { ReactComponent as MXFlag } from '../../assets/flags/mx.svg';
import { ReactComponent as BRFlag } from '../../assets/flags/br.svg';
import { ReactComponent as AUFlag } from '../../assets/flags/au.svg';
import { ReactComponent as NZFlag } from '../../assets/flags/nz.svg';
import { ReactComponent as KRFlag } from '../../assets/flags/kr.svg';
import { ReactComponent as AEFlag } from '../../assets/flags/ae.svg';
import { ReactComponent as CZFlag } from '../../assets/flags/cz.svg';
import { ReactComponent as SKFlag } from '../../assets/flags/sk.svg';
import { ReactComponent as HRFlag } from '../../assets/flags/hr.svg';
import { ReactComponent as UAFlag } from '../../assets/flags/ua.svg';
import { ReactComponent as BGFlag } from '../../assets/flags/bg.svg';
import { ReactComponent as LTFlag } from '../../assets/flags/lt.svg';
import { ReactComponent as LVFlag } from '../../assets/flags/lv.svg';
import { ReactComponent as EEFlag } from '../../assets/flags/ee.svg';
import { ReactComponent as NOFlag } from '../../assets/flags/no.svg';
import { ReactComponent as DKFlag } from '../../assets/flags/dk.svg';
import { ReactComponent as CHFlag } from '../../assets/flags/ch.svg';
import { ReactComponent as ROFlag } from '../../assets/flags/ro.svg';
import { ReactComponent as HUFlag } from '../../assets/flags/hu.svg';
import { ReactComponent as BEFlag } from '../../assets/flags/be.svg';
import { ReactComponent as NLFlag } from '../../assets/flags/nl.svg';
import { ReactComponent as GRFlag } from '../../assets/flags/gr.svg';
import { ReactComponent as ZAFlag } from '../../assets/flags/za.svg';
import { ReactComponent as FIFlag } from '../../assets/flags/fi.svg';
import { ReactComponent as RSFlag } from '../../assets/flags/rs.svg';
import { ReactComponent as ILFlag } from '../../assets/flags/il.svg';
import { ReactComponent as SAFlag } from '../../assets/flags/sa.svg';
import { ReactComponent as CAFlag } from '../../assets/flags/ca.svg';
import { ReactComponent as LUFlag } from '../../assets/flags/lu.svg';
import { codes } from '../../constants/countries';
import { countryCodeReversedMap } from 'utils/definesLocal';

const StyledFlag = styled.div`
  min-width: 20px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const codeMap = new Map();
codeMap.set('US', <USFlag />);
codeMap.set('CA', <CAFlag />);
codeMap.set('RU', <RUFlag />);
codeMap.set('ZA', <ZAFlag />);
codeMap.set('GR', <GRFlag />);
codeMap.set('NL', <NLFlag />);
codeMap.set('BE', <BEFlag />);
codeMap.set('FR', <FRFlag />);
codeMap.set('ES', <ESFlag />);
codeMap.set('HU', <HUFlag />);
codeMap.set('IT', <ITFlag />);
codeMap.set('RO', <ROFlag />);
codeMap.set('CH', <CHFlag />);
codeMap.set('AT', <ATFlag />);
codeMap.set('GB', <GBFlag />);
codeMap.set('DK', <DKFlag />);
codeMap.set('SE', <SEFlag />);
codeMap.set('NO', <NOFlag />);
codeMap.set('PL', <PLFlag />);
codeMap.set('DE', <DEFlag />);
codeMap.set('MX', <MXFlag />);
codeMap.set('BR', <BRFlag />);
codeMap.set('AU', <AUFlag />);
codeMap.set('NZ', <NZFlag />);
codeMap.set('JP', <JPFlag />);
codeMap.set('KR', <KRFlag />);
codeMap.set('CN', <CNFlag />);
codeMap.set('TR', <TRFlag />);
codeMap.set('IN', <INFlag />);
codeMap.set('PT', <PTFlag />);
codeMap.set('LU', <LUFlag />);
codeMap.set('FI', <FIFlag />);
codeMap.set('BG', <BGFlag />);
codeMap.set('LT', <LTFlag />);
codeMap.set('LV', <LVFlag />);
codeMap.set('EE', <EEFlag />);
codeMap.set('UA', <UAFlag />);
codeMap.set('RS', <RSFlag />);
codeMap.set('HR', <HRFlag />);
codeMap.set('CZ', <CZFlag />);
codeMap.set('SK', <SKFlag />);
codeMap.set('SA', <SAFlag />);
codeMap.set('AE', <AEFlag />);
codeMap.set('IL', <ILFlag />);

const itemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
  const itemChildren = (
    <ItemWrapper>
      <StyledFlag>{codeMap.get(itemProps.dataItem)}</StyledFlag>
      <span>{countryCodeReversedMap.get(itemProps.dataItem)}</span>
    </ItemWrapper>
  );

  return React.cloneElement(li, li.props, itemChildren);
};

const valueRender = (element: React.ReactElement<HTMLSpanElement>, value: any) => {
  if (!value) {
    return element;
  }

  const children = [
    <StyledFlag>{codeMap.get(value)}</StyledFlag>,
    <span key={2}>&nbsp; {countryCodeReversedMap.get(value)}</span>
  ];

  return React.cloneElement(element, { ...element.props }, children);
};

const FormCountryCode = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, touched, label, id, valid, hint, optional, mask, isValid, ...others } =
    fieldRenderProps;

  const showValidationMessage: string | boolean | null = (touched && validationMessage) || !isValid;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : '';

  return (
    <FieldWrapper>
      <Label editorId={id} editorValid={valid} optional={optional}>
        {label}
      </Label>
      <DropDownList
        id={id}
        {...others}
        style={{ width: '110px' }}
        data={codes.map((item) => item.country)}
        itemRender={itemRender}
        valueRender={valueRender}
        valid={isValid}
      />
      {showHint && <Hint id={hintId}>{hint}</Hint>}
    </FieldWrapper>
  );
};

export default FormCountryCode;
