import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { acceptDocuments, getDocumentsStatus } from 'api/documents/documents';
import { errorApiNotification } from '../../utils/notifications';
import { DocumentStatusParams, DocumentsEnum } from 'api/documents/documents.types';

const DOCUMENTS_STATUS_QUERY = 'documents_status';

export const useDocumentsStatus = (
  enabled: boolean = false,
  queryParams: DocumentStatusParams = { type: DocumentsEnum.web }
) => {
  const { data, isInitialLoading, isError } = useQuery(
    [DOCUMENTS_STATUS_QUERY],
    () => getDocumentsStatus(queryParams),
    {
      onError(error: any) {
        errorApiNotification(error);
      },
      enabled
    }
  );

  return {
    result: data ? data : null,
    isLoading: isInitialLoading,
    isError
  };
};

export const useDocumentsAccept = () => {
  const queryClient = useQueryClient();
  const { data, mutateAsync, isLoading, isError } = useMutation(acceptDocuments, {
    onSuccess() {
      return queryClient.invalidateQueries([DOCUMENTS_STATUS_QUERY]);
    },
    onError(error: any) {
      errorApiNotification(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};
