/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import styled from 'styled-components';

import Card from '../../components/Card/Card';
import FormInput from '../../components/FormInput/FormInput';
import {
  emailValidatorOptional,
  phoneValidatorOptional,
  requiredValidator
} from '../../components/FormInput/validators';
import FormMaskedTextBox from '../../components/FormInput/FormMaskedTextBox';
import FormTextArea from '../../components/FormInput/FormTextArea';
import { useHistory, useLocation } from 'react-router-dom';
import { Loader } from '@progress/kendo-react-indicators';
import { HeaderComponent } from '../../components/Typography/Header';
import {
  useLocationCreate,
  useLocationOpeningEntryDelete,
  useLocationOpeningHours,
  useLocationOpeningHoursCreate,
  useLocationUpdate
} from '../../hooks/api/useLocations';
import { useCompaniesInfinite } from '../../hooks/api/useCompanies';
import FormComboBox from '../../components/FormInput/FormComboBox';
import { useGetStates, useTimezones } from '../../hooks/api/useGeoData';
import { LOCATION } from '../../constants/routes';
import FormCountryCode from '../../components/FormInput/FormCountryCode';
import { PhoneWrapper } from '../Companies/styled';
import { parseCountryCodeFromAPI, parsePhoneFromAPI } from '../../utils/parsePhone';
import { getter } from '@progress/kendo-data-query';
import { WEEKDAYS } from '../../constants/dates';
import { FormTimePicker } from '../../components/FormInput/FormTimePicker';
import { OpeningHoursWrapper, WeekdaysInnerWrapper, WeekdaysWrapper } from './styled';
import { Switch } from '@progress/kendo-react-inputs';
import { SwitchWrapper } from '../../components/SwitchWrapper/CustomSwitch';
import { differenceBy, isEmpty } from 'lodash';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { LocationOpeningHours } from '../../api/locations/locations.types';
import * as Sentry from '@sentry/react';
import { userHasPermissions } from '../../utils/permissionUtils';
import { RoleEnum } from '../../api/users/users.types';
import useUserData from '../../hooks/useUserData';
import LoaderButton from '../../components/LoaderButton/LoaderButton';
import { countryCodeMap, countryCodeReversedMap } from 'utils/definesLocal';

const StyledCard = styled(Card)`
  padding: 30px;
`;

const InnerWrapper = styled.div`
  margin: 0 auto;
  max-width: 450px;
  @media ${({ theme }) => theme.devices.xlarge} {
    width: 100%;
    max-width: 1640px;
  }
`;

const Fieldset = styled.fieldset`
  max-width: 450px;
  width: 100%;
`;

const StateWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 150px;
  gap: 20px;
`;

const FieldsWrapper = styled.div`
  display: grid;
  justify-items: center;
  @media ${({ theme }) => theme.devices.xlarge} {
    grid-template-columns: 1fr 1fr 1fr;
  }
  gap: 30px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

dayjs.extend(utc);
dayjs.extend(timezone);

const AddLocation = () => {
  const { data: userData, rolesByName } = useUserData();
  const isSuperAdmin = userHasPermissions([RoleEnum.superAdmin], rolesByName);
  const [countryId, setCountryId] = useState(
    isSuperAdmin ? null : userData?.location?.company?.country_id
  );
  const [statesKey, setStatesKey] = useState(1);
  const { state: { detail: existingCompanyData = false } = {} }: any = useLocation();
  const { result: openingHoursCollection } = useLocationOpeningHours(
    existingCompanyData.id,
    'collection'
  );
  const { result: companies } = useCompaniesInfinite(
    {
      perpage: 100
    },
    isSuperAdmin
  );
  const { result: timezones } = useTimezones();
  const { mutateAsync: createLocation, isLoading: isLoadingCreate } = useLocationCreate();
  const { mutateAsync: deleteLocationHourEntry } = useLocationOpeningEntryDelete();
  const { mutateAsync: createLocationOpeningHours } = useLocationOpeningHoursCreate();
  const { mutateAsync: updateLocation, isLoading: isLoadingUpdate } = useLocationUpdate();
  const { push, goBack } = useHistory();
  const { result: states } = useGetStates(countryId || 1);
  const [openingDaysStatus, setOpeningDaysStatus] = useState(
    WEEKDAYS.map((weekday) => ({ id: weekday.id, open: false, day_of_week: weekday.id }))
  );

  useEffect(() => {
    if (!openingHoursCollection) {
      return;
    }
    setOpeningDaysStatus(
      WEEKDAYS.map((weekday) => ({
        id: weekday.id,
        day_of_week: weekday.id,
        open: Boolean(
          openingHoursCollection &&
            openingHoursCollection.find(
              (item: LocationOpeningHours) => item.day_of_week === weekday.id
            )
        )
      }))
    );
  }, [openingHoursCollection]);

  // Updates states form field after refetch
  useEffect(() => {
    setStatesKey((prev) => prev + 1);
  }, [JSON.stringify(companies), openingHoursCollection]);

  useEffect(() => {
    if (existingCompanyData) {
      setCountryId(existingCompanyData?.company?.country_id);
    }
  }, [existingCompanyData]);

  const onCompanyChange = (e: any) => {
    setCountryId(e?.value?.country_id);
  };

  const isLoading = isLoadingCreate || isLoadingUpdate;

  const handleSubmit = async (e: any) => {
    const {
      name,
      company,
      address1,
      address2 = '',
      city,
      state,
      postal_code = '',
      phone = '',
      phone_code = '',
      email = '',
      website = '',
      about = '',
      tax_number = '',
      id_number = '',
      days = undefined,
      timezone = ''
    } = e;

    const clinicAdminLocation = userData?.location?.company_id;

    const locationData = {
      name,
      company: isSuperAdmin ? company.id : clinicAdminLocation,
      address1,
      address2,
      city,
      state: state.id,
      postal_code,
      phone: phone && phone_code ? `${countryCodeReversedMap.get(phone_code)}-${phone}` : '',
      email,
      website,
      about,
      tax_number,
      id_number,
      timezone
    };

    const setOpeningHours = (locationId: number | undefined = undefined) => {
      return Array.from(openingDaysStatus)
        .filter((day) => day.open)
        .map((day) => {
          const open = dayjs(days[`opening${day.id}`]);
          const close = dayjs(days[`closing${day.id}`]);
          return {
            day_of_week: day.id,
            ...(timezone && { timezone }),
            opens: open.format('HH:mm'),
            closes: close.format('HH:mm'),
            location_id: locationId ?? existingCompanyData.id
          };
        })
        .map((item) => {
          return createLocationOpeningHours(item);
        });
    };

    const deleteDifferent = () => {
      const diff = differenceBy(
        openingHoursCollection,
        openingDaysStatus.filter((day) => day.open),
        'day_of_week'
      );
      return diff.map((item: any) => {
        return deleteLocationHourEntry({
          locationId: existingCompanyData.id,
          hoursEntryId: item.id
        });
      });
    };

    try {
      if (existingCompanyData) {
        await updateLocation({ data: locationData, locationId: existingCompanyData.id });
        await Promise.all(setOpeningHours());
        await Promise.all(deleteDifferent());
      } else {
        const data = await createLocation(locationData);
        await Promise.all(setOpeningHours(data.id));
      }
      push(LOCATION);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const transformExistingData = (existingCompanyData: any) => {
    const phoneCode = parseCountryCodeFromAPI(existingCompanyData?.phone);
    return {
      ...existingCompanyData,
      company: {
        name: existingCompanyData?.company?.name,
        id: existingCompanyData?.company?.id
      },
      phone_code: phoneCode ? countryCodeMap.get(phoneCode) : '',
      phone: parsePhoneFromAPI(existingCompanyData?.phone),
      days: openingHoursCollection
        ? openingHoursCollection.reduce((previousValue: any, currentValue: any) => {
            const dateFromDefault = new Date();
            const dateToDefault = new Date();
            dateFromDefault.setHours(
              Number(currentValue.opens.split(':')[0]),
              Number(currentValue.opens.split(':')[1]),
              0,
              0
            );
            dateToDefault.setHours(
              Number(currentValue.closes.split(':')[0]),
              Number(currentValue.closes.split(':')[1]),
              0,
              0
            );
            return {
              ...previousValue,
              [`opening${currentValue.day_of_week}`]: dateFromDefault,
              [`closing${currentValue.day_of_week}`]: dateToDefault
            };
          }, {})
        : []
    };
  };

  const openingDaysValidator = (values: any) => {
    const openDays = openingDaysStatus.filter((day) => day.open);
    const openDaysForm: any = [];
    openDays.forEach((day) => {
      openDaysForm.push({
        openHour: getter(`days.opening${day.id}`),
        closeHour: getter(`days.closing${day.id}`),
        id: day.id
      });
    });
    if (values?.days) {
      let errorMessages: any = {};
      openDaysForm.forEach((day: any) => {
        if (day?.openHour(values) > day?.closeHour(values)) {
          errorMessages = {
            ...errorMessages,
            [`days.opening${day.id}`]: 'Opening hour cant be later than closing hour'
          };
        }
      });
      const timeZoneGetter = getter('timezone');
      if (openDaysForm.length > 0 && !timeZoneGetter(values)) {
        errorMessages = {
          ...errorMessages,
          ['timezone']: 'Timezone must be selected'
        };
      }
      return errorMessages;
    }
  };

  const toggleSwitch = (id: number, itemAttributes: any) => {
    const index = openingDaysStatus.findIndex((x) => x.id === id);

    setOpeningDaysStatus((prev) => [
      ...prev.slice(0, index),
      Object.assign({}, prev[index], itemAttributes),
      ...prev.slice(index + 1)
    ]);
  };

  return (
    <DefaultLayout>
      <InnerWrapper>
        <HeaderComponent headerText={existingCompanyData ? 'Edit location' : 'Add location'} />
        <StyledCard>
          <Form
            initialValues={existingCompanyData ? transformExistingData(existingCompanyData) : []}
            onSubmit={handleSubmit}
            validator={openingDaysValidator}
            key={statesKey}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement>
                <FieldsWrapper>
                  <Fieldset
                    className={'k-form-fieldset'}
                    style={{ margin: 0, justifySelf: 'start' }}>
                    <legend>Basic information</legend>
                    <Field
                      data-testid='name'
                      id={'name'}
                      name={'name'}
                      label={'Location name'}
                      component={FormInput}
                      validator={requiredValidator}
                    />
                    {isSuperAdmin && (
                      <Field
                        data-testid='company'
                        id={'company'}
                        name={'company'}
                        label={'Company'}
                        component={FormComboBox}
                        validator={requiredValidator}
                        data={companies}
                        filterable={true}
                        textField='name'
                        onChange={onCompanyChange}
                      />
                    )}
                    <Field
                      data-testid='address1'
                      id={'address1'}
                      name={'address1'}
                      label={'Street Address (Line 1)'}
                      component={FormInput}
                      validator={requiredValidator}
                    />
                    <Field
                      data-testid='address2'
                      id={'address2'}
                      name={'address2'}
                      label={'Street Address (Line 2)'}
                      optional={true}
                      component={FormInput}
                    />
                    <Field
                      data-testid='city'
                      id={'city'}
                      name={'city'}
                      label={'City'}
                      component={FormInput}
                      validator={requiredValidator}
                    />
                    <StateWrapper>
                      <Field
                        data-testid='state'
                        id={'state'}
                        name={'state'}
                        label={'State / Province'}
                        component={FormComboBox}
                        validator={requiredValidator}
                        filterable={true}
                        textField='name'
                        key={`state-${states?.length || 0}`}
                        data={states}
                      />
                      <Field
                        data-testid='postal_code'
                        id={'postal_code'}
                        name={'postal_code'}
                        label={'Postal code'}
                        optional={true}
                        component={FormInput}
                      />
                    </StateWrapper>
                  </Fieldset>
                  <Fieldset className={'k-form-fieldset'} style={{ margin: 0 }}>
                    <legend>Contact details</legend>
                    <PhoneWrapper>
                      {formRenderProps.valueGetter('phone') && (
                        <Field
                          data-testid='phone_code'
                          id={'phone_code'}
                          name={'phone_code'}
                          label={'Country code'}
                          component={FormCountryCode}
                          validator={requiredValidator}
                        />
                      )}
                      <Field
                        data-testid='phone'
                        id={'phone'}
                        name={'phone'}
                        label={'Phone Number'}
                        component={FormMaskedTextBox}
                        validator={phoneValidatorOptional}
                        optional={true}
                      />
                    </PhoneWrapper>
                    <Field
                      data-testid='email'
                      id={'email'}
                      name={'email'}
                      label={'E-mail address'}
                      component={FormInput}
                      validator={emailValidatorOptional}
                      optional={true}
                    />
                    <Field
                      data-testid='website'
                      id={'website'}
                      name={'website'}
                      label={'Website URL'}
                      component={FormInput}
                      optional={true}
                    />
                    <OpeningHoursWrapper style={{ marginTop: '32px' }}>
                      <legend>Opening hours</legend>
                      <Field
                        data-testid='timezone'
                        id={'timezone'}
                        name={'timezone'}
                        component={FormComboBox}
                        filterable={true}
                        key={`timezones-${timezones?.length || 0}`}
                        data={timezones}
                        disabled={openingDaysStatus.filter((day) => day.open).length <= 0}
                      />
                    </OpeningHoursWrapper>
                    <WeekdaysWrapper>
                      {WEEKDAYS.map((weekday) => {
                        const dateFromDefault = new Date();
                        dateFromDefault.setHours(9, 0, 0, 0);
                        const dateToDefault = new Date();
                        dateToDefault.setHours(21, 0, 0, 0);
                        return (
                          <WeekdaysInnerWrapper key={weekday.id + weekday.name}>
                            <SwitchWrapper label={weekday.name}>
                              <Switch
                                checked={
                                  openingDaysStatus?.find((day) => day?.id === weekday?.id)?.open
                                }
                                onChange={() => {
                                  toggleSwitch(weekday.id, {
                                    open: !openingDaysStatus?.find((day) => day?.id === weekday?.id)
                                      ?.open
                                  });
                                  if (
                                    isEmpty(
                                      formRenderProps.valueGetter(`days.opening${weekday.id}`)
                                    )
                                  ) {
                                    formRenderProps.onChange(`days.opening${weekday.id}`, {
                                      value: dateFromDefault
                                    });
                                    formRenderProps.onChange(`days.closing${weekday.id}`, {
                                      value: dateToDefault
                                    });
                                  }
                                  if (!formRenderProps.valueGetter(`timezone`)) {
                                    formRenderProps.onChange(`timezone`, {
                                      value: dayjs?.tz?.guess()
                                    });
                                  }
                                }}
                              />
                            </SwitchWrapper>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '270px',
                                gap: '8px'
                              }}>
                              <Field
                                data-testid={`days-opening${weekday.id}`}
                                id={`days.opening${weekday.id}`}
                                name={`days.opening${weekday.id}`}
                                component={FormTimePicker}
                                format='h:mm a'
                                optional={true}
                                defaultValue={dateFromDefault}
                                disabled={
                                  !openingDaysStatus?.find((day) => day?.id === weekday?.id)?.open
                                }
                              />
                              <span>to</span>
                              <Field
                                data-testid={`days-closing${weekday.id}`}
                                id={`days.closing${weekday.id}`}
                                name={`days.closing${weekday.id}`}
                                component={FormTimePicker}
                                format='h:mm a'
                                optional={true}
                                defaultValue={dateToDefault}
                                disabled={
                                  !openingDaysStatus?.find((day) => day?.id === weekday?.id)?.open
                                }
                              />
                            </div>
                          </WeekdaysInnerWrapper>
                        );
                      })}
                    </WeekdaysWrapper>
                  </Fieldset>
                  <Fieldset className={'k-form-fieldset'} style={{ margin: 0, justifySelf: 'end' }}>
                    <legend>Additional information</legend>
                    <Field
                      data-testid='about'
                      id={'about'}
                      name={'about'}
                      label={'About'}
                      component={FormTextArea}
                      rows={3}
                      optional={true}
                      maxLength={200}
                    />
                    <Field
                      data-testid='id_number'
                      id={'id_number'}
                      name={'id_number'}
                      label={'ID number'}
                      optional={true}
                      component={FormInput}
                    />
                    <Field
                      data-testid='tax_number'
                      id={'tax_number'}
                      name={'tax_number'}
                      label={'Tax number'}
                      optional={true}
                      component={FormInput}
                    />
                  </Fieldset>
                </FieldsWrapper>
                <ButtonsWrapper className='k-form-buttons'>
                  <Button data-testid='cancel-location-form' onClick={goBack} type='button'>
                    Cancel
                  </Button>
                  <Button
                    data-testid='submit-location-form'
                    themeColor={'primary'}
                    type='submit'
                    disabled={!formRenderProps.allowSubmit || isLoading}>
                    {existingCompanyData ? 'Edit location' : 'Create location'}
                    {isLoading && <LoaderButton />}
                  </Button>
                </ButtonsWrapper>
              </FormElement>
            )}
          />
        </StyledCard>
      </InnerWrapper>
    </DefaultLayout>
  );
};

export default AddLocation;
