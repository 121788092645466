import { Grid } from '@progress/kendo-react-grid';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { getTableSize } from '../../utils/tableHelpers';
import { ActionButtonsWrapper } from '../../components/Table/styles';
import {
  ActionButton,
  ActionButtonError,
  ActionButtonGeneral,
  ActionButtonPrimary
} from '../../components/ActionButton/ActionButton';
import { SvgIcon } from '@progress/kendo-react-common';
import * as svgIcons from '@progress/kendo-svg-icons';
import {
  useUserGoalDelete,
  useUserGoalProgress,
  useUserGoalUpdate,
  useUserGoalsInfinite
} from '../../hooks/api/useGoals';
import { useCallback, useEffect, useState, useRef } from 'react';
import dayjs from 'dayjs';
import { GOALS_EDIT } from 'constants/routes';
import { useHistory } from 'react-router-dom';
import { GoalConditionType, GoalEntryPayload } from 'api/goals/goals.types';
import { timeFrequenciesMap, timePeriodsMap } from 'utils/definesLocal';
import {
  ExerciseGoalsGraph,
  GripGoalsCountGraph,
  SwitchesGoalsCountGraph
} from 'components/DeviceUsageMonitoring/Graphs';
import useDeviceUsageTab, { filterParser } from 'pages/DeviceUsage/useDeviceUsageTab';
import { PeriodEnum } from 'api/deviceUsageMonitoring/deviceUsageMonitoring.types';
import { useDevicesList } from 'hooks/api/useDevices';
import { DeviceExtendOptions, DevicesQueryParams, DeviceEntry } from 'api/devices/device.types';
import UsageMonitoringGraph from 'components/DeviceUsageMonitoring/UsageMonitoringGraph';
import { Checkbox } from '@progress/kendo-react-inputs';
import {
  useDeviceUsageChartData,
  useDeviceUsageGripsCountData
} from 'hooks/api/useDeviceUseageMonitoring';
import chroma from 'chroma-js';
import { successNotification } from 'utils/notifications';
import {
  transformExercises,
  TransformedExercise,
  groupGoalsValues,
  getGripsInGoal,
  getSwitchesInGoal,
  colors,
  formatGoal,
  GoalsStatus,
  goalsStatusMap
} from './utils';
import { CheckboxesWrapper, CustomBadge } from './styled';
import { useModal } from 'hooks/api/useModal';
import ConfirmDeleteModal, { ModalMessageDelete } from 'components/Modals/ConfirmDeleteModal';
import { Loader } from '@progress/kendo-react-indicators';
import useElementSize from 'hooks/useElementSize';

const colorScale = colors;
const GOAL_GRAPH_HEIGHT = '600px';

const DetailComponent = ({ goal, devices }: { goal: GoalEntryPayload; devices: DeviceEntry[] }) => {
  const gripsGoals = getGripsInGoal(goal);
  const switchesGoal = getSwitchesInGoal(goal);
  const chartRef = useRef(null);
  const chartSize = useElementSize(chartRef);

  const { chartData: gripsPerformedData, handleFilterChange: handleGripsGoalFilter } =
    useDeviceUsageTab(
      useDeviceUsageGripsCountData,
      { group: timeFrequenciesMap.get(gripsGoals?.gripsFrequency) },
      filterParser.default
    );
  const { chartData: gripsSwitchesData, handleFilterChange: handleSwitchesGoalFilter } =
    useDeviceUsageTab(
      useDeviceUsageChartData,
      { group: timeFrequenciesMap.get(switchesGoal?.switchesFrequency) },
      filterParser.default
    );
  const [selectedGrips, setSelectedGrips] = useState<any>();

  useEffect(() => {
    if (gripsGoals?.gripsNames) {
      setSelectedGrips(
        gripsGoals?.gripsNames.map((gripName, index) => ({
          name: gripName,
          value: true,
          color: colorScale[index]
        }))
      );
    }
  }, [JSON.stringify(gripsGoals)]);

  const goalStartDate = new Date(goal.start_date);
  const goalEndDate = new Date(goal.end_date);
  const gripsPerformedGrouped = groupGoalsValues(
    gripsPerformedData,
    gripsGoals!.gripsFrequency,
    GoalConditionType.grip,
    {
      goalStartDate,
      goalEndDate
    },
    // @ts-ignore
    gripsGoals.gripsNames
  );

  const switchesPerformedGrouped = groupGoalsValues(
    gripsSwitchesData,
    switchesGoal!.switchesFrequency,
    GoalConditionType.switch,
    {
      goalStartDate,
      goalEndDate
    },
    // @ts-ignore
    gripsGoals.gripsNames
  );

  const handleCheckboxChange = (gripToggle: any) => {
    setSelectedGrips((prev: any) =>
      prev.map((_gripToggle: any) => {
        if (_gripToggle.name === gripToggle.name)
          return { ..._gripToggle, value: !_gripToggle.value };

        return _gripToggle;
      })
    );
  };

  const filteredGripsKeys: any = () => {
    if (!selectedGrips) return [];

    return {
      keys: gripsGoals?.gripsNames.filter((name: any) => {
        const obj: any = selectedGrips.find((o: any) => o.name === name);
        return obj && obj.value;
      }),
      markers: gripsGoals?.gripsInvolved.filter((grip: any) => {
        const obj: any = selectedGrips.find((o: any) => o.name === grip.name);
        return obj && obj.value;
      }),
      getColor: (datumName: any) => {
        const mapping = selectedGrips.find((item: any) => item.name === datumName);
        return mapping.color;
      }
    };
  };

  const renderGripsGraph = useCallback(
    () => (
      <GripGoalsCountGraph
        legend={`Date (${timePeriodsMap.get(gripsGoals?.gripsFrequency)})`}
        data={gripsPerformedGrouped ?? []}
        markers={
          filteredGripsKeys()?.markers
            ? filteredGripsKeys()?.markers.map((grip: any) => ({
                axis: 'y',
                value: grip.grips_count,
                lineStyle: {
                  stroke: selectedGrips.find((_grip: any) => _grip.name === grip.name).color,
                  strokeWidth: 2,
                  strokeDasharray: '10'
                },
                legend: 'Goal',
                legendOrientation: 'horizontal'
              }))
            : []
        }
        keys={filteredGripsKeys()?.keys}
        colors={(datum: any) => filteredGripsKeys()?.getColor(datum.id)}
        size={chartSize.width}
      />
    ),
    [gripsGoals, gripsPerformedGrouped, selectedGrips, chartSize]
  );

  const renderSwitchesGraph = useCallback(
    () => (
      <SwitchesGoalsCountGraph
        legend={`Date (${timePeriodsMap.get(switchesGoal?.switchesFrequency)})`}
        data={switchesPerformedGrouped ?? []}
        keys={['Switches']}
        markers={
          switchesGoal
            ? [
                {
                  axis: 'y',
                  value: switchesGoal.switchesCount,
                  lineStyle: {
                    stroke: colorScale[13],
                    strokeWidth: 2,
                    strokeDasharray: '10'
                  },
                  legend: 'Goal',
                  legendOrientation: 'horizontal'
                }
              ]
            : []
        }
        colors={colorScale}
        size={chartSize.width}
      />
    ),
    [switchesGoal, colorScale, switchesPerformedGrouped, chartSize]
  );

  return (
    <div style={{ height: GOAL_GRAPH_HEIGHT, overflowY: 'scroll' }} ref={chartRef}>
      <UsageMonitoringGraph
        totalCounter={false}
        header='Grips performed progress'
        devices={devices ?? []}
        initialFilters={{
          period: PeriodEnum.day,
          dateRange: {
            start: goalStartDate,
            end: goalEndDate
          }
        }}
        additionalFilterProps={{
          dateRange: {
            min: goalStartDate,
            max: goalEndDate,
            disabled: true
          }
        }}
        extraFilters={
          selectedGrips && (
            <CheckboxesWrapper>
              {selectedGrips.map((gripToggle: any) => (
                <Checkbox
                  value={gripToggle.value}
                  key={gripToggle.name}
                  label={gripToggle.name}
                  onChange={() => handleCheckboxChange(gripToggle)}
                />
              ))}
            </CheckboxesWrapper>
          )
        }
        onFilterChange={handleGripsGoalFilter}
        deviceFilter
        dateRangeFilter
        graphHeight={GOAL_GRAPH_HEIGHT}
        graphDataSource={[]}
        GraphComponent={renderGripsGraph}
      />
      <UsageMonitoringGraph
        header='Switches performed progress'
        devices={devices ?? []}
        totalCounter={false}
        initialFilters={{
          instance: 'Instance',
          period: PeriodEnum.day,
          dateRange: { start: goalStartDate, end: goalEndDate }
        }}
        additionalFilterProps={{
          dateRange: {
            min: goalStartDate,
            max: goalEndDate,
            disabled: true
          }
        }}
        onFilterChange={handleSwitchesGoalFilter}
        deviceFilter
        dateRangeFilter
        graphHeight={GOAL_GRAPH_HEIGHT}
        graphDataSource={[]}
        GraphComponent={renderSwitchesGraph}
      />
      <ExerciseGraphComponent goal={goal} size={chartSize} />
    </div>
  );
};

const CustomTooltip = ({ point }: any) => {
  return (
    <div
      style={{
        background: '#626C84',
        padding: '9px 12px',
        borderRadius: '4px',
        fontSize: '12px',
        color: 'white'
      }}>
      {`Failed attempts: ${point.data.unsuccessful}`}
    </div>
  );
};

const CustomPoint = ({ size, borderWidth, borderColor, datum }: any) => {
  return (
    <circle
      r={size}
      fill={datum.unsuccessful > 0 ? colorScale[13] : colorScale[6]}
      strokeWidth={borderWidth}
      stroke={borderColor}
    />
  );
};

const ExerciseGraphComponent = ({ goal, size }: { goal: GoalEntryPayload; size: any }) => {
  const { result: exercises } = useUserGoalProgress({
    goalId: goal.id,
    transformData: (data) => transformExercises(data, goal)
  });

  const [selectedExercise, setSelectedExercise] = useState<TransformedExercise>();

  const renderExercisesGraph = useCallback(
    () => (
      <ExerciseGoalsGraph
        useMesh={true}
        tooltip={CustomTooltip}
        pointSymbol={CustomPoint}
        markers={
          selectedExercise?.exercise_count
            ? [
                {
                  axis: 'y',
                  value: selectedExercise?.exercise_count,
                  lineStyle: {
                    stroke: colorScale[6],
                    strokeWidth: 2,
                    strokeDasharray: '10'
                  },
                  legend: 'Goal',
                  legendOrientation: 'horizontal'
                }
              ]
            : []
        }
        colors={[colorScale[6]]}
        legend={`Time (${timePeriodsMap.get(selectedExercise?.exercise_frequency)})`}
        size={size.width}
        data={[
          {
            id: 'Successful attempts',
            data: selectedExercise?.attempts ? selectedExercise.attempts : []
          }
        ]}
      />
    ),
    [selectedExercise, colorScale, size]
  );

  // @ts-ignore
  if (exercises?.length === 0) {
    return null;
  }

  return (
    exercises && (
      <UsageMonitoringGraph
        totalCounter={false}
        deviceFilter={false}
        header='Exercises performed progress'
        initialFilters={{
          period: PeriodEnum.day
        }}
        onFilterChange={({ exercise }) => setSelectedExercise(exercise)}
        graphHeight={'600px'}
        graphDataSource={[]}
        exercises={exercises}
        exerciseFilter
        GraphComponent={renderExercisesGraph}
      />
    )
  );
};

const canDeleteGoal = (goal: GoalEntryPayload) => {
  const timeNow = new Date();
  const dateNow = new Date(timeNow.getFullYear(), timeNow.getMonth(), timeNow.getDate()).getTime();
  const startTime = new Date(goal.start_date).getTime();
  const endTime = new Date(goal.end_date);
  const endTimeAdjusted = endTime.setDate(endTime.getDate() + 1);

  return dateNow < startTime || dateNow > endTimeAdjusted || goal.active !== 1;
};

const goalStatus = (goal: GoalEntryPayload) => {
  const timeNow = new Date();
  const dateNow = new Date(timeNow.getFullYear(), timeNow.getMonth(), timeNow.getDate()).getTime();
  const startTime = new Date(goal.start_date).getTime();
  const endTime = new Date(goal.end_date);
  const endTimeAdjusted = endTime.setDate(endTime.getDate() + 1);

  if (dateNow < startTime) {
    if (goal.active === 0) return GoalsStatus.inactive;

    return GoalsStatus.awaiting;
  }

  if (dateNow >= startTime && dateNow < endTimeAdjusted) {
    if (goal.active === 0) return GoalsStatus.inactive;

    return GoalsStatus.inProgress;
  }

  return GoalsStatus.finished;
};

const canToggleActivity = (goal: GoalEntryPayload) => {
  const status = goalStatus(goal);
  const timeNow = new Date();
  const dateNow = new Date(timeNow.getFullYear(), timeNow.getMonth(), timeNow.getDate()).getTime();
  const startTime = new Date(goal.start_date).getTime();
  return (
    status === GoalsStatus.awaiting ||
    (status === GoalsStatus.inactive && dateNow < startTime) ||
    status === GoalsStatus.inProgress
  );
};

const GoalUsageTab = ({ userId, initialGoal }: { userId: number; initialGoal?: number }) => {
  const { result, isLoading } = useUserGoalsInfinite({
    userId
  });
  const { push } = useHistory();
  const [data, setData] = useState<any>(null);
  const [selectedGoal, setSelectedGoal] = useState<GoalEntryPayload>();
  const queryParamsDevices: DevicesQueryParams = {
    amputee: String(userId),
    extend: [DeviceExtendOptions.model, DeviceExtendOptions.amputee]
  };
  const [initialGoalLoaded, setInitialGoalLoaded] = useState<boolean>(false);
  const { result: devices } = useDevicesList(queryParamsDevices);
  const { mutateAsync: deleteGoal, isLoading: isLoadingDeleteGoal } = useUserGoalDelete();
  const { mutateAsync: updateGoal, isLoading: isLoadingUpdateGoal } = useUserGoalUpdate();
  const {
    isOpen: isModalOpen,
    handleOpen: handleDeleteModalOpen,
    handleClose: handleDeleteModalClose
  } = useModal();
  const {
    isOpen: isModalToggleOpen,
    handleOpen: handleToggleModalOpen,
    handleClose: handleToggleModalClose
  } = useModal();

  useEffect(() => {
    if (result && !isLoading) {
      const formattedResult = result
        .map((item: any) => ({
          ...item,
          start_date: dayjs(item.start_date).format('DD MMM YYYY'),
          end_date: dayjs(item.end_date).format('DD MMM YYYY')
        }))
        .sort((goalA: any, goalB: any) => (goalA.created_at - goalB.created_at > 0 ? 1 : -1));

      setData(formattedResult);
    }
  }, [isLoading, JSON.stringify(result)]);

  useEffect(() => {
    if (initialGoal && data && !initialGoalLoaded) {
      setInitialGoalLoaded(true);
      expandChange(initialGoal);
    }
  }, [initialGoal, JSON.stringify(data)]);

  useEffect(() => {
    if (initialGoalLoaded) {
      const element = document.getElementById(`goal-${initialGoal}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [initialGoalLoaded, initialGoal]);

  const expandChange = (goalId: number) => {
    setData((prev: any) =>
      prev.map((item: any) => {
        let expanded = item.expanded;
        if (item.id === goalId) {
          expanded = !item.expanded;
        }
        return { ...item, expanded };
      })
    );
  };

  const handleEdit = (goal: GoalEntryPayload, isView: boolean = false) => {
    const startDate = dayjs(goal.start_date);
    const endDate = dayjs(goal.end_date);
    const period = endDate.diff(startDate, 'day') + 1;
    const goalFormatted = formatGoal(goal.conditions!);
    push({
      pathname: GOALS_EDIT,
      state: {
        initialValues: { start_date: new Date(goal.start_date), period, ...goalFormatted },
        userId,
        goalId: goal.id,
        conditions: goal.conditions,
        isView
      }
    });
  };

  const toggleGoalActivity = async (e: GoalEntryPayload) => {
    try {
      await updateGoal({
        userId: e.amputee_id,
        goalId: e.id,
        active: Number(e.active) ? 0 : 1
      });
      successNotification('Goal updated');
    } catch (e) {
      console.log(e);
    }
  };

  const deleteGoalModal = async () => {
    try {
      if (selectedGoal) {
        await deleteGoal({ userId: selectedGoal.amputee_id, goalId: selectedGoal.id });
        successNotification('Goal deleted');
        handleDeleteModalClose();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleToggleActivityGoal = async (e: GoalEntryPayload) => {
    if (goalStatus(e) !== GoalsStatus.inProgress) {
      toggleGoalActivity(e);
      return;
    }

    setSelectedGoal(e);
    handleToggleModalOpen();
  };

  const handleDeleteGoal = async (e: GoalEntryPayload) => {
    setSelectedGoal(e);
    handleDeleteModalOpen();
  };

  const renderDetailComponent = useCallback(
    (e: any) => <DetailComponent goal={e.dataItem} devices={devices} />,
    [devices]
  );

  if (!data) {
    return <Loader />;
  }

  return (
    <>
      {isModalOpen && selectedGoal && (
        <ConfirmDeleteModal
          handleClose={handleDeleteModalClose}
          handleAccept={deleteGoalModal}
          isLoading={isLoadingDeleteGoal}
          message={<ModalMessageDelete id={selectedGoal?.id} text='Do you want to delete goal' />}
        />
      )}
      {isModalToggleOpen && selectedGoal && (
        <ConfirmDeleteModal
          handleClose={handleToggleModalClose}
          handleAccept={async () => {
            await toggleGoalActivity(selectedGoal);
            handleToggleModalClose();
          }}
          isLoading={isLoadingUpdateGoal}
          message={
            'Are you sure you want to turn off this goal? Since the goal has already started, it will not be possible to turn it on again.'
          }
          title='Turn off goal'
          acceptMessage='Yes, turn off'
          icon={'lock'}
        />
      )}
      <Grid
        data={data}
        detail={renderDetailComponent}
        expandField='expanded'
        onExpandChange={(e) => expandChange(e.dataItem.id)}>
        <Column field='start_date' title='Start date' />
        <Column field='end_date' title='End date' />
        <Column
          field='active'
          title='Active'
          cell={(e: any) => (
            <td>
              <div style={{ display: 'flex' }}>
                <CustomBadge status={goalStatus(e.dataItem)}>
                  {goalsStatusMap.get(goalStatus(e.dataItem))}
                </CustomBadge>
              </div>
            </td>
          )}
        />
        <Column
          width={getTableSize(4)}
          title='Actions'
          cell={(e: any) => (
            <ActionButtonsWrapper id={`goal-${e.dataItem.id}`}>
              <ActionButton onClick={() => expandChange(e.dataItem.id)} title='Show graph'>
                <SvgIcon icon={svgIcons['graphIcon']} size='medium' />
              </ActionButton>
              <ActionButtonPrimary
                data-testid='button-view'
                title='View goal details'
                onClick={() => handleEdit(e.dataItem, true)}>
                <SvgIcon icon={svgIcons['eyeIcon']} size='medium' />
              </ActionButtonPrimary>
              {canToggleActivity(e.dataItem) && (
                <ActionButtonGeneral
                  data-testid='button-update'
                  title={e.dataItem.active ? 'Turn off' : 'Turn on'}
                  variant={e.dataItem.active ? 'success' : 'error'}
                  onClick={() => handleToggleActivityGoal(e.dataItem)}>
                  <SvgIcon
                    icon={svgIcons[e.dataItem.active ? 'unlockIcon' : 'lockIcon']}
                    size='medium'
                  />
                </ActionButtonGeneral>
              )}
              {canDeleteGoal(e.dataItem) && (
                <ActionButtonError
                  data-testid='button-delete'
                  onClick={() => handleDeleteGoal(e.dataItem)}
                  title='Delete'>
                  <SvgIcon icon={svgIcons['trashIcon']} size='medium' />
                </ActionButtonError>
              )}
            </ActionButtonsWrapper>
          )}
        />
      </Grid>
    </>
  );
};

export default GoalUsageTab;
