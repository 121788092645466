import { Grips, gripsGroupsOptionsMap } from '../utils/definesLocal';

export const grips_options = [
  { text: gripsGroupsOptionsMap.get(Grips.kGripRestOpp), id: Grips.kGripRestOpp },
  { text: gripsGroupsOptionsMap.get(Grips.kGripPower), id: Grips.kGripPower },
  { text: gripsGroupsOptionsMap.get(Grips.kGripTripodClosed), id: Grips.kGripTripodClosed },
  { text: gripsGroupsOptionsMap.get(Grips.kGripTrigger), id: Grips.kGripTrigger },
  { text: gripsGroupsOptionsMap.get(Grips.kGripPrecisionOpen), id: Grips.kGripPrecisionOpen },
  { text: gripsGroupsOptionsMap.get(Grips.kGripRestNopp), id: Grips.kGripRestNopp },
  { text: gripsGroupsOptionsMap.get(Grips.kGripHook), id: Grips.kGripHook },
  { text: gripsGroupsOptionsMap.get(Grips.kGripKey), id: Grips.kGripKey },
  { text: gripsGroupsOptionsMap.get(Grips.kGripMouse), id: Grips.kGripMouse },
  { text: gripsGroupsOptionsMap.get(Grips.kGripFingerPoint), id: Grips.kGripFingerPoint },
  { text: gripsGroupsOptionsMap.get(Grips.kGripCamera), id: Grips.kGripCamera },
  { text: gripsGroupsOptionsMap.get(Grips.kGripTripodOpen), id: Grips.kGripTripodOpen },
  { text: gripsGroupsOptionsMap.get(Grips.kGripPrecisionClosed), id: Grips.kGripPrecisionClosed },
  { text: gripsGroupsOptionsMap.get(Grips.kGripCount), id: Grips.kGripCount },
  { text: gripsGroupsOptionsMap.get(Grips.kGripCounting), id: Grips.kGripCounting },
  { text: gripsGroupsOptionsMap.get(Grips.kGripTypeUnknown), id: Grips.kGripTypeUnknown }
];

export type GripsType = (typeof grips_options)[number];
export type GripsWithAllType = GripsType | { text: 'All'; id: 'all' };
