import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useUserData from 'hooks/useUserData';
import { HOME } from 'constants/routes';

const GeneralRedirect = () => {
  const { rolesByName } = useUserData();
  const { push } = useHistory();

  useEffect(() => {
    if (localStorage.getItem('redirectUrl')) {
      push(localStorage.getItem('redirectUrl') || HOME);
      localStorage.removeItem('redirectUrl');
      return;
    }

    push({ pathname: HOME });
  }, [rolesByName]);

  return null;
};

export default GeneralRedirect;
