import './theme/web-therapy.css';

import * as Sentry from '@sentry/react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import AddCompany from './pages/Companies/AddCompany';
import Login from './pages/Auth/Login';
import SetUpPass from './pages/Auth/SetUpPass/SetUpPass';
import { useEffect, useState } from 'react';
import { setApiAuthToken } from 'api/utils/apiClient';
import { useSelector } from 'react-redux';
import { authSelector, mfaSelector, tokenSelector } from 'reducers/authReducer';
import Dashboard from './pages/Dashboard/Dashboard';
import TokenRefresh from './pages/Auth/TokenRefresh';
import ConfirmationLoader from './layouts/ConfirmationLoader';
import CompaniesList from './pages/Companies/CompaniesList';
import CompanyDetails from './pages/Companies/CompanyDetails';
import CliniciansManage from './pages/Clinicians/CliniciansManage';
import ClinicianDetails from './pages/Clinicians/ClinicianDetails';
import AddClinician from './pages/Clinicians/AddClinician';
import EditClinician from './pages/Clinicians/EditClinician';
import LocationsList from './pages/Locations/LocationsList';
import AddLocation from './pages/Locations/AddLocation';
import LocationDetails from './pages/Locations/LocationDetails';
import DevicesList from './pages/Devices/DevicesList';
import AddDevice from './pages/Devices/AddDevice';
import DeviceDetails from './pages/Devices/DeviceDetails';
import AccountDetails from './pages/Account/AccountDetails';
import ChangePassword from './pages/Account/ChangePassword';
import AddMessage from './pages/Message/AddMessage';
import { AuthRoute } from 'hoc/AuthRoute';
import PatientsList from './pages/Patients/PatientsList';
import AddPatient from './pages/Patients/Forms/AddPatient';
import EditPatient from './pages/Patients/Forms/EditPatient';
import PatientDetails from './pages/Patients/PatientDetails';
import UserManageList from './pages/UserManage/UserManageList';
import UserManageEdit from './pages/UserManage/UserManageEdit';
import ResetPassword from './pages/Auth/ResetPassword';
import useUserData from './hooks/useUserData';
import { RoleEnum } from 'api/users/users.types';
import ErrorBoundary from './layouts/ErrorBoundary';
import NotFoundScreenAuthentication from './pages/404/NotFoundScreenAuthentication';
import MfaAuth from './pages/Auth/MfaAuth';
import ServicingForm from './pages/Servicing/ServicingForm';
import { ticketIdSelector } from 'reducers/redirectsReducer';
import TicketRedirect from './pages/TicketRedirect/TicketRedirect';
import { Toaster } from 'react-hot-toast';
import * as svgIcons from '@progress/kendo-svg-icons';
import { SvgIcon } from '@progress/kendo-react-common';
import * as routes from './constants/routes';
import ReleaseNoteList from './pages/ReleaseNote/ReleaseNoteList';
import ReleaseNoteAdd from './pages/ReleaseNote/ReleaseNoteAdd';
import ReleaseNoteAddCompatibility from './pages/ReleaseNote/ReleaseNoteAddCompatibility';
import VersionAdd from './pages/ReleaseNote/Versions/VersionAdd';
import ReleaseNoteEditCompatibility from './pages/ReleaseNote/ReleaseNoteEditCompatibility';
import {
  ALLIED_PERMISSIONS,
  CHAT_PERMISSIONS,
  COMPANIES_PERMISSIONS,
  DEVICES_PERMISSIONS,
  GOALS_PERMISSIONS,
  LOCATIONS_PERMISSIONS,
  PATIENTS_PERMISSIONS
} from 'constants/rolesPermissions';
import TogglesList from 'pages/FeatureToggle/TogglesList';
import AddGoal from 'pages/Goals/AddGoal';
import EditGoal from 'pages/Goals/EditGoal';
import { allowedRedirectUrls } from 'constants/redirectUrls';
import GeneralRedirect from 'pages/TicketRedirect/GeneralRedirect';
import ChatWidget from 'features/Chat/components/ChatWidget';
import { userHasPermissions } from 'utils/permissionUtils';
import { FeatureToggle } from 'AppProviders';
import { CHAT } from 'constants/featureToggles';
import AutoLogout from 'components/AutoLogout/AutoLogout';
import useRefreshToken from 'hoc/useRefreshToken';
import ChatWidgetUnreadMessageListener from 'features/Chat/components/ChatRoomUnreadMessageListener';
import DocumentsModal from 'components/Modals/DocumentsModal';
import useDocumentsAcceptanceModal from 'components/DocumentsAcceptance/DocumentsAcceptance';

const SentryRoute = Sentry.withSentryRouting(Route);
const filteredRedirectUrls = (pathname: string) =>
  allowedRedirectUrls.filter((allowedUrl) => {
    const pattern = new RegExp('^' + allowedUrl.replace(/:\w+/g, '\\w*') + '$');
    return pattern.test(pathname);
  });

const App = () => {
  const token = useSelector(tokenSelector);
  const mfa = useSelector(mfaSelector);
  const { isUserLoggingOut } = useSelector(authSelector);
  const { refreshToken } = useRefreshToken();
  const [show, setShow] = useState(false);
  const currentUser = useUserData();
  const { data: userData, rolesByName, isLoading: loadingUserData } = currentUser;
  const ticketId = useSelector(ticketIdSelector);
  const loggedIn = Boolean(token);
  const {
    logoutDocuments,
    submitDocuments,
    isLoadingDocuments,
    documentStatusModalOpen,
    documentsResults
  } = useDocumentsAcceptanceModal(loggedIn && userData !== undefined);

  const showDashboard =
    loggedIn && userData !== undefined && mfa.required === false && null !== documentsResults;

  useEffect(() => {
    if (Boolean(token) && mfa.required === false && userData !== undefined) {
      setShow(true);
      Sentry.setUser(userData);
      return;
    }

    if (token && mfa.required === true) {
      setShow(true);
      Sentry.setUser(userData);
      return;
    }

    if (!token) {
      refreshToken()
        .then(() => {})
        .catch(() => {
          if (filteredRedirectUrls(window.location.pathname).length > 0) {
            localStorage.setItem('redirectUrl', window.location.pathname + window.location.search);
          }
          setShow(true);
          return;
        });
    }

    setShow(false);
  }, [token, userData, refreshToken, mfa]);

  useEffect(() => {
    setApiAuthToken(token);
  }, [token]);

  useEffect(() => {
    if (isUserLoggingOut) {
      setShow(false);
    }
  }, [isUserLoggingOut]);

  const loadingNeseseryData = !show || isLoadingDocuments || loadingUserData;

  return (
    <Router>
      <div>
        <Toaster
          gutter={12}
          position='bottom-right'
          reverseOrder={false}
          containerStyle={{ zIndex: 10010 }}
          toastOptions={{
            duration: 10000,
            style: {
              borderRadius: '0.25rem',
              padding: '0.8rem 0.8rem',
              fontSize: '0.875rem',
              lineHeight: 1.5
            },
            success: {
              icon: <SvgIcon icon={svgIcons['checkOutlineIcon']} />,
              style: {
                background: '#bfddcf',
                color: '#0d462c'
              }
            },
            error: {
              icon: <SvgIcon icon={svgIcons['xIcon']} />,
              style: {
                background: '#f5c6cb',
                color: '#721c24'
              }
            }
          }}
        />
      </div>
      <SentryRoute path='*' component={TokenRefresh} />
      {!loadingNeseseryData && showDashboard && ticketId && (
        <>
          <ErrorBoundary>
            <Redirect to={routes.DASHBOARD} />
          </ErrorBoundary>
        </>
      )}
      {!loadingNeseseryData && showDashboard && (
        <>
          <ErrorBoundary>
            {documentStatusModalOpen && (
              <DocumentsModal
                title={documentsResults.texts.title}
                description={documentsResults.texts.description}
                checkboxText={documentsResults.texts.checkbox}
                acceptAction={submitDocuments}
                logoutAction={logoutDocuments}
                files={documentsResults.documents}
                isLoading={isLoadingDocuments}
              />
            )}
            <AutoLogout user={userData}>
              <FeatureToggle name={CHAT}>
                {userHasPermissions(CHAT_PERMISSIONS.VIEW, rolesByName) && (
                  <>
                    <ChatWidgetUnreadMessageListener />
                    <ChatWidget />
                  </>
                )}
              </FeatureToggle>
              <Switch>
                <Route exact path='/'>
                  <Redirect to={routes.DASHBOARD} />
                </Route>
                <AuthRoute
                  exact
                  path={routes.COMPANY}
                  component={CompaniesList}
                  user={currentUser}
                  roles={COMPANIES_PERMISSIONS.LIST}
                />
                <AuthRoute
                  path={routes.COMPANY_ADD}
                  component={AddCompany}
                  user={currentUser}
                  roles={COMPANIES_PERMISSIONS.CREATE}
                />
                <AuthRoute
                  path={routes.COMPANY_EDIT}
                  component={AddCompany}
                  user={currentUser}
                  roles={COMPANIES_PERMISSIONS.EDIT}
                />
                <AuthRoute
                  path={routes.COMPANY_DETAILS}
                  component={CompanyDetails}
                  user={currentUser}
                  roles={COMPANIES_PERMISSIONS.DETAILS}
                />
                <AuthRoute
                  exact
                  path={routes.LOCATION}
                  component={LocationsList}
                  user={currentUser}
                  roles={LOCATIONS_PERMISSIONS.LIST}
                />
                <AuthRoute
                  path={routes.LOCATION_ADD}
                  component={AddLocation}
                  user={currentUser}
                  roles={LOCATIONS_PERMISSIONS.CREATE}
                />
                <AuthRoute
                  path={routes.LOCATION_EDIT}
                  component={AddLocation}
                  user={currentUser}
                  roles={LOCATIONS_PERMISSIONS.EDIT}
                />
                <AuthRoute
                  path={routes.LOCATION_DETAILS}
                  component={LocationDetails}
                  user={currentUser}
                  roles={LOCATIONS_PERMISSIONS.DETAILS}
                />
                <AuthRoute
                  exact
                  path={routes.MANAGE_CLINICIANS_ROLE}
                  component={CliniciansManage}
                  user={currentUser}
                  roles={[
                    RoleEnum.superAdmin,
                    RoleEnum.clinician,
                    RoleEnum.clinicAdmin,
                    RoleEnum.clinicianSupport
                  ]}
                />
                <AuthRoute
                  exact
                  path={routes.MANAGE_CLINICIANS_PATIENTS}
                  component={CliniciansManage}
                  user={currentUser}
                  roles={PATIENTS_PERMISSIONS.LIST}
                />
                <AuthRoute
                  exact
                  path={routes.MANAGE_CLINICIANS_ALLIED}
                  component={CliniciansManage}
                  user={currentUser}
                  roles={ALLIED_PERMISSIONS.LIST}
                />
                <AuthRoute
                  path={routes.CLINICIAN_ADD}
                  component={AddClinician}
                  user={currentUser}
                  roles={ALLIED_PERMISSIONS.CREATE}
                />
                <AuthRoute
                  path={routes.CLINICIAN_EDIT}
                  component={EditClinician}
                  user={currentUser}
                  roles={ALLIED_PERMISSIONS.EDIT}
                />
                <AuthRoute
                  path={routes.CLINICIAN}
                  component={ClinicianDetails}
                  user={currentUser}
                  roles={ALLIED_PERMISSIONS.DETAILS}
                />
                <AuthRoute
                  exact
                  path={routes.USER_MANAGE}
                  component={UserManageList}
                  user={currentUser}
                  roles={[RoleEnum.superAdmin]}
                />
                <AuthRoute
                  path={routes.USER_MANAGE_EDIT}
                  component={UserManageEdit}
                  user={currentUser}
                  roles={[RoleEnum.superAdmin]}
                />
                <Route exact path={routes.DASHBOARD} component={Dashboard} />
                <AuthRoute
                  exact
                  path={routes.DEVICE}
                  component={DevicesList}
                  user={currentUser}
                  roles={DEVICES_PERMISSIONS.LIST}
                />
                <AuthRoute
                  path={routes.DEVICE_ADD}
                  component={AddDevice}
                  user={currentUser}
                  roles={DEVICES_PERMISSIONS.CREATE}
                />
                <AuthRoute
                  path={routes.DEVICE_EDIT}
                  component={AddDevice}
                  user={currentUser}
                  roles={DEVICES_PERMISSIONS.EDIT}
                />
                <AuthRoute
                  path={routes.DEVICE_DETAILS}
                  component={DeviceDetails}
                  user={currentUser}
                  roles={DEVICES_PERMISSIONS.DETAILS}
                />
                <AuthRoute exact path={routes.ACCOUNT} component={AccountDetails} />
                <AuthRoute path={routes.ACCOUNT_CHANGE_PASSWORD} component={ChangePassword} />
                <AuthRoute exact path={routes.MESSAGE} component={AddMessage} />
                <AuthRoute
                  exact
                  path={routes.PATIENTS}
                  component={PatientsList}
                  user={currentUser}
                  roles={PATIENTS_PERMISSIONS.LIST}
                />
                <AuthRoute
                  path={routes.PATIENT_ADD}
                  component={AddPatient}
                  user={currentUser}
                  roles={PATIENTS_PERMISSIONS.CREATE}
                />
                <AuthRoute
                  path={routes.PATIENT_EDIT}
                  component={EditPatient}
                  user={currentUser}
                  roles={PATIENTS_PERMISSIONS.EDIT}
                />
                <AuthRoute
                  path={routes.PATIENT}
                  component={PatientDetails}
                  user={currentUser}
                  roles={PATIENTS_PERMISSIONS.DETAILS}
                />
                <AuthRoute
                  exact
                  path={routes.SERVICING}
                  component={ServicingForm}
                  user={currentUser}
                  roles={[RoleEnum.superAdmin, RoleEnum.clinician, RoleEnum.clinicAdmin]}
                />
                <AuthRoute
                  exact
                  path={routes.RELEASES}
                  component={ReleaseNoteList}
                  user={currentUser}
                  roles={[RoleEnum.superAdmin]}
                />
                <AuthRoute
                  exact
                  path={routes.RELEASE_ADD}
                  component={ReleaseNoteAdd}
                  user={currentUser}
                  roles={[RoleEnum.superAdmin]}
                />
                <AuthRoute
                  exact
                  path={routes.RELEASE_EDIT}
                  component={ReleaseNoteEditCompatibility}
                  user={currentUser}
                  roles={[RoleEnum.superAdmin]}
                />
                <AuthRoute
                  exact
                  path={routes.VERSION_ADD}
                  component={VersionAdd}
                  user={currentUser}
                  roles={[RoleEnum.superAdmin]}
                />
                <AuthRoute
                  exact
                  path={routes.RELEASE_COMPATIBILITY_ADD}
                  component={ReleaseNoteAddCompatibility}
                  user={currentUser}
                  roles={[RoleEnum.superAdmin]}
                />
                <AuthRoute
                  exact
                  path={routes.RELEASE_COMPATIBILITY_EDIT}
                  component={ReleaseNoteAddCompatibility}
                  user={currentUser}
                  roles={[RoleEnum.superAdmin]}
                />
                <AuthRoute
                  exact
                  path={routes.FEATURE_TOGGLES}
                  component={TogglesList}
                  user={currentUser}
                  roles={[RoleEnum.superAdmin]}
                />
                <AuthRoute
                  exact
                  path={routes.GOALS_ADD}
                  component={AddGoal}
                  user={currentUser}
                  roles={GOALS_PERMISSIONS.CREATE}
                />
                <AuthRoute
                  exact
                  path={routes.GOALS_EDIT}
                  component={EditGoal}
                  user={currentUser}
                  roles={GOALS_PERMISSIONS.EDIT}
                />
                <AuthRoute
                  path={routes.FEATURE_TOGGLES}
                  component={TogglesList}
                  user={currentUser}
                  roles={[RoleEnum.superAdmin]}
                />
                <Route exact path={routes.TICKET_REDIRECT} component={TicketRedirect} />
                <Route exact path={routes.REDIRECT} component={GeneralRedirect} />
                <Route exact path={routes.LOGIN}>
                  <Redirect to={routes.DASHBOARD} />
                </Route>
                <SentryRoute path='*' component={NotFoundScreenAuthentication} />
              </Switch>
            </AutoLogout>
          </ErrorBoundary>
        </>
      )}
      {!loadingNeseseryData && !showDashboard && (
        <>
          <ErrorBoundary>
            <Switch>
              <SentryRoute exact path={routes.SETUP_PASS} component={SetUpPass} />
              <SentryRoute exact path={routes.FORGOTTEN_PASSWORD} component={ResetPassword} />
              {loggedIn && <SentryRoute exact path={routes.CONFIRM_MFA} component={MfaAuth} />}
              <SentryRoute exact path={routes.LOGIN} component={Login} />
              <SentryRoute exact path='/'>
                <Redirect to={routes.LOGIN} />
              </SentryRoute>
              <SentryRoute path='*'>
                <Redirect to={routes.LOGIN} />
              </SentryRoute>
            </Switch>
          </ErrorBoundary>
        </>
      )}
      {!show && (
        <>
          <ErrorBoundary>
            <SentryRoute exact path={routes.TICKET_REDIRECT} component={TicketRedirect} />
            <ConfirmationLoader fullScreen />
          </ErrorBoundary>
        </>
      )}
    </Router>
  );
};

export default Sentry.withProfiler(App);
