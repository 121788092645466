export const regions = [
  { name: 'EU', value: 'eu' },
  { name: 'US', value: 'us' }
];

type Region = (typeof regions)[number]['value'];

export const regionsMap = new Map<Region, string>(
  regions.map((region) => [region.value, region.name])
);
