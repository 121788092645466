import { Button } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import React from 'react-dom';
import { DeviceUUID } from 'device-uuid';

import Card from 'components/Card/Card';
import FormCheckbox from 'components/FormInput/FormCheckbox';
import FormInput from 'components/FormInput/FormInput';
import AuthLayout from 'layouts/AuthLayout';
import { ButtonWrapper } from '../SetUpPass/styled';
import { AuthTitle, AuthWrapperBox } from '../styled';
import {
  FooterWrapper,
  ForgottenPasswordLink,
  Description2FA,
  Auth2FAWrapper,
  Line,
  SmallParagraph
} from './styled';
import { useAuthentication } from 'hooks/api/useAuthentication';
import { useHistory, useLocation } from 'react-router-dom';
import { FORGOTTEN_PASSWORD } from 'constants/routes';
import { useEffect, useState } from 'react';
import ConfirmationLoader from 'layouts/ConfirmationLoader';
import { FormPasswordTextBox } from 'components/FormInput/FormPasswordTextBox';
import { MfaMethods } from 'types';
import LoaderButton from 'components/LoaderButton/LoaderButton';
import AutoLogout from 'components/AutoLogout/AutoLogout';
import FormComboBox from '../../../components/FormInput/FormComboBox';
import { setAPIUrl } from '../../../api/utils/apiClient';
import { AVAILABLE_REGIONS_API_URL, Region } from '../../../constants/config';
import { regions, regionsMap } from '../../../constants/regions';

const Login = () => {
  const {
    isLoading,
    login,
    is2FaPage,
    verifyCode,
    reSendCode,
    mfaData,
    isErrorVerify,
    handleLogout,
    userData
  } = useAuthentication();
  const [isLoadingMfaCode, setIsLoadingMfaCode] = useState<boolean>(false);
  const [region, setRegion] = useState<Region>('us');
  const { state: locationState = {} } = useLocation();
  const { mfa = false, method = undefined, code = '' }: any = locationState || {};
  const { push } = useHistory();
  const deviceUUID = new DeviceUUID();
  const onSubmit = (data: any) => {
    login(data);
  };
  const verificationMethod = method ?? mfaData.channel;
  const onSubmit2Fa = async (data: any) => {
    if (data.code) {
      setIsLoadingMfaCode(true);
      verifyCode({
        channel: verificationMethod,
        code: data.code,
        remember_mfa_session: data.rememberDevice,
        machine_key: deviceUUID.get()
      });
    }
  };

  useEffect(() => {
    if (isErrorVerify) {
      setIsLoadingMfaCode(false);
    }
  }, [isErrorVerify]);

  useEffect(() => {
    if (code && code.length === 6) {
      setIsLoadingMfaCode(true);
      verifyCode({
        channel: verificationMethod,
        code,
        remember_mfa_session: false,
        machine_key: deviceUUID.get()
      });
    }
  }, [code]);

  const isMfaPage = is2FaPage || mfa || mfaData.required;

  useEffect(() => {
    setAPIUrl(AVAILABLE_REGIONS_API_URL[region]);
    localStorage.setItem('region', region);
  }, [region]);

  if (isLoadingMfaCode) {
    return <ConfirmationLoader fullScreen />;
  }

  return (
    <AuthLayout isLoginPage={!isMfaPage}>
      <Card>
        {!isMfaPage && (
          <AuthWrapperBox>
            <AuthTitle>Log in</AuthTitle>
            <Form
              onSubmit={onSubmit}
              initialValues={{ email: '', password: '', region: { name: 'US', value: 'us' } }}
              render={(formRenderProps: FormRenderProps) => (
                <FormElement>
                  <fieldset className={'k-form-fieldset'}>
                    <Field
                      id={'emailAddress'}
                      name={'email'}
                      type={'email'}
                      label={'Email address'}
                      component={FormInput}
                    />
                    <Field
                      id={'password'}
                      name={'password'}
                      label={'Password'}
                      component={FormPasswordTextBox}
                    />
                    <Field
                      name={'region'}
                      component={FormComboBox}
                      data={regions}
                      onChange={(e: any) => setRegion(e.value.value)}
                      textField='name'
                      label={'Region'}
                    />
                    <FooterWrapper>
                      <Field
                        id={'rememberMe'}
                        name={'rememberMe'}
                        label={'Remember me'}
                        component={FormCheckbox}
                      />
                      <ForgottenPasswordLink onClick={() => push(FORGOTTEN_PASSWORD)}>
                        Forgotten password
                      </ForgottenPasswordLink>
                    </FooterWrapper>
                    <div className='k-form-buttons'>
                      <ButtonWrapper>
                        <Button
                          themeColor={'primary'}
                          onClick={formRenderProps.onSubmit}
                          disabled={!formRenderProps.allowSubmit || isLoading}>
                          Log in
                          {isLoading && <LoaderButton />}
                        </Button>
                      </ButtonWrapper>
                    </div>
                  </fieldset>
                </FormElement>
              )}
            />
          </AuthWrapperBox>
        )}
        {isMfaPage && (
          <AutoLogout forceRunTimer user={userData}>
            <Auth2FAWrapper>
              <Description2FA>
                {`We have sent you ${
                  verificationMethod === MfaMethods.email ? 'an email' : 'SMS'
                } with
              verification code. Please, check your inbox and type code below to verify your first
              log in.`}
              </Description2FA>
              <Form
                onSubmit={onSubmit2Fa}
                initialValues={{ code }}
                key={code}
                render={(formRenderProps: FormRenderProps) => (
                  <FormElement>
                    <fieldset className={'k-form-fieldset'}>
                      <Field
                        data-testid='code'
                        id={'code'}
                        name={'code'}
                        type={'number'}
                        label={'Authentication Code'}
                        autoFocus
                        component={FormInput}
                      />
                      <FooterWrapper style={{ marginTop: '5px' }}>
                        <Field
                          data-testid='remember-device'
                          id={'rememberDevice'}
                          name={'rememberDevice'}
                          label={'Turn off MFA for this device for 30 days'}
                          component={FormCheckbox}
                        />
                      </FooterWrapper>
                      <div className='k-form-buttons'>
                        <ButtonWrapper>
                          <Button
                            data-testid='resend-code'
                            type={'button'}
                            onClick={() => reSendCode(mfaData.channel)}
                            disabled={isLoading}>
                            Resend code
                            {isLoading && <LoaderButton />}
                          </Button>
                          <Button
                            data-testid='submit-code'
                            themeColor={'primary'}
                            type={'submit'}
                            disabled={!formRenderProps.allowSubmit || isLoading}>
                            Verify
                            {isLoading && <LoaderButton />}
                          </Button>
                        </ButtonWrapper>
                      </div>
                    </fieldset>
                  </FormElement>
                )}
              />
              <Line />
              <SmallParagraph>If you want to use another account, please log out</SmallParagraph>
              <div className='k-form-buttons'>
                <ButtonWrapper>
                  <Button data-testid='logout' disabled={isLoading} onClick={handleLogout}>
                    Logout
                    {isLoading && <LoaderButton />}
                  </Button>
                </ButtonWrapper>
              </div>
            </Auth2FAWrapper>
          </AutoLogout>
        )}
      </Card>
    </AuthLayout>
  );
};

export default Login;
