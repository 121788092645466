import styled from 'styled-components';
import Card from '../../components/Card/Card';
import { HeaderBig } from '../../components/Typography/HeaderBig';
import { Button } from '@progress/kendo-react-buttons';

const rowMargin = '30px';
const columnMargin = '50px';

export const StyledCard = styled(Card)`
  padding: 30px;
`;

export const InnerWrapper = styled.div`
  margin: 0 auto;
  @media ${({ theme }) => theme.devices.xlarge} {
    width: 100%;
    max-width: 1640px;
  }
`;

export const UpperGrid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media ${({ theme }) => theme.devices.large} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const LowerGrid = styled.div`
  display: grid;
  gap: ${rowMargin} ${columnMargin};
  margin-top: ${rowMargin};
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
`;

export const DeviceDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;

  img {
    width: 300px;
    height: 300px;
  }

  @media ${({ theme }) => theme.devices.large} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const DeviceName = styled.h3`
  ${HeaderBig};
  margin-bottom: ${rowMargin};
  @media ${({ theme }) => theme.devices.large} {
    margin: 0;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 24px;
  grid-area: buttons;
  margin-bottom: ${rowMargin};
  @media ${({ theme }) => theme.devices.large} {
    justify-content: flex-end;
    align-items: flex-start;
  }
  @media ${({ theme }) => theme.devices.xlarge} {
    margin: 0;
  }
`;

export const Subtitle = styled.p`
  font-size: ${({ theme }) => theme.fontSize.title};
  color: ${({ theme }) => theme.palette.subtleText};
  margin-top: ${rowMargin};
`;

export const RemoveButton = styled(Button)`
  color: ${({ theme }) => theme.palette.error2} !important;

  &:hover {
    background-color: ${({ theme }) => theme.palette.error2} !important;
    color: white !important;
    border: 1px solid ${({ theme }) => theme.palette.error2} !important;
  }
`;

export const CompanyDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TabsWrapper = styled.div`
  margin-top: ${columnMargin};
`;
