import React from 'react';
import TherapyLogo from '../../assets/aether-logo.png';
import { FooterWrapper, LogoWrapper, MainWrapper, Spacer, Udi, Wrapper } from './styled';
import dayjs from 'dayjs';
import Barcode from 'react-barcode';
import { myTheme } from 'theme/theme';
import './style.css';

const AuthLayout = ({
  children,
  isLoginPage = false
}: {
  children: React.ReactNode;
  isLoginPage?: boolean;
}) => (
  <Wrapper>
    <LogoWrapper>
      <img src={TherapyLogo} alt={'Main logo'} />
    </LogoWrapper>
    <MainWrapper>{children}</MainWrapper>
    <FooterWrapper>{dayjs().format('YYYY')} © Aether Digital Platform</FooterWrapper>
    {isLoginPage && (
      <div>
        <Spacer style={{ margin: '24px 0 24px 0' }} />
        <Udi>UDI</Udi>
        <Barcode
          value='(01)05904569152253'
          width={1.5}
          height={50}
          font={'Roboto'}
          fontSize={12}
          background={myTheme.palette.background}
        />
      </div>
    )}
  </Wrapper>
);

export default AuthLayout;
