import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import * as Sentry from '@sentry/react';
import { DeviceUUID } from 'device-uuid';
import { API_URL, AVAILABLE_REGIONS_API_URL, Region } from 'constants/config';

axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    Sentry.captureException(error);
    return Promise.reject(error);
  }
);
const region = (localStorage.getItem('region') as Region) ?? 'us';
const instance = axios.create({
  baseURL: AVAILABLE_REGIONS_API_URL[region],
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-MFA-Machine-Key': new DeviceUUID().get()
  }
});

instance?.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const mfaSessionToken = localStorage.getItem(localStorage.getItem('hash') || '') || undefined;
  if (mfaSessionToken) {
    // @ts-ignore
    config.headers['X-MFA-Session-Token'] = mfaSessionToken;
  }

  return config;
});

const dumInstance = axios.create({
  baseURL: process.env.REACT_APP_API_DUM_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
});

export const withCredentials = {
  withCredentials: true
};

export const withCredentialsAndMultipartForm = {
  ...withCredentials,
  headers: {
    'content-type': 'multipart/form-data'
  }
};

export function setAPIUrl(path: string) {
  instance.defaults.baseURL = path;
}

export function setApiAuthToken(token: string | null) {
  if (token) {
    // @ts-ignore
    dumInstance.defaults.headers['Authorization'] = `Bearer ${token}`;
  } else {
    // @ts-ignore
    delete instance.defaults.headers['Authorization'];
    // @ts-ignore
    delete dumInstance.defaults.headers['Authorization'];
  }
}

export const apiURL = (path: string) => {
  return new URL(path, API_URL).toString();
};
export { dumInstance as ApiDUMClient };
export default instance;
