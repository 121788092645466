import { Grid } from '@progress/kendo-react-grid';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { getTableSize } from '../../utils/tableHelpers';
import { ActionButtonsWrapper } from '../../components/Table/styles';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { ActionButton } from '../../components/ActionButton/ActionButton';
import { SvgIcon } from '@progress/kendo-react-common';
import * as svgIcons from '@progress/kendo-svg-icons';
import { DeviceEntry } from '../../api/devices/device.types';
import { CONFIGURATOR_URL } from '../../constants/config';

export interface RepairGridProps {
  device: DeviceEntry;
}

const RepairGrid = ({ device }: RepairGridProps) => {
  const handlePcbProcedure = () => {
    window.open(
      `${CONFIGURATOR_URL}/device?serial=${device.serial}&bluetoothId=${device.bluetooth_id}&deviceId=${device.id}&repair=1`,
      '_blank'
    );
  };

  const repairProcedures = [{ name: 'PCB replacement', action: handlePcbProcedure }];

  return (
    <Grid data={repairProcedures}>
      <Column field='name' title='Procedure name' />
      <Column
        width={getTableSize(3)}
        title='Actions'
        cell={(e: any) => (
          <ActionButtonsWrapper>
            <Tooltip anchorElement='target' parentTitle={true} position={'top'}>
              <ActionButton title='Start repair' onClick={e.dataItem.action}>
                <SvgIcon icon={svgIcons['wrenchIcon']} size='medium' />
              </ActionButton>
            </Tooltip>
          </ActionButtonsWrapper>
        )}
      />
    </Grid>
  );
};
export default RepairGrid;
