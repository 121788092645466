import {
  GoalEntryPayload,
  GoalConditionsEntryPayload,
  GoalConditionType,
  GoalFrequencyType,
  GoalProgressEntryPayload
} from 'api/goals/goals.types';
import dayjs from 'dayjs';
import { gripsGroupsOptionsMap, timePeriodsMap, Grips } from 'utils/definesLocal';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import weekOfYear from 'dayjs/plugin/weekOfYear';

dayjs.extend(advancedFormat);
dayjs.extend(weekOfYear);

const convertWeekToRange = (weekString: string) => {
  const [year, week] = weekString.split('-').map(Number);

  const startDate = dayjs().year(year).week(week).startOf('week');
  const endDate = startDate.endOf('week');

  const startDateFormat = startDate.format('YYYY-MM-DD');
  const endDateFormat = endDate.format('YYYY-MM-DD');

  return `${startDateFormat} - ${endDateFormat}`;
};

export const filterGrips = (values: any) => {
  const gripsGoals: any = [];

  if (values.performedPerDay) {
    gripsGoals.push({
      id: null,
      performedPerDay: values['performedPerDay']
    });
  }

  for (const key in values) {
    if (key.startsWith('grip-')) {
      const gripIndex = key.split('-')[1];
      const gripValue = values[key];
      const performedPerDayValue = values[`performedPerDay-${gripIndex}`];
      if (!performedPerDayValue) continue;

      gripsGoals.push({
        ...gripValue,
        performedPerDay: performedPerDayValue
      });
    }
  }
  return gripsGoals;
};

export const filterExercises = (values: any) => {
  const exercisesGoals: any = [];
  for (const key in values) {
    if (key.startsWith('exercise-status-')) {
      const exerciseIndex = key.split('-')[2];
      const exerciseStatus = values[`exercise-status-${exerciseIndex}`];
      const exerciseValue = values[`exercise-${exerciseIndex}`];
      const performedPerDayValue = values[`exerciseTimesPerformed-${exerciseIndex}`];

      if (!performedPerDayValue || !exerciseStatus) continue;

      exercisesGoals.push({
        frequency: exerciseValue['id'],
        id: exerciseIndex,
        performedPerDay: performedPerDayValue
      });
    }
  }
  return exercisesGoals;
};

const parseAttempts = (attempts: any, frequency: GoalFrequencyType) =>
  attempts.map((attempt: any) => {
    if (frequency === GoalFrequencyType.d)
      return {
        y: attempt.count_done,
        x: attempt.date_from,
        unsuccessful: attempt.count_not_done
      };
    if (frequency === GoalFrequencyType.w)
      return {
        y: attempt.count_done,
        x: `${attempt.date_from} - ${attempt.date_to}`,
        unsuccessful: attempt.count_not_done
      };
    if (frequency === GoalFrequencyType.m)
      return {
        y: attempt.count_done,
        x: `${dayjs(attempt.date_from).format('YYYY-MM')}`,
        unsuccessful: attempt.count_not_done
      };
  });

export const transformExercises = (
  goalProgressData: GoalProgressEntryPayload,
  goal: GoalEntryPayload
): TransformedExercise[] | undefined => {
  if (!goalProgressData.exercises) return;

  const exercises2 = goal.conditions!.filter(
    (condition: any) => condition.type === GoalConditionType.exercise
  );

  const parsedExercises = goalProgressData.exercises.conditions.map((instance) => {
    const exerciseConditions = exercises2.find(
      (exercise: any) => exercise?.exercise_id === instance.exercise.id
    )!;
    return {
      ...exerciseConditions,
      ...instance.exercise,
      attempts: parseAttempts(instance.attempts, exerciseConditions?.exercise_frequency)
    };
  });

  return parsedExercises;
};

export const groupGoalsValues = (
  gripsPerformedData: any,
  frequency: GoalFrequencyType,
  conditionType: GoalConditionType,
  parameters: {
    goalStartDate: Date;
    goalEndDate: Date;
  },
  gripsInGoal?: string[]
) => {
  const allGripsLabel = 'All grips';
  const switchesLabel = 'Switches';

  let gripsGrouped =
    gripsPerformedData && gripsPerformedData?.length > 0
      ? gripsPerformedData.reduce((acc: any, val: any) => {
          let foundGroup = acc.find((group: any) => group.label_name === val.group_by);

          if (!foundGroup) {
            foundGroup = {
              label_name: val.group_by,
              [`${allGripsLabel}`]: 0,
              [`${switchesLabel}`]: 0
            };
            acc.push(foundGroup);
          }

          const gripName = gripsGroupsOptionsMap.get(Number(val.grip));

          if (gripName) {
            foundGroup[gripName] = Number(val.instances);
          }

          foundGroup[`${switchesLabel}`] += Number(val.instances);
          foundGroup[`${allGripsLabel}`] += Number(val.instances);

          return acc;
        }, [])
      : null;

  if (frequency === GoalFrequencyType.w && gripsGrouped)
    return gripsGrouped.map((group: any) => ({
      ...group,
      label_name: convertWeekToRange(group.label_name)
    }));

  if (frequency !== GoalFrequencyType.a) return gripsGrouped;

  if (gripsGrouped) {
    const timeFrameGrips = gripsGrouped.reduce((acc: any, val: any) => {
      if (acc[`${allGripsLabel}`] === undefined) {
        acc[`${allGripsLabel}`] = 0;
        acc[`${switchesLabel}`] = 0;
      }

      if (conditionType === GoalConditionType.switch) {
        acc[`${switchesLabel}`] += Number(val[`${switchesLabel}`]);
        return acc;
      }

      if (gripsInGoal && conditionType === GoalConditionType.grip) {
        gripsInGoal.forEach((element) => {
          if (acc[`${element}`] === undefined) {
            acc[`${element}`] = 0;
          }
          const gripValue = val[`${element}`];
          if (!gripValue) return;
          acc[`${element}`] += Number(gripValue);
        });
      } else {
        acc[`${allGripsLabel}`] += Number(val[`${allGripsLabel}`]);
      }

      return acc;
    }, {});

    gripsGrouped = [
      {
        ...timeFrameGrips,
        label_name: `${dayjs(parameters.goalStartDate).format('YYYY-MM-DD')} - ${dayjs(
          parameters.goalEndDate
        ).format('YYYY-MM-DD')}`
      }
    ];
  }

  return gripsGrouped;
};

export const getGripsInGoal = (goal: GoalEntryPayload) => {
  if (!goal.conditions) return null;

  const gripsConditions = goal.conditions.filter(
    (condition) => condition.type === GoalConditionType.grip
  );

  const gripsInvolved: { grip_number: number | null; grips_count: number }[] = gripsConditions.map(
    (condition) => ({
      grip_number: condition.grip_id ? condition.grip_id! - 1 : null,
      grips_count: condition.grips_count,
      name: condition.grip_id ? gripsGroupsOptionsMap.get(condition.grip_id! - 1) : 'All grips'
    })
  );

  const gripsInGoal = {
    gripsInvolved: gripsInvolved,
    gripsNames: gripsInvolved.map((gripInvolved) => {
      if (gripInvolved.grip_number === null) return 'All grips';
      return gripsGroupsOptionsMap.get(gripInvolved.grip_number as Grips);
    }),
    gripsFrequency: gripsConditions[0].grips_frequency
  };

  return gripsInGoal;
};

export const getSwitchesInGoal = (goal: GoalEntryPayload) => {
  if (!goal.conditions) return null;

  const switchesGoal = goal.conditions.find(
    (condition) => condition.type === GoalConditionType.switch
  );

  return {
    switchesCount: switchesGoal!.switches_count,
    switchesFrequency: switchesGoal!.switches_frequency
  };
};

export const findGripsCount = (object: any) => {
  let count = 0;
  for (const key in object) {
    if (key.startsWith('grip-')) {
      count++;
    }
  }
  return count;
};

export const formatGoal = (conditions: GoalConditionsEntryPayload[]) => {
  const valuesObject = conditions.reduce((acc: any, cur) => {
    switch (cur.type) {
      case GoalConditionType.switch:
        acc.switches = cur.switches_count;
        acc['switches-frequency'] = cur.switches_frequency;
        break;
      case GoalConditionType.exercise:
        acc[`exercise-${cur.exercise_id}`] = {
          name: timePeriodsMap.get(cur.exercise_frequency),
          id: cur.exercise_frequency
        };
        acc[`exercise-status-${cur.exercise_id}`] = true;
        acc[`exerciseTimesPerformed-${cur.exercise_id}`] = cur.exercise_count;
        break;
      case GoalConditionType.grip:
        if (!cur.grip_id) {
          acc.allGrips = true;
          acc.performedPerDay = cur.grips_count;
        } else {
          const gripsCount = findGripsCount(acc);
          acc[`grip-${gripsCount}`] = {
            id: cur.grip_id - 1,
            name: gripsGroupsOptionsMap.get(cur.grip_id - 1)
          };
          acc[`performedPerDay-${gripsCount}`] = cur.grips_count;
        }
        acc['grips-frequency'] = cur.grips_frequency;
        break;
      default:
        break;
    }
    return acc;
  }, {});
  const gripsCount = findGripsCount(valuesObject);
  return { ...valuesObject, gripsCount };
};

export const frequencyPeriods = [
  { label: 'Per day', value: GoalFrequencyType.d },
  { label: 'Per week', value: GoalFrequencyType.w },
  { label: 'Per month', value: GoalFrequencyType.m },
  { label: 'Time frame', value: GoalFrequencyType.a }
];

export interface TransformedExercise {
  attempts: { x: string; y: number }[];
  created_at: string;
  updated_at: string;
  description: string;
  exercise_count: number | undefined;
  exercise_id: number;
  exercise_frequency: GoalFrequencyType;
  id: number;
  goal_id: number;
  type: GoalConditionType;
  name: string;
  icon: string;
  grip_id: Grips | null;
  grips_frequency: GoalFrequencyType;
  grips_count: number;
  switches_frequency: GoalFrequencyType;
  switches_count: number;
}

export const colors = [
  '#ffd366',
  '#acd58a',
  '#bf903c',
  '#d9d4bf',
  '#6ac4c6',
  '#ac514c',
  '#4960a6',
  '#929b24',
  '#c1c9d9',
  '#025159',
  '#c0988f',
  '#69436d',
  '#EB7D3F',
  '#DC3534',
  '#F36161',
  '#F4610E',
  '#F5C64E',
  '#FF64EF',
  '#FFC107',
  '#FF8D07',
  '#999999'
];

export enum GoalsStatus {
  inProgress = 'inProgress',
  finished = 'finished',
  inactive = 'inactive',
  awaiting = 'awaiting'
}

export const goalsStatusMap = new Map<GoalsStatus, string>([
  [GoalsStatus.inProgress, 'In progress'],
  [GoalsStatus.finished, 'Finished'],
  [GoalsStatus.inactive, 'Inactive'],
  [GoalsStatus.awaiting, 'Awaiting']
]);
