import styled from 'styled-components';

export const AuthTitle = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.title};
  margin-bottom: ${({ theme }) => theme.dimensions.spacing * 2}px;
  color: ${({ theme }) => theme.palette.headerTitle};
`;

export const AuthWrapperBox = styled.div`
  width: 350px;
  padding: 30px;
`;
