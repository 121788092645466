import DefaultLayout from '../../layouts/DefaultLayout';
import { Form, Field, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import styled from 'styled-components';
import Card from '../../components/Card/Card';
import { requiredValidator } from '../../components/FormInput/validators';
import { useHistory, useLocation } from 'react-router-dom';
import { UserExtendOptions } from '../../api/users/users.types';
import { HeaderComponent } from '../../components/Typography/Header';
import FormComboBox from '../../components/FormInput/FormComboBox';
import { useDeviceCreate, useDeviceUpdate } from '../../hooks/api/useDevices';
import { RoleEnum, UsersQueryParams } from '../../api/users/users.types';
import { useEffect, useState } from 'react';
import { DEVICE } from '../../constants/routes';
import { useUsersInfinite } from '../../hooks/api/useUsers';
import FormInput from '../../components/FormInput/FormInput';
import { useCompaniesInfinite } from '../../hooks/api/useCompanies';
import { mapUsersRoles, userHasPermissions } from '../../utils/permissionUtils';
import useUserData from '../../hooks/useUserData';
import { useVersions } from '../../hooks/api/useVersions';
import * as Sentry from '@sentry/react';
import LoaderButton from '../../components/LoaderButton/LoaderButton';
import { Loader } from '@progress/kendo-react-indicators';
import ConfirmationLoader from 'layouts/ConfirmationLoader';

const StyledCard = styled(Card)`
  padding: 30px;
`;

const InnerWrapper = styled.div`
  margin: 0 auto;
  max-width: 450px;
`;

const Fieldset = styled.fieldset`
  max-width: 450px;
  width: 100%;
`;

const FieldsWrapper = styled.div`
  display: grid;
  justify-items: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

let initialLoaded = false;

const AddDevice = () => {
  const [queryParamsClinician, setQueryParamsClinician] = useState<UsersQueryParams>({
    roles: [RoleEnum.clinician, RoleEnum.clinicAdmin],
    extend: [UserExtendOptions.roles],
    perpage: 1000
  });
  const [queryParamsPatient, setQueryParamsPatient] = useState<UsersQueryParams>({
    roles: RoleEnum.amputee,
    perpage: 1000
  });
  const { rolesByName } = useUserData();
  let { state: { detail: existingDeviceData = false } = {} }: any = useLocation();
  const {
    PCBVersion,
    FirmwareVersion,
    DeviceModel,
    isLoading: isLoadingVersions
  } = useVersions(userHasPermissions([RoleEnum.superAdmin], rolesByName));
  const { result: companies, isLoading: isLoadingCompanies } = useCompaniesInfinite(
    { perpage: 100 },
    userHasPermissions([RoleEnum.superAdmin], rolesByName)
  );
  const { mutateAsync: createDevice, isLoading: isLoadingCreate } = useDeviceCreate();
  const { mutateAsync: updateDevice, isLoading: isLoadingUpdate } = useDeviceUpdate();
  const { result: patients, isLoading: isLoadingPatients } = useUsersInfinite(queryParamsPatient);
  const { result: clinicians, isLoading: isLoadingClinicians } =
    useUsersInfinite(queryParamsClinician);
  const isEdit = Boolean(existingDeviceData);

  const cliniciansWithRoles = clinicians && mapUsersRoles(clinicians);

  const { push, goBack } = useHistory();

  useEffect(() => {
    if (existingDeviceData?.company) {
      setQueryParamsClinician((old) => {
        return { ...old, company: existingDeviceData.company.id };
      });
      setQueryParamsPatient((old) => {
        return { ...old, company: existingDeviceData.company.id };
      });
    }

    return () => {
      setQueryParamsClinician({});
    };
  }, []);

  const isLoading = isLoadingCreate || isLoadingUpdate;

  const handleSubmit = async (e: any) => {
    const {
      model = undefined,
      pcb_version: pcb = undefined,
      firmware_version: firmware = undefined,
      amputee = undefined,
      clinician = undefined,
      serial = undefined,
      bluetooth_id = undefined,
      company = undefined
    } = e;

    const deviceData = {
      ...(model && { model_id: model.id }),
      ...(pcb && { pcb_version_id: pcb.id }),
      ...(firmware && { firmware_version_id: firmware.id }),
      ...(bluetooth_id &&
        existingDeviceData?.bluetooth_id !== bluetooth_id && { bluetooth_id: bluetooth_id }),
      ...(serial && existingDeviceData?.serial !== serial && { serial }),
      ...(amputee ? { amputee_id: amputee.id } : { amputee_id: null }),
      ...(clinician ? { clinician_id: clinician.id } : { clinician_id: null }),
      ...(company ? { company_id: company.id } : { company_id: null })
    };

    try {
      if (isEdit) {
        await updateDevice({ data: deviceData, deviceId: existingDeviceData.id });
      } else {
        await createDevice(deviceData);
      }
      push(DEVICE);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const onCompanyChange = (e: any) => {
    setQueryParamsClinician((old) => {
      return { ...old, company: e.value?.id };
    });
    setQueryParamsPatient((old) => {
      return { ...old, company: e.value?.id };
    });
  };

  if (isEdit) {
    if ((!clinicians || !patients) && !initialLoaded) {
      return (
        <DefaultLayout>
          <ConfirmationLoader />
        </DefaultLayout>
      );
    }

    if (!initialLoaded) {
      existingDeviceData = {
        ...existingDeviceData,
        clinician: cliniciansWithRoles.find(
          (clinician: any) => clinician.id === existingDeviceData?.clinician_id
        )
      };
      initialLoaded = true;
    }
  }

  return (
    <DefaultLayout>
      <InnerWrapper>
        <HeaderComponent headerText={isEdit ? 'Edit device' : 'Add device'} />
        <StyledCard>
          {isEdit && !existingDeviceData ? (
            <Loader />
          ) : (
            <Form
              initialValues={existingDeviceData!}
              onSubmit={handleSubmit}
              render={(formRenderProps: FormRenderProps) => {
                return (
                  <FormElement>
                    <FieldsWrapper>
                      <Fieldset
                        className={'k-form-fieldset'}
                        style={{ margin: 0, justifySelf: 'start' }}>
                        <legend>Basic information</legend>
                        {userHasPermissions([RoleEnum.superAdmin], rolesByName) && (
                          <Field
                            data-testid='model'
                            id={'model'}
                            name={'model'}
                            label={'Model'}
                            component={FormComboBox}
                            filterable={true}
                            textField='name'
                            key={`model_${DeviceModel?.length}`}
                            data={DeviceModel}
                            validator={requiredValidator}
                            disabled={isLoadingVersions}
                            loading={isLoadingVersions}
                          />
                        )}
                        {userHasPermissions([RoleEnum.superAdmin], rolesByName) && (
                          <Field
                            data-testid='pcb'
                            id={'pcb'}
                            name={'pcb_version'}
                            label={'PCB'}
                            component={FormComboBox}
                            filterable={true}
                            textField='name'
                            key={`pcb${PCBVersion?.length}`}
                            data={PCBVersion}
                            validator={requiredValidator}
                            disabled={isLoadingVersions}
                            loading={isLoadingVersions}
                          />
                        )}
                        {userHasPermissions([RoleEnum.superAdmin], rolesByName) && (
                          <Field
                            data-testid='firmware'
                            id={'firmware'}
                            name={'firmware_version'}
                            label={'Firmware'}
                            component={FormComboBox}
                            filterable={true}
                            textField='name'
                            key={`firmware${FirmwareVersion?.length}`}
                            data={FirmwareVersion}
                            validator={requiredValidator}
                            disabled={isLoadingVersions}
                            loading={isLoadingVersions}
                          />
                        )}
                        {userHasPermissions([RoleEnum.superAdmin], rolesByName) && (
                          <Field
                            data-testid='serial'
                            id={'serial'}
                            name={'serial'}
                            label={'Serial number'}
                            component={FormInput}
                            validator={requiredValidator}
                          />
                        )}
                        {userHasPermissions([RoleEnum.superAdmin], rolesByName) && (
                          <Field
                            data-testid='bluetooth_id'
                            id={'bluetooth_id'}
                            name={'bluetooth_id'}
                            label={'Bluetooth ID'}
                            component={FormInput}
                            validator={requiredValidator}
                          />
                        )}
                        {userHasPermissions([RoleEnum.superAdmin], rolesByName) && (
                          <Field
                            data-testid='company'
                            id={'company'}
                            name={'company'}
                            label={'Company'}
                            component={FormComboBox}
                            filterable={true}
                            onChange={(e) => {
                              onCompanyChange(e);
                              formRenderProps.onChange('clinician', { value: null });
                              formRenderProps.onChange('amputee', { value: null });
                            }}
                            onFilterChange={() => {
                              formRenderProps.onChange('clinician', { value: null });
                              formRenderProps.onChange('amputee', { value: null });
                            }}
                            textField='name'
                            data={companies}
                            key={`companies_${JSON.stringify(
                              companies?.map((element: any) => element.id)
                            )}`}
                            optional
                            disabled={isLoadingCompanies}
                            isLoading={isLoadingCompanies}
                          />
                        )}
                        <Field
                          data-testid='clinician'
                          id={'clinician'}
                          name={'clinician'}
                          label={'Clinician'}
                          component={FormComboBox}
                          filterable={true}
                          textField='name'
                          data={cliniciansWithRoles}
                          dataItemKey='id'
                          key={`clinicians_${JSON.stringify(
                            clinicians?.map((element: any) => element.id)
                          )}`}
                          onFilterChange={() => {
                            formRenderProps.onChange('amputee', { value: null });
                          }}
                          disabled={!formRenderProps.valueGetter('company') || isLoadingClinicians}
                          optional
                          loading={isLoadingClinicians}
                        />
                        <Field
                          data-testid='amputee'
                          id={'amputee'}
                          name={'amputee'}
                          label={'Patient'}
                          component={FormComboBox}
                          filterable={true}
                          textField='name'
                          data={patients}
                          key={`patients_${JSON.stringify(
                            patients?.map((element: any) => element.id)
                          )}`}
                          disabled={
                            !formRenderProps.valueGetter('company') ||
                            !formRenderProps.valueGetter('clinician') ||
                            isLoadingPatients
                          }
                          optional
                          loading={isLoadingPatients}
                        />
                      </Fieldset>
                    </FieldsWrapper>
                    <ButtonsWrapper className='k-form-buttons'>
                      <Button data-testid='cancel-device-form' onClick={goBack} type='button'>
                        Cancel
                      </Button>
                      <Button
                        data-testid='submit-device-form'
                        themeColor={'primary'}
                        type='submit'
                        disabled={!formRenderProps.allowSubmit || isLoading}>
                        {isEdit ? 'Edit device' : 'Create device'}
                        {isLoading && <LoaderButton />}
                      </Button>
                    </ButtonsWrapper>
                  </FormElement>
                );
              }}
            />
          )}
        </StyledCard>
      </InnerWrapper>
    </DefaultLayout>
  );
};

export default AddDevice;
