import Notification, { NotificationVariant } from '../components/Notifications/Notification';
import { Toast, toast, ToastOptions } from 'react-hot-toast';
import React from 'react';
import parseApiError from './parseApiError';
import ApiErrorNotification from '../components/Notifications/ApiErrorNotification';

function renderNotification(variant: NotificationVariant) {
  return (title: string, description: string, options: ToastOptions, children: React.ReactNode) => {
    return toast.custom(
      (t: Toast) => (
        <Notification toast={t} title={title} description={description} variant={variant}>
          {children}
        </Notification>
      ),
      options
    );
  };
}

export function errorNotification(
  title = '',
  description = '',
  options: ToastOptions = {},
  children: React.ReactNode = null
) {
  return renderNotification('error')(title, description, options, children);
}

export function errorApiNotification(error: any, fallbackErrorMessage: string | null = null) {
  if (!error.isAxiosError && fallbackErrorMessage) {
    errorNotification(fallbackErrorMessage);
  }

  const parsedError = parseApiError(error);
  if (parsedError.accumulatedErrors) {
    return errorNotification(
      parsedError.message,
      '',
      { id: parsedError.message },
      ApiErrorNotification({ errors: parsedError.accumulatedErrors })
    );
  } else {
    return errorNotification(parsedError.message, '', { id: parsedError.message });
  }
}

export function successNotification(
  title = '',
  description = '',
  options: ToastOptions = {},
  children: React.ReactNode = null
) {
  return renderNotification('success')(title, description, options, children);
}

export function warningNotification(
  title = '',
  description = '',
  options: ToastOptions = {},
  children: React.ReactNode = null
) {
  return renderNotification('warning')(title, description, options, children);
}
