import dayjs from 'dayjs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import styled, { css } from 'styled-components';
import { SvgIcon } from '@progress/kendo-react-common';
import * as svgIcons from '@progress/kendo-svg-icons';
import {
  SupportTicketAttachmentEntry,
  SupportTicketEntry,
  SupportTicketMessageEntry,
  SupportTicketStatusEnum
} from '../../../api/tickets/tickets.types';
import { getTableSize } from '../../../utils/tableHelpers';
import { Badge } from '../../../components/Badge/Badge';
import { myTheme } from '../../../theme/theme';
import { DetailsLinkStyle } from '../../../components/Links/Links';
import { ActionButtonsWrapper } from '../../../components/Table/styles';
import {
  ActionButtonError,
  ActionButtonPrimary
} from '../../../components/ActionButton/ActionButton';
import { useState, useRef } from 'react';
import { CONFIGURATOR_URL } from '../../../constants/config';
import { TableDataLoader } from '../../../components/TableDataLoader/TableDataLoader';
import { SUPPORT_TICKETS_QUERY_KEY } from '../../../hooks/api/useTickets';
import useUserData from '../../../hooks/useUserData';
import { Tooltip } from '@progress/kendo-react-tooltip';

export interface TicketGridProps {
  queryFunction: any;
  queryParams: any;
  handleCloseTicket: Function;
  handleShowTicket: Function;
  handleReopenTicket: Function;
}

const MessageWrapper = styled.td`
  display: flex;
  align-items: center;
`;

const DetailsLinkMessage = styled.div`
  ${DetailsLinkStyle};
`;

interface CustomBadgeProps {
  status: 'open' | 'closed' | 'new' | 'reopened';
}

const badgesColors = {
  open: myTheme.palette.link2,
  closed: myTheme.palette.error2,
  reopened: myTheme.palette.orange,
  _new: myTheme.palette.success2
};

const CustomBadge = styled.div<CustomBadgeProps>`
  ${Badge};

  ${({ status }) =>
    status === SupportTicketStatusEnum.closed &&
    css`
      background-color: ${badgesColors.closed};
    `};

  ${({ status }) =>
    status === SupportTicketStatusEnum.new &&
    css`
      background-color: ${badgesColors._new};
    `};

  ${({ status }) =>
    status === SupportTicketStatusEnum.reopened &&
    css`
      background-color: ${badgesColors.reopened};
    `};
`;

const checkIsRecordProblemTicket = (ticket: SupportTicketEntry) => {
  if (ticket?.messages?.length === 0) return false;
  return !!Array.from(ticket.messages).find((message: SupportTicketMessageEntry) => {
    if (message.attachments === undefined) {
      return;
    }
    if (message.attachments.length === 0) {
      return;
    }

    return Array.from(message.attachments).find(
      (attachment: SupportTicketAttachmentEntry) => attachment.type === 'application/json'
    );
  });
};

const transformTicketData = (supportTicketEntries: SupportTicketEntry[]) => {
  const formatDate = (date: string) => dayjs(date).tz(dayjs.tz.guess()).format('MM/DD/YYYY, HH:mm');
  const formatDateDate = (date: string) => dayjs(date).tz(dayjs.tz.guess()).format('MM/DD/YYYY');
  const formatDateHour = (date: string) => dayjs(date).tz(dayjs.tz.guess()).format('HH:mm');

  const formatTitle = (title: string) =>
    title?.length > 250 ? title.substring(0, 250) + '...' : title;

  const formatMessage = (message: SupportTicketMessageEntry) => ({
    ...message,
    created_at: formatDate(message.created_at),
    created_at_date: formatDateDate(message.created_at),
    created_at_hour: formatDateHour(message.created_at)
  });

  const transformTicket = (ticket: SupportTicketEntry) => {
    const titleFromMessageContent = ticket?.messages[0]?.content
      ? formatTitle(ticket.messages[0].content)
      : '';
    const ticketTitle =
      ticket?.messages[0]?.title && ticket.messages[0].title.length > 0
        ? formatTitle(ticket.messages[0].title)
        : titleFromMessageContent;

    const lastMessageDate =
      ticket.messages.length > 0
        ? formatDate(ticket.messages[ticket.messages.length - 1].created_at)
        : null;

    return {
      ...ticket,
      date: formatDate(ticket.created_at),
      lastMessageDate: lastMessageDate,
      isRecordReplay: checkIsRecordProblemTicket(ticket),
      title: ticketTitle,
      messages: ticket?.messages?.map(formatMessage) ?? []
    };
  };

  const transformFunction = (data: SupportTicketEntry[]) => data?.map(transformTicket) ?? [];

  return transformFunction(supportTicketEntries);
};

const sortMap = {
  lastMessageDate: 'last_message',
  ['sender.name']: 'sender_name',
  ['recipient.name']: 'recipient_name'
};

const TicketsGrid = ({
  queryFunction,
  queryParams,
  handleShowTicket,
  handleCloseTicket,
  handleReopenTicket
}: TicketGridProps) => {
  const { me } = useUserData();
  const [tickets, setTickets] = useState<any>({
    data: [],
    total: 10
  });
  const [dataState, setDataState] = useState<any>({
    take: 12,
    skip: 0,
    sort: [{ field: 'lastMessageDate', dir: 'desc' }]
  });
  const dataReceived = (messages: any) => {
    setTickets({ data: transformTicketData(messages.data), total: messages.total });
  };

  const dataStateChange = (e: any) => {
    setDataState(e.dataState);
  };
  const tableRef = useRef(null) as any;

  const ticketStatusMap = new Map<string, string>([
    [SupportTicketStatusEnum.new, 'New'],
    [SupportTicketStatusEnum.inProgress, 'In progress'],
    [SupportTicketStatusEnum.closed, 'Closed'],
    [SupportTicketStatusEnum.reopened, 'Re-opened']
  ]);

  const handleRedirect = (id: any) => {
    window.open(`${CONFIGURATOR_URL}/support-ticket?ticketId=${id}`, '_blank');
  };

  const parseTicketTitle = (ticket: any) => {
    const isUnread = ticket?.messages.some(
      (message: any) => !message.is_read && message.sender_id !== me?.id
    );
    const title = ticket?.title || 'Message title';

    return isUnread ? <b>{title}</b> : title;
  };

  return (
    <>
      <Grid
        data={tickets}
        {...dataState}
        onDataStateChange={dataStateChange}
        sortable={true}
        pageable={{ buttonCount: 4 }}
        className='tickets'>
        <Column
          title='Topic'
          sortable={false}
          cell={(e: any) => {
            return (
              <MessageWrapper data-testid={`ticket-table-cell-${e.dataIndex}`}>
                <CustomBadge status={e?.dataItem.status}>
                  {ticketStatusMap.get(e?.dataItem.status)}
                </CustomBadge>
                <DetailsLinkMessage onClick={() => handleShowTicket(e.dataItem?.id)}>
                  {parseTicketTitle(e.dataItem)}
                </DetailsLinkMessage>
              </MessageWrapper>
            );
          }}
        />
        <Column width={220} field='sender.name' title='From' />
        <Column width={220} field='recipient.name' title='To' />
        <Column width={220} field='lastMessageDate' title='Date' />
        <Column
          width={getTableSize(3)}
          title='Actions'
          cell={(e: any) => (
            <Tooltip anchorElement='target' parentTitle={true} position='top'>
              <ActionButtonsWrapper>
                {e.dataItem.isRecordReplay && (
                  <ActionButtonPrimary
                    title={'Play record'}
                    onClick={() => handleRedirect(e.dataItem.id)}>
                    <SvgIcon icon={svgIcons['fileConfigIcon']} size='medium' />
                  </ActionButtonPrimary>
                )}
                {e.dataItem.status !== SupportTicketStatusEnum.closed && (
                  <ActionButtonError
                    title={'Close ticket'}
                    onClick={() => handleCloseTicket(e.dataItem.id)}>
                    <SvgIcon icon={svgIcons['lockIcon']} size='medium' />
                  </ActionButtonError>
                )}
                {e.dataItem.status === SupportTicketStatusEnum.closed && (
                  <ActionButtonPrimary
                    title={'Re-Open ticket'}
                    onClick={() => handleReopenTicket(e.dataItem.id)}>
                    <SvgIcon icon={svgIcons['arrowRotateCcwSmallIcon']} size='medium' />
                  </ActionButtonPrimary>
                )}
              </ActionButtonsWrapper>
            </Tooltip>
          )}
        />
      </Grid>
      <TableDataLoader
        dataState={dataState}
        onDataReceived={dataReceived}
        setDataState={setDataState}
        sortMap={sortMap}
        additionalQueryParams={queryParams}
        getFetch={queryFunction}
        errorMessage={'Failed to fetch messages'}
        ref={tableRef}
        queryKey={SUPPORT_TICKETS_QUERY_KEY}
        tableId={'tickets'}
      />
    </>
  );
};

export default TicketsGrid;
