/* eslint-disable no-unused-vars */
import DefaultLayout from '../../layouts/DefaultLayout';
import { HeaderComponent } from '../../components/Typography/Header';
import LegendParagraph from '../../components/LegendParagraph/LegendParagraph';
import { Button } from '@progress/kendo-react-buttons';
import { useHistory, useParams } from 'react-router-dom';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import {
  CLINICIANS,
  COMPANY,
  DEVICE,
  DEVICE_EDIT,
  LOCATION,
  PATIENTS
} from '../../constants/routes';
import DeviceImg from '../../assets/tripod-open.png';
import DeviceRightImg from '../../assets/tripod-open-right.png';
import {
  useDeviceConfig,
  useDeviceDelete,
  useDeviceHistory,
  useDeviceInfo
} from '../../hooks/api/useDevices';
import { useUser, useUsers } from '../../hooks/api/useUsers';
import { useModal } from '../../hooks/api/useModal';
import { RoleEnum, UserExtendOptions, UsersQueryParams } from '../../api/users/users.types';
import ConfirmDeleteModal, { ModalMessageDevice } from '../../components/Modals/ConfirmDeleteModal';
import { DetailsLink } from '../../components/Links/Links';
import { SuccessButton } from '../../components/ActionButton/ActionButton';
import ModesGrid from '../Modes/ModesGrid';
import React, { useState } from 'react';
import { ModesEntry, ModesExtendOptions, ModesQueryParams } from '../../api/modes/modes.types';
import { useDeviceModes, useUpdateMode } from '../../hooks/api/useModes';
import { UserRoles } from '../../types';
import { CONFIGURATOR_URL } from '../../constants/config';
import DeviceUsageTab from '../DeviceUsage/DeviceUsageTab';
import {
  ButtonsWrapper,
  CompanyDetails,
  DeviceDetailsWrapper,
  DeviceName,
  InnerWrapper,
  LowerGrid,
  RemoveButton,
  StyledCard,
  Subtitle,
  TabsWrapper,
  UpperGrid
} from './styled';
import { DeviceExtendOptions, DevicesQueryParams } from '../../api/devices/device.types';
import { userCanVisit, userHasPermissions } from '../../utils/permissionUtils';
import useUserData from '../../hooks/useUserData';
import * as Sentry from '@sentry/react';
import RepairGrid from './Repair';
import { userHasRole } from '../../hoc/useCanAccess';
import {
  CLINICIANS_PERMISSIONS,
  COMPANIES_PERMISSIONS,
  DEVICE_CONFIG_PERMISSIONS,
  DEVICES_PERMISSIONS,
  LOCATIONS_PERMISSIONS,
  PATIENTS_PERMISSIONS
} from '../../constants/rolesPermissions';
import '../Patients/styles.css';
import ConfirmationLoader from 'layouts/ConfirmationLoader';
import { ErrorComponent } from 'components/Error/ErrorComponent';

const DeviceDetails = () => {
  // @ts-ignore
  const { deviceId } = useParams();
  const { rolesByName, data: user } = useUserData();
  const [selected, setSelected] = useState(0);
  const { push } = useHistory();
  const isClinicianSupportAccount = userHasRole(user, RoleEnum.clinicianSupport);
  const queryParamsDevicesClinician: DevicesQueryParams = {
    extend: [
      DeviceExtendOptions.model,
      DeviceExtendOptions.clinician,
      DeviceExtendOptions.amputee,
      DeviceExtendOptions.company,
      DeviceExtendOptions.pcbVersion,
      DeviceExtendOptions.firmawreVersion
    ]
  };
  const {
    result: deviceInfo,
    isLoading: isLoadingDeviceInfo,
    isError: isErrorDeviceInfo
  } = useDeviceInfo(
    deviceId,
    userHasPermissions(DEVICES_PERMISSIONS.DETAILS, rolesByName),
    queryParamsDevicesClinician
  );
  const { result: deviceHistory, isLoading: isLoadingDeviceHistory } = useDeviceHistory(
    deviceId,
    userHasPermissions(DEVICE_CONFIG_PERMISSIONS.DETAILS, rolesByName),
    {
      perpage: 10000
    }
  );
  const queryParamsClinician: UsersQueryParams = {
    extend: [UserExtendOptions.location, UserExtendOptions.locationCompany]
  };
  const { result: clinicianInfo } = useUser(
    !isClinicianSupportAccount && deviceInfo?.clinician?.id
      ? deviceInfo?.clinician?.id
      : deviceInfo?.amputee_id,
    queryParamsClinician
  );
  const { mutateAsync: deleteDevice, isLoading: isLoadingDelete } = useDeviceDelete();
  const {
    isOpen: isModalOpen,
    handleOpen: handleModalOpen,
    handleClose: handleModalClose
  } = useModal();

  const handleDelete = async () => {
    await deleteDevice(deviceId);
    push(DEVICE);
  };

  const handleEdit = () => {
    push({ pathname: DEVICE_EDIT, state: { detail: deviceInfo } });
  };

  const handleConfigure = (
    serial: string,
    bluetooth_id: string,
    deviceId: string,
    patientId: string
  ) => {
    window.open(
      `${CONFIGURATOR_URL}/device?serial=${serial}&bluetoothId=${bluetooth_id}&deviceId=${deviceId}&amputeeId=${patientId}`,
      '_blank'
    );
  };

  const { mutateAsync: updateMode } = useUpdateMode();
  const userQueryParams: UsersQueryParams = {
    roles: UserRoles.amputee
  };
  const { result: amputee } = useUsers(userQueryParams);
  const modesQueryParams: ModesQueryParams = {
    extend: ModesExtendOptions.deviceAmputee
  };
  const configModes = useDeviceModes(
    deviceId,
    !!deviceId && userHasPermissions(DEVICE_CONFIG_PERMISSIONS.DETAILS, rolesByName),
    modesQueryParams
  );
  const deviceConfigs = useDeviceConfig(
    deviceId,
    !!deviceId && userHasPermissions(DEVICE_CONFIG_PERMISSIONS.DETAILS, rolesByName)
  );

  const handleSelect = (e: any) => {
    setSelected(e.selected);
  };

  const submitEditForm = async (formParams: any, currentItem: ModesEntry) => {
    try {
      if (currentItem) {
        // @ts-ignore
        await updateMode({
          name: formParams.mode_name,
          deviceId: currentItem.device_id,
          modeId: currentItem.id
        });
      }
    } catch (e) {
      Sentry.captureException(e);
      console.log('e', e);
    }
  };

  const toggleDisable = async (mode: ModesEntry) => {
    try {
      if (mode) {
        // @ts-ignore
        await updateMode({
          active: Number(!mode.active),
          deviceId: mode.device_id,
          modeId: mode.id
        });
      }
    } catch (e) {
      Sentry.captureException(e);
      console.log('e', e);
    }
  };

  const handleEditOnConfigurator = (item: ModesEntry, modeNumber: number) => {
    if (!item) {
      return;
    }
    window.open(`${CONFIGURATOR_URL}/device?deviceId=${deviceId}&modeId=${modeNumber}`, '_blank');
  };

  const Details = (
    <InnerWrapper>
      <HeaderComponent headerText='Device details' isLoading={false} />
      {isModalOpen && (
        <ConfirmDeleteModal
          handleClose={handleModalClose}
          handleAccept={handleDelete}
          isLoading={isLoadingDelete}
          message={<ModalMessageDevice id={deviceId} />}
        />
      )}
      {
        <StyledCard>
          <UpperGrid>
            <DeviceDetailsWrapper>
              <img
                src={deviceInfo?.model?.orientation === 'left' ? DeviceImg : DeviceRightImg}
                alt='Device image'
              />
              <div>
                <DeviceName>{deviceInfo?.serial}</DeviceName>
                <Subtitle>{deviceInfo?.model?.name}</Subtitle>
              </div>
            </DeviceDetailsWrapper>
            <ButtonsWrapper>
              {userHasPermissions(DEVICES_PERMISSIONS.DELETE, rolesByName) && (
                <RemoveButton
                  data-testid='delete-device'
                  onClick={handleModalOpen}
                  fillMode='outline'
                  icon='trash'>
                  Delete device
                </RemoveButton>
              )}
              {userHasPermissions(DEVICES_PERMISSIONS.CONFIGURE, rolesByName) &&
                deviceInfo &&
                deviceInfo?.clinician_id !== null &&
                deviceInfo?.amputee_id !== null && (
                  <SuccessButton
                    data-testid='configurator'
                    onClick={() =>
                      handleConfigure(
                        deviceInfo?.serial,
                        deviceInfo?.bluetooth_id,
                        deviceId,
                        deviceInfo?.amputee_id
                      )
                    }
                    icon='gear'>
                    Configurator
                  </SuccessButton>
                )}
              {!isClinicianSupportAccount && (
                <Button
                  data-testid='edit-device'
                  onClick={handleEdit}
                  icon='pencil'
                  themeColor={'primary'}>
                  Edit device
                </Button>
              )}
            </ButtonsWrapper>
          </UpperGrid>
          <LowerGrid>
            <LegendParagraph headerText='COMPANY'>
              <CompanyDetails>
                <DetailsLink
                  disabled={!userCanVisit(COMPANIES_PERMISSIONS.VIEW, rolesByName)}
                  to={`${COMPANY}/${clinicianInfo?.location?.company?.id}`}>
                  {deviceInfo?.company?.name}
                </DetailsLink>
              </CompanyDetails>
            </LegendParagraph>
            <LegendParagraph headerText='LOCATION'>
              <CompanyDetails>
                <DetailsLink
                  disabled={!userCanVisit(LOCATIONS_PERMISSIONS.VIEW, rolesByName)}
                  to={`${LOCATION}/${clinicianInfo?.location?.id}`}>
                  {clinicianInfo?.location?.name}
                </DetailsLink>
              </CompanyDetails>
            </LegendParagraph>
            <LegendParagraph headerText='CLINICIAN'>
              <CompanyDetails>
                <DetailsLink
                  disabled={!userCanVisit(CLINICIANS_PERMISSIONS.VIEW, rolesByName)}
                  to={`${CLINICIANS}/${deviceInfo?.clinician?.id}`}>
                  {deviceInfo?.clinician?.name}
                </DetailsLink>
              </CompanyDetails>
            </LegendParagraph>
            <LegendParagraph headerText='PATIENT'>
              <CompanyDetails>
                <DetailsLink
                  to={`${PATIENTS}/${deviceInfo?.amputee_id}`}
                  disabled={!userCanVisit(PATIENTS_PERMISSIONS.VIEW, rolesByName)}>
                  {deviceInfo?.amputee?.name}
                </DetailsLink>
              </CompanyDetails>
            </LegendParagraph>
          </LowerGrid>
          {deviceHistory && (
            <TabsWrapper>
              <TabStrip selected={selected} onSelect={handleSelect}>
                <TabStripTab title='Modes'>
                  <ModesGrid
                    handleEdit={submitEditForm}
                    handleToggleDisable={toggleDisable}
                    handleEditInConfigurator={handleEditOnConfigurator}
                    amputee={amputee}
                    device={deviceInfo}
                    queryMetaData={configModes}
                    queryMetaDataDevice={deviceConfigs}
                  />
                </TabStripTab>
                <TabStripTab title='Device usage' contentClassName='device-usage'>
                  <DeviceUsageTab devices={[deviceInfo]} />
                </TabStripTab>
                {deviceInfo?.clinician && (
                  <TabStripTab title='PCB update'>
                    <RepairGrid device={deviceInfo} />
                  </TabStripTab>
                )}
              </TabStrip>
            </TabsWrapper>
          )}
        </StyledCard>
      }
    </InnerWrapper>
  );

  if (isErrorDeviceInfo)
    return (
      <ErrorComponent
        message="The device no longer exists or you don't have access to it."
        allowRedirect
      />
    );

  return <DefaultLayout>{isLoadingDeviceInfo ? <ConfirmationLoader /> : Details}</DefaultLayout>;
};

export default DeviceDetails;
