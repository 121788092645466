import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { Grid } from '@progress/kendo-react-grid';
import { Role, RoleEnum, UserEntry } from '../../../api/users/users.types';
import {
  ActionButtonError,
  ActionButtonPrimary
} from '../../../components/ActionButton/ActionButton';
import { USER_MANAGE } from '../../../constants/routes';
import { SvgIcon } from '@progress/kendo-react-common';
import * as svgIcons from '@progress/kendo-svg-icons';
import { ActionButtonsWrapper } from '../styled';
import { useHistory } from 'react-router-dom';
import { getTableSize } from '../../../utils/tableHelpers';

const UserTab = ({
  users = [],
  onDelete,
  isPatientView = false,
  isCliniciansView = false,
  dataState,
  onDataStateChange,
  isSuperAdmin = false
}: {
  users?: UserEntry[];
  onDelete: (user: UserEntry) => void;
  isPatientView?: boolean;
  isCliniciansView?: boolean;
  dataState: any;
  onDataStateChange: Function;
  isSuperAdmin: boolean;
}) => {
  const { push } = useHistory();
  const isShowActionButtons = (userEntry: UserEntry) => {
    const isAmputeeAccount = userEntry.roles?.find(
      (roles: Role) => roles.name === RoleEnum.amputee
    );

    return !(isSuperAdmin && isAmputeeAccount);
  };
  return (
    <Grid
      data={users}
      {...dataState}
      onDataStateChange={onDataStateChange}
      sortable={true}
      pageable={{ buttonCount: 4, pageSizes: true }}
      selectable={{
        enabled: true
      }}
      style={{ height: '100%' }}>
      <Column field='name' title={isPatientView && isSuperAdmin ? 'Id' : 'Name'} />
      {((isPatientView && !isSuperAdmin) || !isPatientView) && (
        <Column field='email' title='Email' />
      )}
      <Column field='mrn' title='MRN' />
      <Column field='phone' title='Phone number' />
      {(isCliniciansView || isPatientView) && (
        <Column field='location.company.name' title='Company' />
      )}
      <Column field='location.company.country.name' title='Country' />
      {(isCliniciansView || isPatientView) && <Column field='location.name' title='Location' />}
      {isPatientView && <Column field='clinician.name' title='Clinician' />}
      <Column
        title='Actions'
        width={getTableSize(2)}
        cell={(e: any) => (
          <ActionButtonsWrapper>
            {isShowActionButtons(e.dataItem) && (
              <>
                <ActionButtonPrimary
                  onClick={() => push(`${USER_MANAGE}/edit`, { item: e.dataItem })}>
                  <SvgIcon icon={svgIcons['pencilIcon']} size='medium' />
                </ActionButtonPrimary>
                <ActionButtonError onClick={() => onDelete(e.dataItem)}>
                  <SvgIcon icon={svgIcons['trashIcon']} size='medium' />
                </ActionButtonError>
              </>
            )}
          </ActionButtonsWrapper>
        )}
      />
    </Grid>
  );
};

export default UserTab;
