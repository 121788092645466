import DefaultLayout from 'layouts/DefaultLayout';
import { useHistory, useLocation } from 'react-router-dom';
import { useUserUpdate } from 'hooks/api/useUsers';
import { UserRoles } from 'types';
import { isNil, omitBy } from 'lodash';
import { RoleEnum, UserUpdatePayload } from 'api/users/users.types';
import { MANAGE_CLINICIANS_PATIENTS } from 'constants/routes';
import FormContentWrapper from 'layouts/FormContentWrapper';
import PatientForm from './PatientForm';
import { parseCountryCodeFromAPI, parsePhoneFromAPI } from 'utils/parsePhone';
import { userHasPermissions } from 'utils/permissionUtils';
import useUserData from 'hooks/useUserData';
import { errorNotification, successNotification } from 'utils/notifications';
import * as Sentry from '@sentry/react';
import {
  CHAT_ROOMS_AVAILABLE_PATIENTS_QUERY_KEY,
  CHAT_ROOMS_QUERY_KEY
} from 'features/Chat/hooks/api/useChatRooms';
import { useQueryClient } from '@tanstack/react-query';
import { countryCodeMap, countryCodeReversedMap } from 'utils/definesLocal';

const EditPatient = () => {
  const queryClient = useQueryClient();
  const { push } = useHistory();
  const { rolesByName } = useUserData();
  const { state: { detail: user = undefined } = {} }: any = useLocation();
  const { mutateAsync: editUser, isLoading: isLoadingUpdated } = useUserUpdate();

  const handleSubmit = async ({
    name = undefined,
    email,
    phone = undefined,
    phone_code = undefined,
    location,
    clinician,
    mrn
  }: any) => {
    try {
      await editUser(
        omitBy(
          {
            id: user.id,
            name,
            ...(mrn !== user.mrn && { mrn }),
            email: user.email === email ? undefined : email,
            ...(phone && { phone: `${countryCodeReversedMap.get(phone_code)}-${phone}` }),
            ...(location && { location: location?.id || null }),
            role: UserRoles.amputee,
            ...(clinician && { clinician: clinician.id })
          },
          isNil
        ) as UserUpdatePayload
      );
      await queryClient.invalidateQueries([CHAT_ROOMS_QUERY_KEY]);
      await queryClient.invalidateQueries([CHAT_ROOMS_AVAILABLE_PATIENTS_QUERY_KEY]);
      successNotification('User updated');
      push(MANAGE_CLINICIANS_PATIENTS);
    } catch (error) {
      Sentry.captureException(error);
      errorNotification('Failed to update patient');
    }
  };

  const transformInitialData = (userData: any) => {
    const phoneCode = parseCountryCodeFromAPI(userData?.phone);
    return {
      ...userData,
      phone_code: phoneCode ? countryCodeMap.get(phoneCode) : '',
      phone: parsePhoneFromAPI(userData?.phone)
    };
  };

  return (
    <DefaultLayout>
      <FormContentWrapper title={'Edit patient'} small>
        <PatientForm
          onSubmit={handleSubmit}
          isAdmin={userHasPermissions([RoleEnum.superAdmin], rolesByName)}
          user={transformInitialData(user)}
          isUpdate={true}
          isLoading={isLoadingUpdated}
        />
      </FormContentWrapper>
    </DefaultLayout>
  );
};

export default EditPatient;
