import { useRef, forwardRef, useImperativeHandle } from 'react';
import * as ReactDOM from 'react-dom';
import { toODataString } from '@progress/kendo-data-query';
import { useFetchingOData } from 'hooks/api/useFetchingOData';
import { Loader } from '@progress/kendo-react-indicators';
import styled from 'styled-components';

type LoadingPanelProps = {
  tableId?: string;
};

const LoaderWrapper = styled.div`
  position: absolute;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingPanel = ({ tableId }: LoadingPanelProps) => {
  const loadingPanel = (
    <div className='k-loading-mask'>
      <span className='k-loading-text'>Loading</span>
      <LoaderWrapper>
        <Loader themeColor={'dark'} size={'large'} />
      </LoaderWrapper>
      <div className='k-loading-color' />
    </div>
  );
  const gridContent = document && document.querySelector('.k-grid-content');
  const table = document && document.querySelector(`.${tableId}`);
  return table
    ? ReactDOM.createPortal(loadingPanel, table)
    : gridContent
      ? ReactDOM.createPortal(loadingPanel, gridContent)
      : loadingPanel;
};

type TableDataLoaderProps = {
  dataState: any;
  onDataReceived: Function;
  setDataState: Function;
  sortMap: {
    [key: string]: any;
  };
  getFetch: Function;
  errorMessage: string;
  queryKey: string;
  additionalQueryParams?: any;
  tableId?: string;
};

export const TableDataLoader = forwardRef(
  (
    {
      dataState,
      onDataReceived,
      setDataState,
      sortMap,
      getFetch,
      errorMessage,
      queryKey,
      additionalQueryParams,
      tableId
    }: TableDataLoaderProps,
    ref
  ) => {
    const lastSuccess = useRef('');
    const pending = useRef('');

    const requestDataIfNeeded = () => {
      if (pending.current || toODataString(dataState) === lastSuccess.current) {
        return;
      }

      const match = pending.current.match(/\$skip=(\d+)/);
      const matchLast = lastSuccess.current.match(/\$skip=(\d+)/);
      const skipValue = match ? parseInt(match[0]) : null;
      const skipValueLast = matchLast ? parseInt(matchLast[0]) : null;

      pending.current = toODataString(dataState);
      if (skipValue === skipValueLast) setDataState({ ...dataState, skip: 0 });
      refetch();
    };

    const transformData = (data: any) => {
      lastSuccess.current = pending.current;
      pending.current = '';
      if (toODataString(dataState) === lastSuccess.current) {
        onDataReceived({
          data: data.items,
          total: data.paginator.total
        });
      } else {
        requestDataIfNeeded();
      }
    };

    const { isLoading: isLoadingStatus, refetch } = useFetchingOData({
      queryParams: {
        page: (dataState?.skip + dataState?.take) / dataState?.take,
        perpage: dataState?.take,
        ...(dataState?.sort?.[0] && { sortby: sortMap[dataState.sort[0].field] }),
        ...(dataState?.sort?.[0] && { sortdir: dataState.sort[0].dir }),
        ...(additionalQueryParams && { ...additionalQueryParams })
      },
      onSuccess: (data: any) => transformData(data),
      getFetch,
      errorMessage,
      queryKey
    });

    requestDataIfNeeded();

    useImperativeHandle(ref, () => ({
      refresh() {
        refetch();
      }
    }));
    return isLoadingStatus || pending.current ? <LoadingPanel tableId={tableId} /> : null;
  }
);
TableDataLoader.displayName = 'TableLoader';
