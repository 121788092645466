import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logoutSuccess, startLogoutUser } from 'reducers/authReducer';
import { LOGIN } from 'constants/routes';
import { logoutAPI } from 'api/authentication/authentication';
import { setApiAuthToken } from 'api/utils/apiClient';
import { useEffect } from 'react';

export default function useLogout() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const history = useHistory();
  const broadcast = new BroadcastChannel('logouts');

  const { isLoading, mutate: logout } = useMutation(logoutAPI, {
    onSuccess() {
      localStorage.removeItem('redirectUrl');
      sessionStorage.removeItem('lastActivity');
      dispatch(logoutSuccess());
      broadcast.postMessage({ typ: 'LOG_OUT' });
      queryClient.clear();
      history.push(LOGIN);
    },
    onSettled() {
      window.location.reload();
    }
  });

  const beforeLogout = (dispatchEvent: boolean = false) => {
    dispatch(startLogoutUser());
    setApiAuthToken(null);
    queryClient.cancelQueries();
    logout();

    if (dispatchEvent) {
      const event = new CustomEvent('logoutEvent', { detail: {}, bubbles: true });
      document.dispatchEvent(event);
    }
  };

  const { isLoading: mfaLogoutIsLoading, mutate: mfaLogout } = useMutation(logoutAPI, {
    onSuccess() {
      window.location.replace(LOGIN);
    }
  });

  const offlineLogout = () => {
    setApiAuthToken(null);
    dispatch(logoutSuccess());
    queryClient.cancelQueries();
    setTimeout(() => {
      queryClient.resetQueries();
    });
  };

  useEffect(() => {
    broadcast.onmessage = () => {
      offlineLogout();
    };
  }, []);

  return {
    logout: (dispatchEvent: boolean = false) => beforeLogout(dispatchEvent),
    mfaLogout,
    isLoading: isLoading || mfaLogoutIsLoading,
    offlineLogout
  };
}
