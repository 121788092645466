import { useRef, useState } from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';
import { Grid, GridCellProps, GridColumn as Column } from '@progress/kendo-react-grid';
import { ActionButtonError, ActionButtonPrimary } from 'components/ActionButton/ActionButton';
import { SvgIcon } from '@progress/kendo-react-common';
import * as svgIcons from '@progress/kendo-svg-icons';
import DefaultLayout from '../../layouts/DefaultLayout';
import { useHistory } from 'react-router-dom';
import {
  CLINICIANS,
  COMPANY,
  DEVICE,
  LOCATION,
  PATIENT_ADD,
  PATIENT_EDIT,
  PATIENTS
} from '../../constants/routes';
import { useModal } from '../../hooks/api/useModal';
import ConfirmDeleteModal, { ModalMessageDelete } from '../../components/Modals/ConfirmDeleteModal';
import { useQueryClient } from '@tanstack/react-query';
import { USERS_QUERY_KEY, useUserDelete } from '../../hooks/api/useUsers';
import { getTableSize } from '../../utils/tableHelpers';
import { DeviceEntry } from '../../api/devices/device.types';
import { DetailsLink, TableLink } from '../../components/Links/Links';
import { DEBOUNCE_SEARCH_TIME } from '../../constants/search';
import TableHeader from '../../components/Table/TableHeader';
import { Button } from '@progress/kendo-react-buttons';
import { Switch } from '@progress/kendo-react-inputs';
import { userHasPermissions } from '../../utils/permissionUtils';
import {
  RoleEnum,
  UserEntry,
  UserExtendOptions,
  UsersQueryParams
} from '../../api/users/users.types';
import useUserData from '../../hooks/useUserData';
import { TableDataLoader } from '../../components/TableDataLoader/TableDataLoader';
import { getUsers } from '../../api/users/users';
import { PATIENTS_PERMISSIONS } from '../../constants/rolesPermissions';

const LocationsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ActionButtonsWrapper = styled.td`
  button {
    margin-right: 5px;

    &:last-child {
      margin: 0;
    }
  }
`;

export const DevicesWrapper = styled.td`
  display: flex;
  flex-direction: column;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.base};

  span {
    white-space: pre;
  }
`;

export const SwitchInner = styled.div`
  margin-right: 12px;
`;

const mapData = (patients?: UserEntry[]) => {
  if (!patients) {
    return [];
  }
  return patients.map((item: UserEntry) => {
    return {
      id: item.id,
      name: item.name,
      email: item.email,
      mrn: item.mrn,
      company: item?.location?.company?.name,
      companyId: item?.location?.company?.id,
      location: item?.location?.name,
      locationId: item?.location?.id,
      role: item.roles?.join(','),
      country: item.location?.company?.country?.name,
      devices: item.devices,
      clinician: item?.clinician
    };
  });
};

export const hasPermissionToDeletePatient = (
  clinicianId: any,
  rolesByName: any,
  userData: any
): boolean => {
  if (
    userHasPermissions([RoleEnum.clinician], rolesByName) &&
    userHasPermissions(PATIENTS_PERMISSIONS.DELETE, rolesByName)
  ) {
    return clinicianId === userData.id;
  }

  return userHasPermissions(PATIENTS_PERMISSIONS.DELETE, rolesByName);
};

const sortMap: any = {
  name: 'user_name',
  company: 'company_name',
  location: 'location_name',
  clinician: 'clinician_name',
  mrn: 'user_mrn'
};

const PatientsList = () => {
  const { push } = useHistory();
  const { data: userData, rolesByName } = useUserData();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedPatient, setSelectedPatient] = useState<UserEntry>();
  const [onlyMyPatients, setOnlyMyPatients] = useState<boolean | null>(
    !userHasPermissions(
      [RoleEnum.superAdmin, RoleEnum.clinicAdmin, RoleEnum.clinicianSupport],
      rolesByName
    )
  );
  const queryClient = useQueryClient();
  const queryParams: UsersQueryParams = {
    extend: [
      UserExtendOptions.location,
      UserExtendOptions.roles,
      UserExtendOptions.countryLocation,
      UserExtendOptions.locationCompany,
      UserExtendOptions.devices,
      UserExtendOptions.clinician,
      UserExtendOptions.permissions
    ],
    roles: 'Amputee',
    search: searchTerm,
    ...(onlyMyPatients && { clinician: userData?.id }),
    ...(userHasPermissions([RoleEnum.clinicAdmin], rolesByName) && {
      company: userData?.location?.company_id
    })
  };
  const {
    isOpen: isModalOpen,
    handleOpen: handleModalOpen,
    handleClose: handleModalClose
  } = useModal();
  const history = useHistory();
  const [patients, setPatients] = useState<any>({
    data: [],
    total: 10
  });
  const [dataState, setDataState] = useState({
    take: 12,
    skip: 0
  });
  const tableRef = useRef(null) as any;

  const dataReceived = (patients: any) => {
    setPatients({ data: mapData(patients.data), total: patients.total });
  };

  const dataStateChange = (e: any) => {
    setDataState(e.dataState);
  };

  const { mutateAsync: deleteUser, isLoading: isLoadingDelete } = useUserDelete();

  const handleDeleteModal = (patient: UserEntry) => {
    setSelectedPatient(patient);
    handleModalOpen();
  };

  const handleDelete = async () => {
    if (selectedPatient) {
      await deleteUser(selectedPatient.id);
      handleModalClose();
      await queryClient.invalidateQueries([USERS_QUERY_KEY]);
    }
  };

  return (
    <DefaultLayout>
      <LocationsListWrapper>
        <TableHeader
          headerText='Patients list'
          search
          searchPlaceholder={
            userHasPermissions([RoleEnum.superAdmin], rolesByName)
              ? 'Type Patient Id'
              : 'Type Patient name'
          }
          setSearchTerm={debounce(setSearchTerm, DEBOUNCE_SEARCH_TIME)}
          actionsFilters={
            userHasPermissions([RoleEnum.clinician, RoleEnum.clinicAdmin], rolesByName) && (
              <SwitchWrapper data-testid='switch-only-my-patients'>
                <SwitchInner>
                  <Switch
                    checked={!!onlyMyPatients}
                    onChange={() => setOnlyMyPatients((prev) => !prev)}
                  />
                </SwitchInner>
                <span>Show only my patients</span>
              </SwitchWrapper>
            )
          }
          actions={
            userHasPermissions(PATIENTS_PERMISSIONS.CREATE, rolesByName) && (
              <Button
                data-testid='button-add'
                onClick={() => history.push(PATIENT_ADD)}
                themeColor={'primary'}
                icon={'plus'}>
                Add new patient
              </Button>
            )
          }
        />
        {isModalOpen && (
          <ConfirmDeleteModal
            handleClose={handleModalClose}
            handleAccept={handleDelete}
            isLoading={isLoadingDelete}
            message={
              <ModalMessageDelete
                name={selectedPatient!.name}
                id={selectedPatient!.id}
                text='Do you want to delete patient'
              />
            }
          />
        )}
        <Grid
          data={patients}
          {...dataState}
          onDataStateChange={dataStateChange}
          sortable={true}
          pageable={{ buttonCount: 4 }}
          data-testid='patients-table'
          selectable={{
            enabled: true
          }}>
          <Column
            field='name'
            title={userHasPermissions([RoleEnum.superAdmin], rolesByName) ? 'Id' : 'Name'}
            sortable={!userHasPermissions([RoleEnum.superAdmin], rolesByName)}
            cell={(e: GridCellProps) => (
              <td data-testid={`patients-table-cell-${e.dataIndex}`}>
                <TableLink to={`${PATIENTS}/${e.dataItem.id}`}>{e.dataItem.name}</TableLink>
              </td>
            )}
          />
          {!userHasPermissions([RoleEnum.superAdmin], rolesByName) && (
            <Column field='email' title='Email' sortable={false} />
          )}
          <Column field='mrn' title='MRN' />
          <Column
            field='company'
            title='Company'
            cell={(e: GridCellProps) => (
              <td>
                <TableLink to={`${COMPANY}/${e.dataItem?.companyId}`}>
                  {e.dataItem?.company}
                </TableLink>
              </td>
            )}
          />
          <Column
            field='clinician'
            title='Clinician'
            cell={(e: any) => (
              <td>
                <DetailsLink
                  to={`${CLINICIANS}/${e.dataItem?.clinician?.id}`}
                  disabled={
                    !userHasPermissions([RoleEnum.superAdmin, RoleEnum.clinicAdmin], rolesByName)
                  }>
                  {e.dataItem?.clinician?.name}
                </DetailsLink>
              </td>
            )}
          />
          <Column
            field='location'
            title='Location'
            cell={(e: any) => (
              <td>
                <TableLink to={`${LOCATION}/${e.dataItem?.locationId}`}>
                  {e.dataItem?.location}
                </TableLink>
              </td>
            )}
          />
          <Column
            field='devices'
            title='Devices'
            sortable={false}
            cell={(e: any) => {
              const rows = Array.from(e.dataItem?.devices as DeviceEntry[]).map(
                (item: DeviceEntry) => {
                  return (
                    <TableLink key={item.id} to={`${DEVICE}/${item.id}`}>
                      {item.serial}
                    </TableLink>
                  );
                }
              );
              return <DevicesWrapper>{rows}</DevicesWrapper>;
            }}
          />
          <Column
            title='Actions'
            width={getTableSize(2)}
            cell={(e: any) => (
              <ActionButtonsWrapper>
                {userHasPermissions(PATIENTS_PERMISSIONS.EDIT, rolesByName) && (
                  <ActionButtonPrimary
                    data-testid='button-edit'
                    onClick={() => push({ pathname: PATIENT_EDIT, state: { detail: e.dataItem } })}>
                    <SvgIcon icon={svgIcons['pencilIcon']} size='medium' />
                  </ActionButtonPrimary>
                )}
                {hasPermissionToDeletePatient(e.dataItem?.clinician?.id, rolesByName, userData) && (
                  <ActionButtonError
                    onClick={() => handleDeleteModal(e.dataItem)}
                    data-testid='button-delete'>
                    <SvgIcon icon={svgIcons['trashIcon']} size='medium' />
                  </ActionButtonError>
                )}
              </ActionButtonsWrapper>
            )}
          />
        </Grid>
        <TableDataLoader
          dataState={dataState}
          onDataReceived={dataReceived}
          setDataState={setDataState}
          sortMap={sortMap}
          additionalQueryParams={queryParams}
          getFetch={getUsers}
          errorMessage={'Failed to fetch patients'}
          ref={tableRef}
          queryKey={USERS_QUERY_KEY}
        />
      </LocationsListWrapper>
    </DefaultLayout>
  );
};

export default PatientsList;
