import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';

import store from './store/store';
import GlobalStyle from './theme/globalStyle';
import { myTheme } from 'theme/theme';
import { SENTRY_DSN, SENTRY_TRACES_SAMPLE_DATE } from 'constants/config';
import { CaptureConsole, ReportingObserver } from '@sentry/integrations';
import AppProviders from 'AppProviders';
import { ErrorEvent, EventHint } from '@sentry/types/types/event';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

Sentry.init({
  dsn: SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT,
  integrations: [
    new BrowserTracing(),
    new CaptureConsole({ levels: ['error'] }),
    new ReportingObserver({ types: ['crash'] }),
    new Sentry.Replay({ maskAllText: false })
  ],
  beforeSend(event: ErrorEvent, hint: EventHint) {
    if (
      // @ts-ignore
      hint?.originalException?.isAxiosError &&
      // @ts-ignore
      hint?.originalException?.response &&
      // @ts-ignore
      hint.originalException.response?.status < 500
    ) {
      return null;
    }
    return event;
  },
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: SENTRY_TRACES_SAMPLE_DATE
});

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store()}>
        <GlobalStyle />
        <ThemeProvider theme={myTheme}>
          <BrowserRouter>
            <AppProviders>
              <StrictMode>
                <App />
              </StrictMode>
            </AppProviders>
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
