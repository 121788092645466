/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import DefaultLayout from '../../layouts/DefaultLayout';
import styled from 'styled-components';
import Card from '../../components/Card/Card';
import { HeaderComponent } from '../../components/Typography/Header';
import { Form, Field, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import {
  ME_QUERY_KEY,
  useChangeUserPhone,
  USER_QUERY_KEY,
  useUserMe,
  useUserUpdate
} from '../../hooks/api/useUsers';
import FormInput from '../../components/FormInput/FormInput';
import { useHistory } from 'react-router-dom';
import { ACCOUNT_CHANGE_PASSWORD } from '../../constants/routes';
import FormUpload from '../../components/FormInput/FormUpload';
import { UserUpdatePayload } from '../../api/users/users.types';
import { FormSwitch } from '../../components/FormInput/FormSwitch';
import { useMfaMethod, useMfaUpdate } from '../../hooks/api/useAuthentication';
import { FormRadioGroup } from '../../components/FormInput/FormRadioGroup';
import { MfaOptions, SetMfaStatusPayload } from '../../api/authentication/authentication.types';
import { useQueryClient } from '@tanstack/react-query';
import { getFileName, getValidFiles } from 'utils/getFileName';
import { PhoneWrapper } from '../Companies/styled';
import {
  phoneValidatorOptional,
  phoneValidatorRequired,
  requiredValidator
} from '../../components/FormInput/validators';
import FormCountryCode from '../../components/FormInput/FormCountryCode';
import FormMaskedTextBox from '../../components/FormInput/FormMaskedTextBox';
import { parseCountryCodeFromAPI, parsePhoneFromAPI } from '../../utils/parsePhone';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { SvgIcon } from '@progress/kendo-react-common';
import { checkIcon, xIcon } from '@progress/kendo-svg-icons';
import { useModal } from '../../hooks/api/useModal';
import VerifyPhoneModal from '../../components/Modals/VerifyPhoneModal';
import { successNotification } from '../../utils/notifications';
import * as Sentry from '@sentry/react';
import { countryCodeMap, countryCodeReversedMap } from '../../utils/definesLocal';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js/mobile';
import { FormPasswordTextBox } from '../../components/FormInput/FormPasswordTextBox';
import LoaderButton from '../../components/LoaderButton/LoaderButton';
import { ReactComponent as DefaultAvatar } from '../../assets/dtp-avatar.svg';
import { CheckMfaPayload } from '../../api/authentication/authentication.types';
import { UserEntry } from '../../api/users/users.types';

const StyledCard = styled(Card)`
  padding: 30px;
`;

const Fieldset = styled.fieldset`
  width: 100%;
`;

const FieldsWrapper = styled.div`
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr;
  gap: ${({ theme }) => theme.dimensions.spacing * 2}px;
`;

const InnerWrapper = styled.div`
  margin: 0 auto;
  @media ${({ theme }) => theme.devices.xlarge} {
    width: 100%;
    max-width: 550px;
  }
`;

const Image = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  img {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid ${({ theme }) => theme.palette.border};
  }
`;

const PasswordWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;

  gap: 20px;

  button {
    height: 38px;
  }
`;

const VerificationBox = styled.div`
  display: flex;
  align-items: center;
  line-height: 1;
`;

const InfoCopy = styled.span`
  color: ${({ theme }) => theme.palette.secondary};
  font-size: ${({ theme }) => theme.fontSize.label};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: ${({ theme }) => theme.dimensions.spacing * 4}px !important;
`;

const StyledIcon = styled(SvgIcon)<{ verified: boolean }>`
  width: 18px;
  color: ${({ verified, theme }) => (verified ? theme.palette.success2 : theme.palette.error2)};
`;

const checkMethod = (mfaStatus: CheckMfaPayload) => {
  if (!mfaStatus.enabled) {
    if (mfaStatus.phone) {
      return MfaOptions.sms;
    } else {
      return MfaOptions.email;
    }
  } else {
    return mfaStatus.method;
  }
};

const PhoneTooltip = ({ verified = false, value }: { verified?: boolean; value: string }) => (
  <Tooltip anchorElement='target' position='top'>
    <div style={{ display: 'flex' }}>
      <span
        style={{ marginRight: '4px' }}
        title={verified ? 'Phone number verified' : 'Phone number not verified'}>
        Phone number
      </span>
      {value && value.length > 0 && (
        <VerificationBox title={verified ? 'Phone number verified' : 'Phone number not verified'}>
          <StyledIcon icon={verified ? checkIcon : xIcon} verified={verified} />
          <InfoCopy>{verified ? '' : '(Not verified)'}</InfoCopy>
        </VerificationBox>
      )}
    </div>
  </Tooltip>
);

const AccountDetails = () => {
  const { push, goBack } = useHistory();
  const { result: accountDetails, isLoading: isLoadingUserDetails } = useUserMe();
  const { result: mfaStatus, isLoading: isLoadingMfaStatus } = useMfaMethod();
  const { mutateAsync: updateUser, isLoading: isLoadingUserUpdate } = useUserUpdate();
  const { mutateAsync: updateMfa, isLoading: isLoadingMfaUpdate } = useMfaUpdate();
  const { mutateAsync: updateUserPhone, isLoading: isLoadingPhoneUpdate } = useChangeUserPhone();
  const {
    isOpen: isModalOpen,
    handleOpen: handleModalOpen,
    handleClose: handleModalClose
  } = useModal();
  const queryClient = useQueryClient();

  const handlePhoneVerify = async (phone: string) => {
    try {
      await updateUserPhone({ userId: accountDetails.id, phone });
      // Fallback to email 2fa when phone is changed
      if (mfaStatus.enabled === 1 && mfaStatus.method === MfaOptions.sms) {
        await updateMfa({ enabled: mfaStatus.enabled, method: MfaOptions.email });
        successNotification(
          'Your 2fa method has been changed to email, because you have changed phone number'
        );
      }
      handleModalOpen();
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
    } finally {
      await queryClient.invalidateQueries([ME_QUERY_KEY]);
      await queryClient.invalidateQueries([USER_QUERY_KEY]);
    }
  };

  const handleSubmit = async (e: any) => {
    const { name = undefined, image, mfa_enabled = undefined, method, phone = undefined } = e;
    const parsedImage = getValidFiles(image, false);

    const userData = {
      id: accountDetails.id,
      ...(name && { name }),
      ...(parsedImage && { image: parsedImage }),
      ...(image?.length === 0 && { image_delete: 1 })
    } as UserUpdatePayload;

    const mfaData = {
      enabled: mfa_enabled ? 1 : 0,
      ...(method && mfa_enabled && { method })
    } as SetMfaStatusPayload;

    try {
      await updateUser(userData);
      await updateMfa(mfaData);

      if (phone !== undefined && phone.length === 0) {
        await updateUserPhone({ userId: accountDetails.id, phone: '0' });
      }

      successNotification('User settings updated');
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
    }
  };

  const goToChangePassword = () => {
    push(ACCOUNT_CHANGE_PASSWORD);
  };

  const transformInitial = (accountDetails: UserEntry, mfaStatus: CheckMfaPayload) => {
    const phoneCode = parseCountryCodeFromAPI(accountDetails?.phone);
    const initialData = {
      ...accountDetails,
      password: 'password',
      mfa_enabled: mfaStatus.enabled !== 0,
      method: checkMethod(mfaStatus),
      ...(accountDetails.image && { image: [{ name: getFileName(accountDetails?.image) }] }),
      phone_code: phoneCode ? countryCodeMap.get(phoneCode) : '',
      phone: parsePhoneFromAPI(accountDetails?.phone)
    };
    return initialData;
  };

  const isLoadingUpdate = isLoadingUserUpdate || isLoadingMfaUpdate || isLoadingPhoneUpdate;
  const isLoadingData = isLoadingUserDetails || isLoadingMfaStatus;

  return (
    <DefaultLayout>
      <InnerWrapper>
        <HeaderComponent headerText='Account settings' isLoading={isLoadingData} />
        {isModalOpen && <VerifyPhoneModal handleClose={handleModalClose} />}
        {!isLoadingData && (
          <StyledCard>
            <Form
              initialValues={transformInitial(accountDetails, mfaStatus)}
              onSubmit={handleSubmit}
              key={JSON.stringify(accountDetails) + JSON.stringify(mfaStatus)}
              render={(formRenderProps: FormRenderProps) => {
                const phoneNumber = formRenderProps.valueGetter('phone');
                const phonePrefix = countryCodeReversedMap.get(
                  formRenderProps.valueGetter('phone_code')
                );
                const phonePrefixCountryCode = formRenderProps.valueGetter('phone_code');
                const phoneFormattedForm = `${phonePrefix}-${phoneNumber}`;
                const phoneVerified =
                  accountDetails?.phone_verified_at && phoneFormattedForm === accountDetails?.phone;
                const isValid =
                  phonePrefixCountryCode &&
                  phonePrefixCountryCode.length > 0 &&
                  phoneNumber.length > 0 &&
                  isValidPhoneNumber(phoneNumber, phonePrefixCountryCode as CountryCode) &&
                  phoneValidatorRequired(phoneNumber).length === 0;

                const phonePrefixIsValid =
                  formRenderProps.valueGetter('phone') !== '' &&
                  formRenderProps.valueGetter('phone_code').length > 0;

                const accountImage = () => {
                  if (
                    formRenderProps.valueGetter('image')?.[0]?.getRawFile &&
                    !formRenderProps.valueGetter('image')?.[0]?.validationErrors
                  ) {
                    return (
                      <img
                        alt={'Account details avatar'}
                        src={URL.createObjectURL(
                          formRenderProps.valueGetter('image')[0].getRawFile()
                        )}
                      />
                    );
                  }

                  return accountDetails.image ? (
                    <img alt={'Account details avatar'} src={accountDetails.image} />
                  ) : (
                    <DefaultAvatar style={{ width: 100 }} />
                  );
                };
                return (
                  <FormElement>
                    <FieldsWrapper>
                      <Image>{accountImage()}</Image>
                      <Fieldset
                        className={'k-form-fieldset'}
                        style={{ margin: 0, justifySelf: 'start' }}>
                        <legend>Profile</legend>
                        <Field
                          data-testid='name'
                          id={'name'}
                          name={'name'}
                          label={'Full name'}
                          component={FormInput}
                        />
                        <Field
                          data-testid='email'
                          id={'email'}
                          name={'email'}
                          label={'E-mail'}
                          component={FormInput}
                          disabled
                        />
                        <PhoneWrapper>
                          {formRenderProps.valueGetter('phone') && (
                            <Field
                              data-testid='phone_code'
                              id={'phone_code'}
                              name={'phone_code'}
                              label={'Country code'}
                              component={FormCountryCode}
                              validator={requiredValidator}
                              isValid={phonePrefixIsValid}
                            />
                          )}
                          <Field
                            data-testid='phone'
                            id={'phone'}
                            name={'phone'}
                            label={
                              <PhoneTooltip
                                verified={phoneVerified}
                                value={formRenderProps.valueGetter('phone')}
                              />
                            }
                            onChange={(e) => {
                              if (!formRenderProps.valueGetter('phone')) {
                                formRenderProps.onChange('method', {
                                  value: MfaOptions.email
                                });
                              }
                            }}
                            component={FormMaskedTextBox}
                            validator={
                              formRenderProps.valueGetter('method') === MfaOptions.sms
                                ? phoneValidatorRequired
                                : phoneValidatorOptional
                            }
                          />
                          {!phoneVerified && formRenderProps.valueGetter('phone') && (
                            <div>
                              <Button
                                data-testid='verify'
                                themeColor={'primary'}
                                type='button'
                                disabled={!isValid || isLoadingUpdate}
                                onClick={() => handlePhoneVerify(phoneFormattedForm)}>
                                Verify
                                {isLoadingUpdate && <LoaderButton />}
                              </Button>
                            </div>
                          )}
                        </PhoneWrapper>
                        <Field
                          data-testid='user-image'
                          id={'image'}
                          name={'image'}
                          label={'User image'}
                          component={FormUpload}
                          restrictions={{
                            allowedExtensions: ['.jpg', '.png', '.gif'],
                            maxFileSize: 5000000 // 5mb in bytes
                          }}
                          hint={'Allowed formats: png / jpg / gif. Max 5mb file.'}
                          optional={true}
                        />
                        <PasswordWrapper>
                          <div style={{ flex: 1 }}>
                            <Field
                              data-testid='password'
                              id={'password'}
                              name={'password'}
                              label={'Password'}
                              disabled
                              component={FormPasswordTextBox}
                            />
                          </div>
                          <Button
                            data-testid='change-password'
                            onClick={goToChangePassword}
                            themeColor={'primary'}
                            type='button'>
                            Change password
                          </Button>
                        </PasswordWrapper>
                      </Fieldset>
                      <Fieldset
                        className={'k-form-fieldset'}
                        style={{ margin: 0, justifySelf: 'start' }}>
                        <legend>Two factor authentication</legend>
                        <Field
                          data-testid='mfa-enabled'
                          id={'mfa_enabled'}
                          name={'mfa_enabled'}
                          component={FormSwitch}
                          label='Enable 2fa authentication'
                          optional={true}
                          className={'k-form-field'}
                        />
                        {formRenderProps.valueGetter('mfa_enabled') === true && (
                          <Field
                            data-testid='mfa-method'
                            id={'method'}
                            name={'method'}
                            label={'2fa method'}
                            component={FormRadioGroup}
                            data={
                              formRenderProps.valueGetter('phone') && phoneVerified
                                ? [
                                    { label: 'Email', value: MfaOptions.email },
                                    { label: 'Text message (sms)', value: MfaOptions.sms }
                                  ]
                                : [{ label: 'Email', value: MfaOptions.email }]
                            }
                          />
                        )}
                      </Fieldset>
                      {/* <Fieldset
                      className={'k-form-fieldset'}
                      style={{ margin: 0, justifySelf: 'start' }}>
                      <legend>Details</legend>
                      <StyledLegendParagraph headerText='MY HANDS'>Hand 1</StyledLegendParagraph>
                      <StyledLegendParagraph headerText='CLINIC'>
                        <LegendButtonWrapper>
                          <span>Miracle Clinic Co.</span>
                          <Button>Details</Button>
                        </LegendButtonWrapper>
                      </StyledLegendParagraph>
                    </Fieldset> */}
                    </FieldsWrapper>
                    <ButtonsWrapper className='k-form-buttons'>
                      <Button
                        onClick={goBack}
                        type='button'
                        data-testid='cancel-save-changes-account'>
                        Cancel
                      </Button>
                      <Button
                        data-testid='save-changes-account'
                        themeColor={'primary'}
                        type='submit'
                        disabled={!formRenderProps.allowSubmit || isLoadingUpdate}>
                        Save changes
                        {isLoadingUpdate && <LoaderButton />}
                      </Button>
                    </ButtonsWrapper>
                  </FormElement>
                );
              }}
            />
          </StyledCard>
        )}
      </InnerWrapper>
    </DefaultLayout>
  );
};
export default AccountDetails;
