import DefaultLayout from '../../layouts/DefaultLayout';
import { useHistory, useLocation } from 'react-router-dom';
import { useChangeUserPhone, useUserUpdate } from '../../hooks/api/useUsers';
import { isNil, omitBy } from 'lodash';
import { UserUpdatePayload } from '../../api/users/users.types';
import { USER_MANAGE } from '../../constants/routes';
import FormContentWrapper from '../../layouts/FormContentWrapper';
import UserManageForm from './UserManageForm';
import { parseCountryCodeFromAPI, parsePhoneFromAPI } from '../../utils/parsePhone';
import { errorNotification, successNotification } from '../../utils/notifications';
import * as Sentry from '@sentry/react';
import { countryCodeReversedMap } from 'utils/definesLocal';

const UserManageEdit = () => {
  const { push } = useHistory();
  const { state: { item: user = undefined } = {} }: any = useLocation();
  const { mutateAsync: editUser, isLoading: isLoadingUpdated } = useUserUpdate();
  const { mutateAsync: updateUserPhone, isLoading: isLoadingPhoneUpdate } = useChangeUserPhone();

  const handleSubmit = async ({
    name = undefined,
    email,
    phone = undefined,
    address1,
    phone_code = undefined
  }: any) => {
    try {
      await editUser(
        omitBy(
          {
            id: user.id,
            name,
            email: user.email === email ? undefined : email,
            address1
          },
          isNil
        ) as UserUpdatePayload
      );
      if (phone === '') {
        phone = undefined;
      }
      const newPhone = `${countryCodeReversedMap.get(phone_code)}-${phone}`;
      if (!phone) {
        await updateUserPhone({ userId: user.id, phone: '0' });
      } else if (user.phone !== newPhone) {
        await updateUserPhone({ userId: user.id, phone: newPhone });
      }
      successNotification('User updated');
      push(USER_MANAGE);
    } catch (error) {
      Sentry.captureException(error);
      errorNotification('Failed updated user');
    }
  };

  return (
    <DefaultLayout>
      <FormContentWrapper title={'User management'}>
        <UserManageForm
          onSubmit={handleSubmit}
          user={{
            ...user,
            phone_code: parseCountryCodeFromAPI(user?.phone),
            phone: parsePhoneFromAPI(user?.phone)
          }}
          isUpdate={true}
          isLoading={isLoadingUpdated || isLoadingPhoneUpdate}
        />
      </FormContentWrapper>
    </DefaultLayout>
  );
};

export default UserManageEdit;
