import React, { useState } from 'react';
import styled from 'styled-components';
import { Dialog } from '@progress/kendo-react-dialogs';
import LegendParagraph from '../LegendParagraph/LegendParagraph';
import { ModesEntry } from '../../api/modes/modes.types';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import {
  allUserFeedbackTypeMap,
  configSettingsNamesMap,
  controlModeMap,
  freezeModeMap,
  genericSwitchingMap,
  gripsGroupsOptionsReversedMap,
  gripSwitchingMap,
  inputDeviceMap,
  inputSiteMap,
  singleElectrodeModeMap,
  speedControlMap,
  startOnStartupMap
} from '../../utils/definesLocal';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import Divider from '../Divider/Divicder';

const columnMargin = '50px';

export enum TypesConfig {
  bool,
  value
}

const OptionsList = styled.ul`
  display: grid;
  grid-gap: 12px;
`;

const StatusWrapper = styled.span`
  margin-right: 16px;
`;

const ValueWrapper = styled.span`
  background-color: ${({ theme }) => theme.palette.configFill};
  border-radius: 5px;
  padding: 5px;
`;

interface StatusIndicatorProps {
  status?: number;
}

const StatusIndicator = styled.span<StatusIndicatorProps>`
  width: 12px;
  height: 12px;
  background-color: ${({ theme, status }) =>
    status === 0 ? theme.palette.red : theme.palette.green};
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
`;

const ConfigItem = ({ header, items }: any) => (
  <>
    <TabHeader>{header}</TabHeader>
    <OptionsList>
      {items.map((item: any) =>
        item.type === TypesConfig.bool ? (
          <span key={item.content}>
            <StatusWrapper>{item.header}:</StatusWrapper> <StatusIndicator status={item.value} />
            {item.content}
          </span>
        ) : (
          <span key={item.content}>
            <StatusWrapper>{item.header}:</StatusWrapper>{' '}
            <ValueWrapper>{item.content}</ValueWrapper>
          </span>
        )
      )}
    </OptionsList>
  </>
);

const DialogWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface InnerWrapperProps {
  readonly slim?: boolean;
}

const InnerWrapper = styled.div<InnerWrapperProps>`
  width: ${({ slim }) => (slim ? 400 : 760)}px;

  p {
    margin: 0 auto 36px auto;
  }
`;

const TabsWrapper = styled.div`
  margin-top: ${columnMargin};
`;

const TabHeader = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.base}px;
  font-weight: bold;
  line-height: 1.5;
  text-align: left;
  color: ${({ theme }) => theme.palette.headerTitle};
  margin-top: 16px;
  margin-bottom: 16px;
`;

const SelectGripWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SelectGripText = styled.div`
  display: inline;
  margin-right: ${(props) => props.theme.dimensions.spacing / 2}px;
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  text-align: center;
  border-collapse: collapse;
  table-layout: fixed;
  border: 1px solid ${({ theme }) => theme.palette.separator};
  border-radius: 5px;

  th,
  td {
    padding: 12px 10px;
    text-align: left;
  }

  th {
    background-color: #f8f9fa;
  }

  td {
    border-top: 1px solid ${({ theme }) => theme.palette.separator};
  }

  caption {
    color: ${({ theme }) => theme.palette.headerTitle};
    border-radius: 5px;
    padding: 10px 0;
    font-weight: bold;
    text-align: left;
  }
`;

export const SectionWrapper = styled.div`
  margin: 15px 0;
`;

type MessageModalProps = {
  handleClose: any;
  config: any;
  configTransformed?: any;
  mode: ModesEntry;
};

const ConfigDetailsMap = {
  gripsPositions: (
    config: any,
    configTransformed: any,
    selectedGrip: string,
    setSelectedGrip: any,
    gripNumber: number
  ) => (
    <>
      <TabHeader>{configSettingsNamesMap.get('gripsPositions')}</TabHeader>
      <SelectGripWrapper>
        <SelectGripText>Grip</SelectGripText>
        <ComboBox
          data={configTransformed?.gripsTransformed}
          value={selectedGrip}
          clearButton={false}
          onChange={(e) => setSelectedGrip(e.target.value)}
        />
      </SelectGripWrapper>
      <SectionWrapper>
        <Table>
          <tr>
            <th style={{ width: '60%' }}>Finger</th>
            <th>Initial</th>
            <th>Limit</th>
          </tr>
          <tr>
            <td>Thumb</td>
            <td>{config?.gripsPositions?.[gripNumber]?.initial?.[0]}</td>
            <td>{config?.gripsPositions?.[gripNumber]?.limit?.[0]}</td>
          </tr>
          <tr>
            <td>Index</td>
            <td>{config?.gripsPositions?.[gripNumber]?.initial?.[1]}</td>
            <td>{config?.gripsPositions?.[gripNumber]?.limit?.[1]}</td>
          </tr>
          <tr>
            <td>Middle</td>
            <td>{config?.gripsPositions?.[gripNumber]?.initial?.[2]}</td>
            <td>{config?.gripsPositions?.[gripNumber]?.limit?.[2]}</td>
          </tr>
          <tr>
            <td>Ring</td>
            <td>{config?.gripsPositions?.[gripNumber]?.initial?.[3]}</td>
            <td>{config?.gripsPositions?.[gripNumber]?.limit?.[3]}</td>
          </tr>
          <tr>
            <td>Pinky</td>
            <td>{config?.gripsPositions?.[gripNumber]?.initial?.[4]}</td>
            <td>{config?.gripsPositions?.[gripNumber]?.limit?.[4]}</td>
          </tr>
        </Table>
      </SectionWrapper>
    </>
  ),
  gripPairsConfig: (configTransformed: any) => (
    <>
      <TabHeader>{configSettingsNamesMap.get('gripPairsConfig')}</TabHeader>
      <SectionWrapper>
        <Table>
          <caption>Opposed</caption>
          <tr>
            <th colSpan={2} style={{ width: '50%' }}>
              Primary
            </th>
            <th colSpan={2} style={{ width: '50%' }}>
              Secondary
            </th>
          </tr>
          <tr>
            <td>{configTransformed?.gripPairsConfig[0]}</td>
            <td>{configTransformed?.gripPairsConfig[1]}</td>
            <td>{configTransformed?.gripPairsConfig[2]}</td>
            <td>{configTransformed?.gripPairsConfig[3]}</td>
          </tr>
        </Table>
        <Divider margin={20} />
        <Table>
          <caption>Non-opposed</caption>
          <tr>
            <th colSpan={2}>Primary</th>
            <th colSpan={2}>Secondary</th>
          </tr>
          <tr>
            <td colSpan={1}>{configTransformed?.gripPairsConfig[4]}</td>
            <td colSpan={1}>{configTransformed?.gripPairsConfig[5]}</td>
            <td colSpan={1}>{configTransformed?.gripPairsConfig[6]}</td>
            <td colSpan={1}>{configTransformed?.gripPairsConfig[7]}</td>
          </tr>
        </Table>
      </SectionWrapper>
    </>
  ),
  gripSequentialConfig: (configTransformed: any) => (
    <>
      <TabHeader>{configSettingsNamesMap.get('gripSequentialConfig')}</TabHeader>
      <SectionWrapper>
        <Table>
          <caption>Opposed</caption>
          <tr>
            <td>{configTransformed?.gripSequentialConfig[0]}</td>
            <td>{configTransformed?.gripSequentialConfig[1]}</td>
            <td>{configTransformed?.gripSequentialConfig[2]}</td>
            <td>{configTransformed?.gripSequentialConfig[3]}</td>
            <td>{configTransformed?.gripSequentialConfig[4]}</td>
          </tr>
        </Table>
        <Divider margin={20} />
        <Table>
          <caption>Non-opposed</caption>
          <tr>
            <td>{configTransformed?.gripSequentialConfig[6]}</td>
            <td>{configTransformed?.gripSequentialConfig[7]}</td>
            <td>{configTransformed?.gripSequentialConfig[8]}</td>
            <td>{configTransformed?.gripSequentialConfig[9]}</td>
            <td>{configTransformed?.gripSequentialConfig[10]}</td>
          </tr>
        </Table>
      </SectionWrapper>
    </>
  ),
  inputSite: (config: any) => (
    <ConfigItem
      header={configSettingsNamesMap.get('inputSite')}
      items={[
        {
          header: 'Value',
          content: `${inputSiteMap.get(config?.inputSite?.[0])}`
        }
      ]}
    />
  ),
  inputDevice: (config: any) => (
    <ConfigItem
      header={configSettingsNamesMap.get('inputDevice')}
      items={[
        {
          header: 'Value',
          content: `${inputDeviceMap.get(config?.inputDevice?.[0])}`
        }
      ]}
    />
  ),
  speedControlStrategy: (config: any) => (
    <ConfigItem
      header={configSettingsNamesMap.get('speedControlStrategy')}
      items={[
        {
          header: 'Value',
          content: `${speedControlMap.get(config?.speedControlStrategy?.[0])}`
        }
      ]}
    />
  ),
  gripSwitchingMode: (config: any) => (
    <ConfigItem
      header={configSettingsNamesMap.get('gripSwitchingMode')}
      items={[
        {
          header: 'Value',
          content: `${gripSwitchingMap.get(config?.gripSwitchingMode?.[0])}`
        }
      ]}
    />
  ),
  controlMode: (config: any) => (
    <ConfigItem
      header={configSettingsNamesMap.get('controlMode')}
      items={[
        {
          header: 'Value',
          content: `${controlModeMap.get(config?.controlMode?.[0])}`
        }
      ]}
    />
  ),
  softGrip: (config: any) => (
    <ConfigItem
      header={configSettingsNamesMap.get('softGrip')}
      items={[
        {
          header: 'Status',
          content: genericSwitchingMap.get(config?.softGrip?.[0]),
          type: TypesConfig.bool,
          value: config?.softGrip?.[0]
        },
        { header: 'Value', content: `${config?.fingerStrength?.[1]}` }
      ]}
    />
  ),
  fingerStrength: (config: any) => (
    <ConfigItem
      header={configSettingsNamesMap.get('fingerStrength')}
      items={[{ header: 'Value', content: `${config?.fingerStrength?.[1]}` }]}
    />
  ),
  autoGrasp: (config: any) => (
    <ConfigItem
      header={configSettingsNamesMap.get('autoGrasp')}
      items={[
        {
          header: 'Status',
          content: `${genericSwitchingMap.get(config?.autoGrasp?.[0])}`,
          type: TypesConfig.bool,
          value: config?.autoGrasp?.[0]
        },
        { header: 'Value', content: `${config?.autoGrasp?.[1]}` }
      ]}
    />
  ),
  holdOpen: (config: any) => (
    <ConfigItem
      header={configSettingsNamesMap.get('holdOpen')}
      items={[
        { header: 'Primary hold open time', content: `${config?.holdOpen?.[0]} ms` },
        { header: 'Secondary hold open time', content: `${config?.holdOpen?.[1]} ms` }
      ]}
    />
  ),
  pulseTimings: (config: any) => (
    <ConfigItem
      header={configSettingsNamesMap.get('pulseTimings')}
      items={[
        { header: 'Min. pulse time', content: `${config?.pulseTimings?.[0]} ms` },
        { header: 'Max. pulse time', content: `${config?.pulseTimings?.[1]} ms` },
        {
          header: 'Min. time between pulses',
          content: `${config?.pulseTimings?.[2]} ms`
        },
        {
          header: 'Max. time between pulses',
          content: `${config?.pulseTimings?.[3]} ms`
        }
      ]}
    />
  ),
  coContractionTimings: (config: any) => (
    <ConfigItem
      header={configSettingsNamesMap.get('coContractionTimings')}
      items={[
        {
          header: 'Long co-contraction time',
          content: `${config?.coContractionTimings?.[0]} ms`
        },
        {
          header: 'Signal rise offset time',
          content: `${config?.coContractionTimings?.[1]} ms`
        }
      ]}
    />
  ),
  batteryBeep: (config: any) => (
    <ConfigItem
      header={configSettingsNamesMap.get('batteryBeep')}
      items={[
        {
          header: 'Alarm trigger level',
          content: `${config?.batteryBeep[0]} mV`
        },
        {
          header: 'Status',
          content: `${genericSwitchingMap.get(config.batteryBeep[1])}`,
          type: TypesConfig.bool,
          value: config?.batteryBeep?.[1]
        }
      ]}
    />
  ),
  freezeModeEmg: (config: any) => (
    <ConfigItem
      header={configSettingsNamesMap.get('freezeModeEmg')}
      items={[
        {
          header: 'Status',
          content: `${freezeModeMap.get(config?.freezeModeEmg[2])}`,
          type: TypesConfig.bool,
          value: config?.freezeModeEmg?.[2]
        },
        {
          header: 'Threshold closing',
          content: `${config?.freezeModeEmg[0]}`
        },
        {
          header: 'Threshold opening',
          content: `${config?.freezeModeEmg[1]}`
        }
      ]}
    />
  ),
  generalHandSettings: (config: any) => (
    <ConfigItem
      header={configSettingsNamesMap.get('generalHandSettings')}
      items={[
        {
          header: 'Status',
          content: `${startOnStartupMap.get(config?.generalHandSettings[2])}`,
          type: TypesConfig.bool,
          value: !config?.generalHandSettings?.[2]
        }
      ]}
    />
  ),
  emgSpike: (config: any) => (
    <ConfigItem
      header={configSettingsNamesMap.get('emgSpike')}
      items={[
        {
          header: 'Status',
          content: `${genericSwitchingMap.get(config?.emgSpike?.[0])}`,
          type: TypesConfig.bool,
          value: config?.emgSpike?.[0]
        },
        { header: 'Value', content: `${config?.emgSpike?.[1]} ms` }
      ]}
    />
  ),
  emgGains: (config: any) => (
    <ConfigItem
      header={configSettingsNamesMap.get('emgGains')}
      items={[
        { header: 'Extension', content: `${config?.emgGains?.[0]}%` },
        { header: 'Flexion', content: `${config?.emgGains?.[1]}%` }
      ]}
    />
  ),
  emgThresholds: (config: any) => (
    <ConfigItem
      header={configSettingsNamesMap.get('emgThresholds')}
      items={[
        {
          header: 'CS extension thresholds',
          content: `${config?.emgThresholds?.[0]}`
        },
        {
          header: 'CS flexion thresholds',
          content: `${config?.emgThresholds?.[1]}`
        },
        {
          header: 'Threshold control open value',
          content: `${config?.emgThresholds?.[2]}`
        },
        {
          header: 'Proportional control open second value',
          content: `${config?.emgThresholds?.[3]}`
        },
        {
          header: 'Proportional control open third value',
          content: `${config?.emgThresholds?.[4]}`
        },
        {
          header: 'Threshold control close value',
          content: `${config?.emgThresholds?.[5]}`
        },
        {
          header: 'Proportional control close second value',
          content: `${config?.emgThresholds?.[6]}`
        },
        {
          header: 'Proportional control close third value',
          content: `${config?.emgThresholds?.[7]}`
        },
        {
          header: 'Soft-grip open threshold second value',
          content: `${config?.emgThresholds?.[8]}`
        },
        {
          header: 'Soft-grip close threshold second value',
          content: `${config?.emgThresholds?.[9]}`
        }
      ]}
    />
  ),
  buzzingVolumeSettings: (config: any) => (
    <ConfigItem
      header={configSettingsNamesMap.get('buzzingVolumeSettings')}
      items={[
        {
          header: 'Volume',
          content: `${Math.round((config.buzzingVolumeSettings[0] / 255) * 100)} %`
        }
      ]}
    />
  ),
  followingGrip: (config: any) => (
    <ConfigItem
      header={configSettingsNamesMap.get('followingGrip')}
      items={[
        {
          header: 'Status',
          content: `${genericSwitchingMap.get(config.followingGrip[0])}`,
          type: TypesConfig.bool,
          value: config?.followingGrip?.[0]
        }
      ]}
    />
  ),
  freezeModeEmgSettings: (config: any) => (
    <ConfigItem
      header={configSettingsNamesMap.get('freezeModeEmgSettings')}
      items={[
        { header: 'Threshold opening', content: `${config.freezeModeEmgSettings[0]}` },
        { header: 'Threshold closing', content: `${config.freezeModeEmgSettings[1]}` },
        { header: 'Stage 1 time', content: `${config.freezeModeEmgSettings[2]} ms` },
        { header: 'Stage 2 time', content: `${config.freezeModeEmgSettings[3]} ms` }
      ]}
    />
  ),
  singleElectrodeMode: (config: any) => (
    <ConfigItem
      header={configSettingsNamesMap.get('singleElectrodeMode')}
      items={[
        {
          header: 'Mode',
          content: `${singleElectrodeModeMap.get(config.singleElectrodeMode[0])}`
        }
      ]}
    />
  ),
  singleElectrodeModeSettings: (config: any) => (
    <ConfigItem
      header={configSettingsNamesMap.get('singleElectrodeModeSettings')}
      items={[
        {
          header: 'Start point signal thresholds',
          content: `${config.singleElectrodeModeSettings[0]}`
        },
        {
          header: 'Window time',
          content: `${config.singleElectrodeModeSettings[1]} ms`
        },
        {
          header: 'Primary hold open time',
          content: `${config.singleElectrodeModeSettings[2]} ms`
        },
        {
          header: 'Secondary hold open time',
          content: `${config.singleElectrodeModeSettings[3]} ms`
        }
      ]}
    />
  ),
  userFeedbackType: (config: any) => (
    <ConfigItem
      header={configSettingsNamesMap.get('userFeedbackType')}
      items={[
        {
          header: 'Buzzer',
          content: `${allUserFeedbackTypeMap.get(config.userFeedbackType[0])}`
        }
      ]}
    />
  )
};

const ConfigDetailsModal = ({
  config,
  configTransformed,
  mode,
  handleClose
}: MessageModalProps) => {
  const [selected, setSelected] = useState(0);
  const [selectedGrip, setSelectedGrip] = useState('Rest opp');
  const gripNumber: any = gripsGroupsOptionsReversedMap.get(selectedGrip);

  const handleSelect = (e: any) => {
    setSelected(e.selected);
  };

  return (
    <Dialog title={'Details'} onClose={handleClose} height={700}>
      <DialogWrapper>
        <InnerWrapper>
          <LegendParagraph headerText={'MODE NAME'}>
            <div>{mode.name}</div>
          </LegendParagraph>
          <LegendParagraph headerText={'CONFIGURATION'}>
            <TabsWrapper>
              <TabStrip selected={selected} onSelect={handleSelect}>
                <TabStripTab title='EMG Settings'>
                  {Boolean(config?.emgSpike) && ConfigDetailsMap.emgSpike(config)}
                  {Boolean(config?.emgGains) && ConfigDetailsMap.emgGains(config)}
                  {Boolean(config?.emgThresholds) && ConfigDetailsMap.emgThresholds(config)}
                </TabStripTab>
                <TabStripTab title='Grips configuration'>
                  {config?.gripsPositions &&
                    ConfigDetailsMap.gripsPositions(
                      config,
                      configTransformed,
                      selectedGrip,
                      setSelectedGrip,
                      gripNumber
                    )}
                  {configTransformed?.gripPairsConfig &&
                    ConfigDetailsMap.gripPairsConfig(configTransformed)}
                  {configTransformed?.gripSequentialConfig &&
                    ConfigDetailsMap.gripSequentialConfig(configTransformed)}
                </TabStripTab>
                <TabStripTab title='Prosthesis settings'>
                  {Boolean(config?.inputSite) && ConfigDetailsMap.inputSite(config)}
                  {Boolean(config?.inputDevice) && ConfigDetailsMap.inputDevice(config)}
                  {Boolean(config?.speedControlStrategy) &&
                    ConfigDetailsMap.speedControlStrategy(config)}
                  {Boolean(config?.gripSwitchingMode) && ConfigDetailsMap.gripSwitchingMode(config)}
                  {Boolean(config?.controlMode) && ConfigDetailsMap.controlMode(config)}
                  {Boolean(config?.softGrip?.[0]) && ConfigDetailsMap.softGrip(config)}
                  {Boolean(config?.fingerStrength) && ConfigDetailsMap.fingerStrength(config)}
                  {Boolean(config?.autoGrasp) && ConfigDetailsMap.autoGrasp(config)}
                  {Boolean(config?.holdOpen) && ConfigDetailsMap.holdOpen(config)}
                  {Boolean(config?.pulseTimings) && ConfigDetailsMap.pulseTimings(config)}
                  {Boolean(config?.coContractionTimings) &&
                    ConfigDetailsMap.coContractionTimings(config)}
                  {Boolean(config?.batteryBeep) && ConfigDetailsMap.batteryBeep(config)}
                  {Boolean(config?.buzzingVolumeSettings) &&
                    ConfigDetailsMap.buzzingVolumeSettings(config)}
                  {Boolean(config?.followingGrip) && ConfigDetailsMap.followingGrip(config)}
                  {Boolean(config?.freezeModeEmg) && ConfigDetailsMap.freezeModeEmg(config)}
                  {Boolean(config?.freezeModeEmgSettings) &&
                    ConfigDetailsMap.freezeModeEmgSettings(config)}
                  {Boolean(config?.userFeedbackType) && ConfigDetailsMap.userFeedbackType(config)}
                  {Boolean(config?.generalHandSettings) &&
                    ConfigDetailsMap.generalHandSettings(config)}
                </TabStripTab>
              </TabStrip>
            </TabsWrapper>
          </LegendParagraph>
        </InnerWrapper>
      </DialogWrapper>
    </Dialog>
  );
};

export default ConfigDetailsModal;
