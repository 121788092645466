import { useState, useRef } from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';
import { COMPANIES_QUERY_KEY, useCompanyDelete } from '../../hooks/api/useCompanies';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { ActionButtonError, ActionButtonPrimary } from '../../components/ActionButton/ActionButton';
import { SvgIcon } from '@progress/kendo-react-common';
import * as svgIcons from '@progress/kendo-svg-icons';
import CompaniesTableHeader from '../../components/Table/CompaniesTableHeader';
import DefaultLayout from '../../layouts/DefaultLayout';
import { useHistory } from 'react-router-dom';
import ConfirmDeleteModal, { ModalMessageDelete } from '../../components/Modals/ConfirmDeleteModal';
import { useModal } from '../../hooks/api/useModal';
import { ActionButtonsWrapper } from '../../components/Table/styles';
import { getTableSize, parseAddress } from '../../utils/tableHelpers';
import { DetailsLink } from '../../components/Links/Links';
import { COMPANY } from '../../constants/routes';
import { useQueryClient } from '@tanstack/react-query';
import { ExternalLink } from './styled';
import { DEBOUNCE_SEARCH_TIME } from '../../constants/search';
import { TableDataLoader } from '../../components/TableDataLoader/TableDataLoader';
import { getCompanies } from '../../api/companies/companies';
import { CompanyExtendOptions } from '../../api/companies/companies.types';

const CompaniesListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const sortMap: any = {
  name: 'company_name',
  ['country.name']: 'country_name'
};

const CompaniesList = () => {
  const [searchTerm, setSearchTerm] = useState<any>('');
  const queryClient = useQueryClient();
  const [selectedCompany, setSelectedCompany] = useState<any>();
  const [companies, setCompanies] = useState({
    data: [],
    total: 10
  });
  const [dataState, setDataState] = useState({
    take: 12,
    skip: 0
  });
  const { mutateAsync: deleteCompany, isLoading: isLoadingDelete } = useCompanyDelete();
  const tableRef = useRef(null) as any;
  const history = useHistory();
  const {
    isOpen: isModalOpen,
    handleOpen: handleModalOpen,
    handleClose: handleModalClose
  } = useModal();

  const dataReceived = (companies: any) => {
    setCompanies({
      total: companies?.total,
      data: companies?.data.map((company: any) => ({
        ...company,
        address: parseAddress({
          address1: company?.address1,
          address2: company?.address2,
          postal_code: company?.postal_code
        })
      }))
    });
  };

  const dataStateChange = (e: any) => {
    setDataState(e.dataState);
  };

  const handleDelete = (company: any) => {
    setSelectedCompany(company);
    handleModalOpen();
  };

  const deleteComp = async () => {
    if (selectedCompany) {
      await deleteCompany(selectedCompany.id);
      handleModalClose();
      await queryClient.invalidateQueries([COMPANIES_QUERY_KEY]);
    }
  };

  return (
    <DefaultLayout>
      <CompaniesListWrapper>
        <CompaniesTableHeader
          headerText={'Companies list'}
          search
          setSearchTerm={debounce(setSearchTerm, DEBOUNCE_SEARCH_TIME)}
          addNew
        />
        {isModalOpen && (
          <ConfirmDeleteModal
            handleClose={handleModalClose}
            handleAccept={deleteComp}
            isLoading={isLoadingDelete}
            message={
              <ModalMessageDelete
                name={selectedCompany?.name}
                id={selectedCompany?.id}
                text='Do you want to delete company'
              />
            }
          />
        )}
        <Grid
          data={companies}
          {...dataState}
          onDataStateChange={dataStateChange}
          pageable={{ buttonCount: 4 }}
          sortable={true}
          data-testid='companies-table'
          selectable={{
            enabled: true
          }}>
          <Column
            field='name'
            title='Company name'
            cell={(e: any) => (
              <td data-testid={`company-table-cell-${e.dataIndex}`}>
                <DetailsLink to={`${COMPANY}/${e.dataItem.id}`}>{e.dataItem.name}</DetailsLink>
              </td>
            )}
          />
          <Column field='agent' title='Agent' sortable={false} />
          <Column field='address' title='Address' sortable={false} />
          <Column field='country.name' title='Country' />
          <Column field='city' title='City' sortable={false} />
          <Column
            field='website'
            title='Website'
            cell={(e: any) => (
              <td>
                <ExternalLink target='_blank' href={e.dataItem.website}>
                  {e.dataItem.website}
                </ExternalLink>
              </td>
            )}
            sortable={false}
          />
          <Column field='email' title='E-mail address' sortable={false} />
          <Column field='phone' title='Phone' sortable={false} />
          <Column
            title='Actions'
            width={getTableSize(2)}
            cell={(e: any) => (
              <ActionButtonsWrapper>
                <ActionButtonPrimary
                  data-testid='button-edit'
                  onClick={() =>
                    history.push({ pathname: '/company/edit', state: { detail: e.dataItem } })
                  }>
                  <SvgIcon icon={svgIcons['pencilIcon']} size='medium' />
                </ActionButtonPrimary>
                <ActionButtonError
                  data-testid='button-delete'
                  onClick={() => handleDelete(e.dataItem)}>
                  <SvgIcon icon={svgIcons['trashIcon']} size='medium' />
                </ActionButtonError>
              </ActionButtonsWrapper>
            )}
          />
        </Grid>
        <TableDataLoader
          dataState={dataState}
          onDataReceived={dataReceived}
          setDataState={setDataState}
          sortMap={sortMap}
          additionalQueryParams={{
            extend: [
              CompanyExtendOptions.country,
              CompanyExtendOptions.state,
              CompanyExtendOptions.tags
            ],
            search: searchTerm
          }}
          getFetch={getCompanies}
          errorMessage={'Failed to fetch companies'}
          ref={tableRef}
          queryKey={COMPANIES_QUERY_KEY}
        />
      </CompaniesListWrapper>
    </DefaultLayout>
  );
};

export default CompaniesList;
