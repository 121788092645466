import { AxiosResponse } from 'axios';

import api from '../utils/apiClient';
import {
  LoginParams,
  SendMfaCodePayload,
  UserDetails,
  UserLoginPayload,
  VerifyMfaCodePayload,
  ChangePasswordPayload,
  ChangePasswordWithTokenPayload,
  SetMfaStatusPayload,
  VerifyPhonePayload,
  VerifyPasswordChangeTokenPayload,
  CheckMfaPayload
} from './authentication.types';

export const ABOUT_ME = 'api/me';
export const LOGIN_USER = 'login';
export const LOGOUT = 'api/logout';
export const REFRESH_TOKEN = 'api/token/refresh';
export const SEND_MFA_CODE = 'api/mfa/send';
export const VERIFY_MFA_CODE = 'api/mfa/verify';
export const VERIFY_PHONE = 'api/mfa/phone/verify';
export const CHANGE_PASSWORD = 'api/password/change';
export const REQUEST_PASSWORD_RESET = 'api/password/reset';
export const CHANGE_PASSWORD_WITH_TOKEN = 'api/password/reset/change';
export const VERIFY_PASSWORD_CHANGE_TOKEN = 'api/password/reset/verify';
export const SET_MFA_STATUS = 'api/mfa/status';
export const AUTH_ABLY_USER = 'api/chat/authorize-user';
export const CSRF_COOKIE = 'sanctum/csrf-cookie';

const withCredentials = {
  withCredentials: true
};

export const loginUserSPA = (data: LoginParams): Promise<any> =>
  api.get(CSRF_COOKIE, withCredentials).then(() => loginUser(data));

export const loginUser = (data: LoginParams): Promise<any> =>
  api
    .post<UserLoginPayload>(LOGIN_USER, data, withCredentials)
    .then((res: AxiosResponse) => res.data);

export const refreshTokenApi = (): Promise<any> =>
  api
    .post<UserLoginPayload>(REFRESH_TOKEN, {}, withCredentials)
    .then((res: AxiosResponse) => res.data);

export const fetchUserData = (): Promise<any> =>
  api.get<UserDetails>(ABOUT_ME, withCredentials).then((res: any) => res.data);

export const sendMfaCode = (data: SendMfaCodePayload): Promise<any> =>
  api.post<SendMfaCodePayload>(SEND_MFA_CODE, data, withCredentials).then((res: any) => res.data);

export const logoutAPI = (): Promise<any> =>
  api.post(LOGOUT, {}, withCredentials).then((res: any) => res.data);

export const verifyMfaCode = (data: VerifyMfaCodePayload): Promise<any> =>
  api
    .post<VerifyMfaCodePayload>(VERIFY_MFA_CODE, data, withCredentials)
    .then((res: any) => res.data);

export const changePassword = (data: ChangePasswordPayload): Promise<any> =>
  api
    .post<ChangePasswordPayload>(CHANGE_PASSWORD, data, withCredentials)
    .then((res: any) => res.data);

export const changePasswordWithToken = (data: ChangePasswordWithTokenPayload): Promise<any> =>
  api.get(CSRF_COOKIE, withCredentials).then(() => {
    api
      .post<ChangePasswordWithTokenPayload>(CHANGE_PASSWORD_WITH_TOKEN, data, withCredentials)
      .then((res: any) => res.data);
  });

export const verifyPasswordChangeToken = (data: VerifyPasswordChangeTokenPayload): Promise<any> =>
  api.get(CSRF_COOKIE, withCredentials).then(() => {
    return api
      .post<VerifyPasswordChangeTokenPayload>(VERIFY_PASSWORD_CHANGE_TOKEN, data, withCredentials)
      .then((res: any) => res.data);
  });

export const resetPassword = (email: string): Promise<any> =>
  api.get(CSRF_COOKIE, withCredentials).then(() => {
    api.post(REQUEST_PASSWORD_RESET, { email }, withCredentials).then((res: any) => res.data);
  });

export const setMfaStatus = (data: SetMfaStatusPayload): Promise<any> =>
  api.get(CSRF_COOKIE, withCredentials).then(() => {
    return api
      .post<SetMfaStatusPayload>(SET_MFA_STATUS, data, withCredentials)
      .then((res: any) => res.data);
  });

export const verifyPhone = (data: VerifyPhonePayload): Promise<any> =>
  api.post<VerifyPhonePayload>(VERIFY_PHONE, data, withCredentials).then((res: any) => res.data);

export const authAblyClient = (): Promise<any> =>
  api.get(AUTH_ABLY_USER, withCredentials).then((res: any) => res.data);

export const checkMfaStatus = (): Promise<any> =>
  api.get<CheckMfaPayload>(SET_MFA_STATUS, withCredentials).then((res: any) => res.data);
