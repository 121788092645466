import { useRef, useState } from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TableHeader from '../../components/Table/TableHeader';
import { Button } from '@progress/kendo-react-buttons';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { useHistory } from 'react-router-dom';
import { Grid } from '@progress/kendo-react-grid';
import { ActionButtonError, ActionButtonPrimary } from '../../components/ActionButton/ActionButton';
import { SvgIcon } from '@progress/kendo-react-common';
import * as svgIcons from '@progress/kendo-svg-icons';
import { CLINICIAN_ADD, CLINICIANS, COMPANY, LOCATION } from '../../constants/routes';
import { USERS_QUERY_KEY, useUserDelete } from '../../hooks/api/useUsers';
import {
  RoleEnum,
  UserEntry,
  UserExtendOptions,
  UsersQueryParams
} from '../../api/users/users.types';
import { useQueryClient } from '@tanstack/react-query';
import { CliniciansListWrapper } from './styled';
import { ActionButtonsWrapper } from '../../components/Table/styles';
import { getTableSize, parseAddress } from '../../utils/tableHelpers';
import { TableLink } from '../../components/Links/Links';
import { useModal } from '../../hooks/api/useModal';
import ConfirmDeleteModal, { ModalMessageDelete } from '../../components/Modals/ConfirmDeleteModal';
import { DEBOUNCE_SEARCH_TIME } from '../../constants/search';
import { debounce } from 'lodash';
import { userHasPermissions } from '../../utils/permissionUtils';
import useUserData from '../../hooks/useUserData';
import { rolesNamesMap } from '../../utils/definesLocal';
import { TableDataLoader } from '../../components/TableDataLoader/TableDataLoader';
import { getUsers } from '../../api/users/users';
import { SwitchWrapper } from '../../components/SwitchWrapper/CustomSwitch';
import { Switch } from '@progress/kendo-react-inputs';
import useUserPatients from '../../hooks/useUserPatients';
import { extractUserFromPermission } from '../../hoc/useCanAccess';
import { myTheme } from '../../theme/theme';

const mapData = (clinicians?: UserEntry[]) => {
  if (!clinicians) {
    return [];
  }
  return clinicians.map((item: UserEntry) => {
    return {
      id: item.id,
      name: item.name,
      email: item.email,
      password: item.password,
      phone: item.phone,
      location: item.location,
      image: item.image,
      permissions: item.permissions,
      role: { ...item.roles?.[0], parsedName: rolesNamesMap.get(item.roles?.[0].name) },
      roles: item.roles,
      address: parseAddress({
        address1: item.location?.address1,
        address2: item.location?.address2,
        postal_code: item.location?.postal_code,
        city: item.location?.city
      }),
      has_access_to_my_patients: item?.has_access_to_my_patients
    };
  });
};

const sortMap: any = {
  name: 'user_name',
  ['location.company.name']: 'company_name',
  ['location.name']: 'location_name',
  has_access_to_my_patients: 'has_access_to_my_patients'
};

interface CliniciansListProps {
  title: string;
  role: RoleEnum;
  searchText: string;
  buttonText: string;
}

const CliniciansList = ({ title, role, searchText, buttonText }: CliniciansListProps) => {
  const [searchTerm, setSearchTerm] = useState<any>('');
  const { data: userData, rolesByName, me } = useUserData();
  const [selectedClinician, setSelectedClinician] = useState<UserEntry>();
  const [onlyMyLocation, setOnlyMyLocation] = useState<boolean | null>(true);
  const { push } = useHistory();
  const alternativeAlliedView =
    userHasPermissions([RoleEnum.clinician], rolesByName) && role === RoleEnum.clinicianSupport;
  const patients = useUserPatients(me.id);
  const queryClient = useQueryClient();
  const queryParams: UsersQueryParams = {
    extend: [
      UserExtendOptions.location,
      UserExtendOptions.roles,
      UserExtendOptions.countryLocation,
      UserExtendOptions.locationCompany,
      UserExtendOptions.permissions
    ],
    roles: role,
    search: searchTerm,
    ...(userHasPermissions([RoleEnum.clinicAdmin], rolesByName) && {
      company: userData?.location?.company_id
    }),
    ...(alternativeAlliedView && onlyMyLocation && { location: userData?.location?.id })
  };

  const tableRef = useRef(null) as any;
  const [clinicians, setClinicians] = useState<any>({
    data: [],
    total: 10
  });
  const [dataState, setDataState] = useState<any>({
    take: 12,
    skip: 0
  });
  const {
    isOpen: isModalOpen,
    handleOpen: handleModalOpen,
    handleClose: handleModalClose
  } = useModal();

  const { mutateAsync: deleteUser, isLoading: isLoadingDelete } = useUserDelete();

  const dataReceived = (clinicians: any) => {
    setClinicians({ data: mapData(clinicians.data), total: clinicians.total });
  };

  const dataStateChange = (e: any) => {
    setDataState(e.dataState);
  };

  const handleDelete = async () => {
    if (selectedClinician) {
      await deleteUser(selectedClinician.id);
      handleModalClose();
      await queryClient.invalidateQueries([USERS_QUERY_KEY]);
    }
  };

  const handleDeleteModal = (clinician: UserEntry) => {
    setSelectedClinician(clinician);
    handleModalOpen();
  };

  return (
    <DefaultLayout>
      <CliniciansListWrapper>
        <TableHeader
          actions={
            <>
              <Button
                data-testid='button-add'
                onClick={() => push({ pathname: CLINICIAN_ADD, state: { role } })}
                themeColor={'primary'}
                icon={'plus'}>
                {buttonText}
              </Button>
            </>
          }
          actionsFilters={
            alternativeAlliedView && (
              <SwitchWrapper label={'Only in my location'}>
                <Switch
                  checked={!!onlyMyLocation}
                  onChange={() => setOnlyMyLocation((prev) => !prev)}
                />
              </SwitchWrapper>
            )
          }
          search={true}
          searchPlaceholder={searchText}
          headerText={title}
          setSearchTerm={debounce(setSearchTerm, DEBOUNCE_SEARCH_TIME)}
        />
        {isModalOpen && (
          <ConfirmDeleteModal
            handleClose={handleModalClose}
            handleAccept={handleDelete}
            isLoading={isLoadingDelete}
            message={
              <ModalMessageDelete
                name={selectedClinician!.name}
                id={selectedClinician!.id}
                text={`Do you want to delete ${rolesNamesMap.get(role)}`}
              />
            }
          />
        )}
        <Grid
          data={clinicians}
          {...dataState}
          onDataStateChange={dataStateChange}
          sortable={true}
          pageable={{ buttonCount: 4 }}
          data-testid='clinicians-table'
          selectable={{
            enabled: true
          }}>
          <Column
            field='name'
            title='Name'
            cell={(e: any) => (
              <td data-testid={`clinician-table-cell-${e.dataIndex}`}>
                <TableLink to={`${CLINICIANS}/${e.dataItem.id}`}>{e.dataItem.name}</TableLink>
              </td>
            )}
          />
          {alternativeAlliedView && (
            <Column field='email' title='Email' cell={(e: any) => <td>{e.dataItem.email}</td>} />
          )}
          {!alternativeAlliedView && (
            <Column
              field='location.company.name'
              title='Company'
              cell={(e: any) => (
                <td>
                  <TableLink to={`${COMPANY}/${e.dataItem?.location?.company?.id}`}>
                    {e.dataItem?.location?.company?.name}
                  </TableLink>
                </td>
              )}
            />
          )}
          <Column
            field='location.name'
            title='Location'
            cell={(e: any) => (
              <td>
                <TableLink to={`${LOCATION}/${e.dataItem?.location?.id}`}>
                  {e.dataItem?.location?.name}
                </TableLink>
              </td>
            )}
          />
          {alternativeAlliedView && (
            <Column
              field='has_access_to_my_patients'
              title='Has access to my patients?'
              cell={(e: any) => (
                <td>
                  {e.dataItem?.has_access_to_my_patients === 1 ? (
                    <span style={{ color: myTheme.palette.success2, fontWeight: 'bold' }}>Yes</span>
                  ) : (
                    <span style={{ color: myTheme.palette.error2, fontWeight: 'bold' }}>No</span>
                  )}
                </td>
              )}
            />
          )}
          {!alternativeAlliedView && <Column field='address' title='Address' sortable={false} />}
          {!alternativeAlliedView && (
            <Column field='location.company.country.name' title='Country' sortable={false} />
          )}
          <Column
            title='Actions'
            width={getTableSize(2)}
            cell={(e: any) => (
              <ActionButtonsWrapper>
                <ActionButtonPrimary
                  data-testid='button-edit'
                  onClick={() =>
                    push(`${CLINICIANS}/edit/`, {
                      item: e.dataItem,
                      origin
                    })
                  }>
                  <SvgIcon icon={svgIcons['pencilIcon']} size='medium' />
                </ActionButtonPrimary>
                {userHasPermissions([RoleEnum.clinician, rolesByName]) && (
                  <ActionButtonError
                    onClick={() => handleDeleteModal(e.dataItem)}
                    data-testid='button-delete'>
                    <SvgIcon icon={svgIcons['trashIcon']} size='medium' />
                  </ActionButtonError>
                )}
              </ActionButtonsWrapper>
            )}
          />
        </Grid>
        <TableDataLoader
          dataState={dataState}
          setDataState={setDataState}
          onDataReceived={dataReceived}
          sortMap={sortMap}
          additionalQueryParams={queryParams}
          getFetch={getUsers}
          errorMessage={'Failed to fetch clinicians'}
          ref={tableRef}
          queryKey={USERS_QUERY_KEY}
        />
      </CliniciansListWrapper>
    </DefaultLayout>
  );
};

export default CliniciansList;
