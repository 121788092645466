import {
  GraphComponentContainer,
  GraphComponentInsideContainer,
  GraphWrapper,
  Header,
  OptionsWrapper,
  TotalComponent
} from './styled';
import { PeriodEnum } from 'api/deviceUsageMonitoring/deviceUsageMonitoring.types';
import { DeviceEntry } from 'api/devices/device.types';
import ConfirmationLoader from '../../layouts/ConfirmationLoader';
import { sumBy } from 'lodash';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import { useGraphFormState } from 'components/DeviceUsageMonitoring/useGraphsFormState';
import {
  DatePickerRangeFilter,
  DeviceDropdownFilter,
  ExercisesFilter,
  GripsDropdownFilter,
  InstancesDropdownFilter,
  PeriodDropdownFilter
} from 'components/DeviceUsageMonitoring/Filters/CustomGraphFilters';
import { TransformedExercise } from 'pages/Goals/utils';
import React from 'react';

dayjs.extend(timezone);
dayjs.extend(utc);

const period_options = [
  { text: 'Day', id: PeriodEnum.day },
  { text: 'Week', id: PeriodEnum.week },
  { text: 'Month', id: PeriodEnum.month }
];

export type PeriodType = (typeof period_options)[number];
export type DateRangeType = { start: Date; end: Date | null };

export interface GraphFilters {
  date?: Date;
  dateFrom?: Date;
  dateTo?: Date;
  instance?: string[];
  period?: PeriodEnum;
  grip?: number | string;
  device?: DeviceEntry;
  dateRange?: DateRangeType;
  exercise?: TransformedExercise;
}

export interface UsageMonitorGraphProps {
  header: string;
  GraphComponent?: any;
  GraphsComponent?: any[];
  renderGraph?: (
    keys: string[],
    data: any,
    yLabel: string | undefined,
    userTimezone: string
  ) => React.ReactNode;
  devices?: DeviceEntry[];
  exercises?: TransformedExercise[];
  initialFilters: any;
  deviceFilter?: boolean;
  dateFilter?: boolean;
  dateRangeFilter?: boolean;
  instancesFilter?: boolean;
  periodFilter?: boolean;
  gripsFilter?: boolean;
  exerciseFilter?: boolean;
  graphDataSource?: any;
  legendMap?: Map<string, string>;
  gripCountText?: Map<string, string>;
  instancesOptions?: { text: string; id: string[] }[];
  graphProps?: any;
  totalCounter?: boolean;
  graphHeight?: string | null;
  additionalFilterProps?: any;
  extraFilters?: any;

  onFilterChange(filters: GraphFilters): void;
}

export type InstanceOption = NonNullable<UsageMonitorGraphProps['instancesOptions']>[number];

const UsageMonitoringGraph = (props: UsageMonitorGraphProps) => {
  const {
    header,
    GraphComponent,
    GraphsComponent,
    renderGraph,
    graphDataSource,
    legendMap,
    gripCountText = new Map<string, string>(),
    deviceFilter = true,
    dateFilter = false,
    dateRangeFilter = false,
    instancesFilter = false,
    periodFilter = false,
    gripsFilter = false,
    exerciseFilter = false,
    graphProps = null,
    totalCounter = true,
    graphHeight = null,
    additionalFilterProps = null,
    extraFilters = null
  } = props;

  // @ts-ignore
  const formState = useGraphFormState(props);
  const userTimezone = formState.device?.amputee?.notifications_timezone ?? dayjs?.tz?.guess();
  if (!graphDataSource) {
    return <ConfirmationLoader fullScreen />;
  }

  return (
    <GraphWrapper>
      <Header>{header}</Header>
      <OptionsWrapper>
        {extraFilters}
        {dateRangeFilter && (
          <DatePickerRangeFilter
            dateRange={formState.dateRange}
            setDate={formState.setDateRange}
            period={formState.period.id}
            additionalProps={additionalFilterProps?.dateRange}
          />
        )}
        {deviceFilter && (
          <DeviceDropdownFilter
            devices={formState.devices ?? []}
            selectedDevice={formState.device}
            setDevice={formState.setDevice}
          />
        )}
        {gripsFilter && (
          <GripsDropdownFilter
            setGrip={formState.setGrip}
            grips={formState.grips}
            selectedGrip={formState.grip}
          />
        )}
        {instancesFilter && (
          <InstancesDropdownFilter
            setInstance={formState.setInstances}
            instances={formState.instances}
            selectedInstance={formState.instance}
          />
        )}
        {periodFilter && (
          <PeriodDropdownFilter
            setPeriod={formState.setPeriod}
            periods={period_options}
            selectedPeriod={formState.period}
          />
        )}
        {exerciseFilter && formState.exercises && formState.exercise && (
          <ExercisesFilter
            setExercise={formState.setExercise}
            exercises={formState.exercises}
            selectedExercise={formState.exercise}
          />
        )}
      </OptionsWrapper>
      <GraphComponentContainer>
        {totalCounter && (
          <TotalComponent>
            {gripCountText.get(formState.instance.text)}:{' '}
            {Math.round(
              sumBy(graphDataSource, (item: any) => parseFloat(item[formState.instance.id[0]]))
            )}
          </TotalComponent>
        )}
        <GraphComponentInsideContainer
          style={
            graphHeight ? { height: graphHeight, maxHeight: graphHeight } : { height: 'inherit' }
          }>
          {renderGraph ? (
            renderGraph(
              formState.instance.id,
              graphDataSource,
              legendMap?.get(formState.instance.text),
              userTimezone
            )
          ) : (
            <GraphComponent
              keys={formState.instance.id}
              data={graphDataSource}
              userTimezone={userTimezone}
              yLabel={legendMap?.get(formState.instance.text)}
              exerciseGoalTarget={formState.exercise ?? 0}
              {...graphProps}
            />
          )}
        </GraphComponentInsideContainer>
      </GraphComponentContainer>
    </GraphWrapper>
  );
};

export default UsageMonitoringGraph;
