/* eslint-disable no-unused-vars */
import { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import {
  ActionButtonError,
  ActionButtonPrimary,
  ActionButtonSuccess
} from '../../components/ActionButton/ActionButton';
import { SvgIcon } from '@progress/kendo-react-common';
import * as svgIcons from '@progress/kendo-svg-icons';
import DefaultLayout from '../../layouts/DefaultLayout';
import { useHistory } from 'react-router-dom';
import {
  CLINICIANS,
  COMPANY,
  DEVICE,
  DEVICE_ADD,
  DEVICE_EDIT,
  PATIENTS
} from '../../constants/routes';
import { DEVICES_QUERY_KEY, useDeviceDelete, useDeviceModelList } from '../../hooks/api/useDevices';
import { useModal } from '../../hooks/api/useModal';
import ConfirmDeleteModal from '../../components/Modals/ConfirmDeleteModal';
import { getTableSize } from '../../utils/tableHelpers';
import { ActionButtonsWrapper } from '../../components/Table/styles';
import { TableLink } from '../../components/Links/Links';
import { Switch } from '@progress/kendo-react-inputs';
import TableHeader from '../../components/Table/TableHeader';
import { Button } from '@progress/kendo-react-buttons';
import useUserData from '../../hooks/useUserData';
import {
  DeviceExtendOptions,
  DeviceModel,
  DevicesQueryParams
} from '../../api/devices/device.types';
import { userHasPermissions } from '../../utils/permissionUtils';
import { RoleEnum } from '../../api/users/users.types';
import BooleanFilter from '../../components/Filters/CustomFilters/BooleanFilter';
import MultiSelectFilter from '../../components/Filters/CustomFilters/MultiSelectFilter';
import { useCompaniesInfinite } from '../../hooks/api/useCompanies';
import { CompanyEntry } from '../../api/companies/companies.types';
import { FieldSettings, FilterValue } from '../../components/Filters/FilterBox.types';
import FilterBox from '../../components/Filters/FilterBox';
import { find } from 'lodash';
import { TableDataLoader } from '../../components/TableDataLoader/TableDataLoader';
import { getDevices } from '../../api/devices/devices';
import { CONFIGURATOR_URL } from '../../constants/config';
import { DEVICES_PERMISSIONS } from 'constants/rolesPermissions';

const LocationsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.base};

  span {
    white-space: pre;
  }
`;

const SwitchInner = styled.div`
  margin-right: 12px;
`;

const fieldFiltersAdmin: FilterValue[] = [
  { field: 'company', value: [], role: RoleEnum.superAdmin },
  { field: 'model', value: [] },
  { field: 'active', value: true }
];

const sortMap: any = {
  serial: 'serial',
  company: 'company_name',
  amputee: 'amputee_name',
  clinician: 'clinician_name'
};

const DevicesList = () => {
  const [filters, setFilters] = useState<FilterValue[]>(fieldFiltersAdmin);
  const { data: userData, rolesByName } = useUserData();
  const { result: companies } = useCompaniesInfinite(
    { perpage: 100 },
    userHasPermissions([RoleEnum.superAdmin], rolesByName)
  );
  const { result: deviceModelList } = useDeviceModelList(true);
  const [searchTerm, setSearchTerm] = useState<any>('');
  const [onlyMyDevices, setOnlyMyDevices] = useState<boolean | null>(true);
  const [selectedDevice, setSelectedDevice] = useState<number>();
  const [devices, setDevices] = useState({
    data: [],
    total: 10
  });
  const [dataState, setDataState] = useState({
    take: 12,
    skip: 0
  });
  const tableRef = useRef(null) as any;
  const companyFilter = find(filters, { field: 'company' });
  const modelFilter = find(filters, { field: 'model' });

  const queryParamsDevices: DevicesQueryParams = {
    extend: [
      DeviceExtendOptions.model,
      DeviceExtendOptions.clinician,
      DeviceExtendOptions.amputee,
      DeviceExtendOptions.company,
      DeviceExtendOptions.pcbVersion,
      DeviceExtendOptions.firmawreVersion
    ],
    search: searchTerm,
    ...(modelFilter && modelFilter.value.length > 0 && { model: modelFilter.value }),
    ...(companyFilter && companyFilter.value.length > 0 && { company: companyFilter.value })
  };
  const queryParamsDevicesClinician: DevicesQueryParams = {
    extend: [
      DeviceExtendOptions.model,
      DeviceExtendOptions.clinician,
      DeviceExtendOptions.amputee,
      DeviceExtendOptions.company,
      DeviceExtendOptions.pcbVersion,
      DeviceExtendOptions.firmawreVersion
    ],
    search: searchTerm,
    clinician: userData?.id,
    ...(modelFilter && modelFilter.value.length > 0 && { model: modelFilter.value }),
    ...(companyFilter && companyFilter.value.length > 0 && { company: companyFilter.value })
  };

  const dataReceived = (locations: any) => {
    setDevices(locations);
  };

  const dataStateChange = (e: any) => {
    setDataState(e.dataState);
  };

  const { mutateAsync: deleteDevice, isLoading: isLoadingDelete } = useDeviceDelete();
  const history = useHistory();
  const {
    isOpen: isModalOpen,
    handleOpen: handleModalOpen,
    handleClose: handleModalClose
  } = useModal();

  const handleDelete = async (deviceId: number) => {
    setSelectedDevice(deviceId);
    handleModalOpen();
  };

  const deleteDev = async () => {
    if (selectedDevice) {
      await deleteDevice(selectedDevice);
      handleModalClose();
      tableRef.current.refresh();
    }
  };

  useEffect(() => {
    if (userData) {
      setOnlyMyDevices(
        !userHasPermissions(
          [RoleEnum.superAdmin, RoleEnum.clinicAdmin, RoleEnum.clinicianSupport],
          rolesByName
        )
      );
    }
  }, [userData]);

  const handleConfigure = (
    serial: string,
    bluetooth_id: string,
    deviceId: string,
    patientId: string
  ) => {
    window.open(
      `${CONFIGURATOR_URL}/device?serial=${serial}&bluetoothId=${bluetooth_id}&deviceId=${deviceId}&amputeeId=${patientId}`,
      '_blank'
    );
  };

  const fieldsByRole = useMemo((): FieldSettings[] => {
    const base = [
      {
        name: 'model',
        label: 'Filter by model',
        filter: MultiSelectFilter,
        data:
          (deviceModelList &&
            deviceModelList.map((deviceModel: DeviceModel) => ({
              text: deviceModel.name,
              value: deviceModel.id
            }))) ||
          []
      },
      {
        name: 'active',
        label: 'Active',
        filter: BooleanFilter
      }
    ];

    if (userHasPermissions([RoleEnum.superAdmin], rolesByName)) {
      const companyField = {
        name: 'company',
        label: 'Filter by company',
        data:
          (companies &&
            companies.map((company: CompanyEntry) => ({
              text: company.name,
              value: company.id
            }))) ||
          [],
        filter: MultiSelectFilter
      };
      base.unshift(companyField);
    }

    return base;
  }, [rolesByName]);

  const handleApplyFilters = (filters: any) => {
    setFilters(filters);
  };

  return (
    <DefaultLayout>
      <LocationsListWrapper>
        <TableHeader
          headerText='Devices list'
          search
          searchPlaceholder={'Type serial or bluetooth id'}
          setSearchTerm={setSearchTerm}
          actionsFilters={
            userHasPermissions([RoleEnum.clinician, RoleEnum.clinicAdmin], rolesByName) && (
              <SwitchWrapper data-testid='switch-only-my-devices'>
                <SwitchInner>
                  <Switch
                    checked={!!onlyMyDevices}
                    onChange={() => setOnlyMyDevices((prev) => !prev)}
                  />
                </SwitchInner>
                <span>Show only my devices</span>
              </SwitchWrapper>
            )
          }
          actions={
            <>
              <FilterBox
                key={filters.length}
                onApply={handleApplyFilters}
                filters={filters}
                fields={fieldsByRole}
                userRoles={rolesByName}
              />
              {userHasPermissions([RoleEnum.superAdmin], rolesByName) && (
                <Button
                  onClick={() => history.push(DEVICE_ADD)}
                  themeColor={'primary'}
                  data-testid='button-add'
                  icon={'plus'}>
                  Add new device
                </Button>
              )}
            </>
          }
        />
        {isModalOpen && (
          <ConfirmDeleteModal
            handleClose={handleModalClose}
            handleAccept={deleteDev}
            isLoading={isLoadingDelete}
            message={`Do you want to delete device (ID: ${selectedDevice})?`}
          />
        )}
        <Grid
          data={devices}
          sortable={true}
          onDataStateChange={dataStateChange}
          {...dataState}
          data-testid='devices-table'
          pageable={{ buttonCount: 4 }}
          selectable={{
            enabled: true
          }}>
          <Column
            field='serial'
            title='Serial'
            cell={(e: any) => (
              <td data-testid={`device-table-cell-${e.dataIndex}`}>
                <TableLink to={`${DEVICE}/${e.dataItem.id}`}>{e.dataItem?.serial}</TableLink>
              </td>
            )}
          />
          <Column field='bluetooth_id' title='Bluetooth ID' sortable={false} />
          <Column field='model.name' title='Model' sortable={false} />
          <Column
            field='company'
            title='Company'
            cell={(e: any) => (
              <td>
                <TableLink to={`${COMPANY}/${e.dataItem?.company?.id}`}>
                  {e.dataItem?.company?.name}
                </TableLink>
              </td>
            )}
          />
          <Column
            field='amputee'
            title='Patient'
            sortable={!userHasPermissions([RoleEnum.superAdmin], rolesByName)}
            cell={(e: any) => (
              <td>
                <TableLink to={`${PATIENTS}/${e.dataItem?.amputee?.id}`}>
                  {e.dataItem?.amputee?.name}
                </TableLink>
              </td>
            )}
          />
          <Column
            field='clinician'
            title='Clinician'
            cell={(e: any) => (
              <td>
                <TableLink to={`${CLINICIANS}/${e.dataItem?.clinician?.id}`}>
                  {e.dataItem?.clinician?.name}
                </TableLink>
              </td>
            )}
          />
          <Column
            title='Actions'
            width={getTableSize(3)}
            cell={(e: any) => (
              <ActionButtonsWrapper>
                {userHasPermissions(DEVICES_PERMISSIONS.EDIT, rolesByName) && (
                  <ActionButtonPrimary
                    data-testid='button-edit'
                    onClick={() =>
                      history.push({ pathname: DEVICE_EDIT, state: { detail: e.dataItem } })
                    }>
                    <SvgIcon icon={svgIcons['pencilIcon']} size='medium' />
                  </ActionButtonPrimary>
                )}
                {userHasPermissions(DEVICES_PERMISSIONS.CONFIGURE, rolesByName) &&
                  e.dataItem?.clinician_id &&
                  e.dataItem?.amputee_id && (
                    <ActionButtonSuccess
                      data-testid='button-configure'
                      onClick={() =>
                        handleConfigure(
                          e.dataItem.serial,
                          e.dataItem.bluetooth_id,
                          e.dataItem.id,
                          e.dataItem.amputee_id
                        )
                      }>
                      <SvgIcon icon={svgIcons['gearIcon']} size='medium' />
                    </ActionButtonSuccess>
                  )}
                {userHasPermissions([RoleEnum.superAdmin], rolesByName) && (
                  <ActionButtonError
                    data-testid='button-delete'
                    onClick={() => handleDelete(e.dataItem.id)}>
                    <SvgIcon icon={svgIcons['trashIcon']} size='medium' />
                  </ActionButtonError>
                )}
              </ActionButtonsWrapper>
            )}
          />
        </Grid>
        <TableDataLoader
          dataState={dataState}
          onDataReceived={dataReceived}
          setDataState={setDataState}
          sortMap={sortMap}
          additionalQueryParams={onlyMyDevices ? queryParamsDevicesClinician : queryParamsDevices}
          getFetch={getDevices}
          errorMessage={'Failed to fetch devices'}
          ref={tableRef}
          queryKey={DEVICES_QUERY_KEY}
        />
      </LocationsListWrapper>
    </DefaultLayout>
  );
};

export default DevicesList;
