import React, { useState } from 'react';
import styled from 'styled-components';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Loader } from '@progress/kendo-react-indicators';
import { Button } from '@progress/kendo-react-buttons';
import { DeviceEntry } from '../../api/devices/device.types';
import { ComboBox, ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';

const DialogWrapper = styled.div`
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
`;

const SelectDeviceBox = styled.div`
  margin-top: ${(props) => props.theme.dimensions.spacing}px;
`;

const SelectDeviceText = styled.div`
  display: inline;
  margin-right: ${(props) => props.theme.dimensions.spacing / 2}px;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const InnerWrapper = styled.div`
  color: ${({ theme }) => theme.palette.subtleText};

  p {
    text-align: left;
    margin: 0 auto;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 36px;
  justify-content: center;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

  span {
    font-size: 40px;
  }
`;

const ErrorIcon = styled(IconWrapper)`
  span {
    color: red;
  }
`;

const SuccessIcon = styled(IconWrapper)`
  span {
    color: ${({ theme }) => theme.palette.success2};
  }
`;

const ErrorHeader = styled.h2`
  font-weight: bold;
  text-align: center;
  margin-bottom: 22px;
  margin-top: 18px;
  color: ${({ theme }) => theme.palette.subtleText};
`;

type StartLiveSessionModalProps = {
  handleClose: any;
  handleAccept: any;
  handleConnectionActions: any;
  step: any;
  session: boolean;
  startActionIsDisabled: boolean;
  closeActionIsDisabled: boolean;
  devices: DeviceEntry[];
};

type LiveSessionModalStep = {
  handleClose: any;
  handleAccept: any;
  disabled: boolean;
  devices: DeviceEntry[];
};

export type LiveSessionAction = 'reconnect' | 'session_already_exists' | 'close' | 'start';

const ModalMessageStartSession = ({
  handleClose,
  handleAccept,
  disabled,
  devices = []
}: LiveSessionModalStep) => {
  const [selectedDevice, setSelectedDevice] = useState(devices[0]);
  const filteredDevices = devices.filter(
    (device: DeviceEntry) => device.amputee_id !== null && device.clinician_id !== null
  );
  const handleChange = (event: ComboBoxChangeEvent) => {
    setSelectedDevice(event.target.value);
  };
  return (
    <>
      {filteredDevices.length > 0 && (
        <>
          <p>Are you sure want to start a session?</p>
          <br />
          <p>
            Push notification will be sent to the patient on mobile app with request to confirm the
            session. After confirming it, you will get access to his configuration.
          </p>
        </>
      )}
      {filteredDevices.length === 0 && (
        <>
          <p>Sorry. You don&apos;t have any device assign to this account</p>
        </>
      )}
      {filteredDevices.length > 0 && (
        <SelectDeviceBox>
          <SelectDeviceText>Choose device:</SelectDeviceText>
          <ComboBox
            textField={'serial'}
            dataItemKey={'id'}
            valid={Boolean(selectedDevice)}
            data={filteredDevices}
            value={selectedDevice}
            onChange={handleChange}
          />
        </SelectDeviceBox>
      )}
      <ButtonsWrapper>
        <Button disabled={disabled} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          themeColor={'primary'}
          onClick={() => handleAccept(selectedDevice)}
          disabled={Boolean(!selectedDevice) || disabled}>
          Start
        </Button>
      </ButtonsWrapper>
    </>
  );
};

export enum SessionInitializationStatus {
  success = 'success',
  waiting = 'waiting',
  error = 'error'
}

const successMessage = () => <p>You can now start a session with patient.</p>;
const waitingMessage = () => <p>Waiting for patient approval. Please, don’t refresh a page...</p>;
const errorMessage = () => (
  <>
    <ErrorHeader>Failed to connect</ErrorHeader>
    <p style={{ textAlign: 'center' }}>
      Failed to connect. Contact the patient to see, if he or she has accepted the invitation and
      then repeat the request.
    </p>
  </>
);

const messageByStatus = {
  [SessionInitializationStatus.waiting]: {
    message: waitingMessage,
    icon: (
      <LoaderWrapper>
        <Loader type={'pulsing'} />
      </LoaderWrapper>
    ),
    actions: (handleClose: any) => (
      <ButtonsWrapper>
        <Button themeColor={'primary'} disabled>
          Start configuration
        </Button>
        <Button onClick={() => handleClose('close')}>Close session</Button>
      </ButtonsWrapper>
    )
  },
  [SessionInitializationStatus.success]: {
    message: successMessage,
    icon: (
      <SuccessIcon>
        <span className='k-icon k-i-check-circle' />
      </SuccessIcon>
    ),
    actions: (handleClose: any, handleAccept: any) => (
      <ButtonsWrapper>
        <Button themeColor={'primary'} onClick={() => handleAccept('start')}>
          Start configuration
        </Button>
      </ButtonsWrapper>
    )
  },
  [SessionInitializationStatus.error]: {
    message: errorMessage,
    icon: (
      <ErrorIcon>
        <span className='k-icon k-i-warning' />
      </ErrorIcon>
    ),
    actions: (handleClose: any, handleAccept: any) => (
      <ButtonsWrapper>
        <Button onClick={() => handleClose('close')}>Cancel</Button>
        <Button themeColor={'primary'} onClick={() => handleAccept('reconnect')}>
          Try again
        </Button>
      </ButtonsWrapper>
    )
  }
};

const ModalMessageInitializeSession = ({
  handleAccept,
  handleClose,
  status,
  ...props
}: {
  handleAccept: any;
  handleClose: any;
  status: SessionInitializationStatus;
}) => {
  const currentStatus = messageByStatus[status];
  return (
    <div {...props}>
      {currentStatus.icon && currentStatus.icon}
      <div>{currentStatus.message()}</div>
      {currentStatus.actions && currentStatus.actions(handleClose, handleAccept)}
    </div>
  );
};
const StartLiveSessionModal = ({
  handleClose,
  handleAccept: handleAcceptProps,
  handleConnectionActions,
  step,
  session,
  devices,
  startActionIsDisabled,
  closeActionIsDisabled,
  ...props
}: StartLiveSessionModalProps) => {
  const [isStart, setIsStart] = useState(false);
  const handleAccept = (selectedDevice: DeviceEntry) => {
    handleAcceptProps(selectedDevice).then((result: boolean) => {
      if (result) {
        setIsStart(true);
      }
    });
  };

  const handleReconnect = () => {
    handleConnectionActions('session_already_exists');
    setIsStart(true);
  };

  return (
    <Dialog title={'Start a session'} onClose={handleClose} width={760} {...props}>
      <DialogWrapper>
        <InnerWrapper>
          {!session && !isStart && (
            <ModalMessageStartSession
              devices={devices}
              disabled={startActionIsDisabled || closeActionIsDisabled}
              handleAccept={handleAccept}
              handleClose={handleClose}
            />
          )}
          {isStart && (
            <ModalMessageInitializeSession
              handleAccept={handleConnectionActions}
              handleClose={handleConnectionActions}
              status={step}
            />
          )}
          {!isStart && session && (
            <>
              {step === SessionInitializationStatus.waiting && (
                <ModalMessageInitializeSession
                  handleAccept={handleConnectionActions}
                  handleClose={handleConnectionActions}
                  status={step}
                />
              )}
              {step === SessionInitializationStatus.success && (
                <div>
                  <SuccessIcon>
                    <span className='k-icon k-i-check-circle' />
                  </SuccessIcon>
                  <p style={{ textAlign: 'center' }}>Your session is already created</p>
                  <ButtonsWrapper>
                    <Button
                      disabled={startActionIsDisabled}
                      themeColor={'primary'}
                      onClick={() => handleReconnect()}>
                      Start configuration
                    </Button>
                    <Button
                      disabled={closeActionIsDisabled}
                      onClick={() => handleConnectionActions('close')}>
                      Close session
                    </Button>
                  </ButtonsWrapper>
                </div>
              )}
            </>
          )}
        </InnerWrapper>
      </DialogWrapper>
    </Dialog>
  );
};

export default StartLiveSessionModal;
